import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';
import lostPandaImage from '../assets/lost-panda.jpg';

const NoTeamPage: React.FC = () => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
          py: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            maxWidth: '100%',
          }}
        >
          <Box
            component="img"
            src={lostPandaImage}
            alt="Lost Panda"
            sx={{
              width: '100%',
              maxWidth: 300,
              height: 'auto',
              borderRadius: 2,
              mb: 3,
            }}
          />
          <Typography variant="h4" component="h1" gutterBottom>
            You need to select a team
          </Typography>
          <Typography variant="body1" paragraph>
            You've hit a page that requires a team. Please select a team from the dropdown in the
            top left.
          </Typography>
          <Typography variant="body1" paragraph>
            If you're not a member of any teams, please contact your administrator.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default NoTeamPage;
