import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { ApiError } from '@cloud-ui/utils/api';
import {
  fetchEvalById,
  deleteEval,
  fetchEvals,
  type UseEvalsOptions,
  shareEval as shareEvalApiCall,
} from '@cloud-ui/utils/api/evals';
import { StatusCodes } from 'http-status-codes';

export const useEval = (evalId: string, options = {}) => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  return useQuery({
    queryKey: ['evals', evalId],
    queryFn: async () => {
      try {
        return await fetchEvalById(evalId);
      } catch (e) {
        if (e instanceof ApiError && e.status === StatusCodes.UNAUTHORIZED) {
          showToast('You may need to be logged in to view this page...', 'warning');
          navigate(ROUTES.login);
          return null;
        }
        if (e instanceof ApiError && e.status === StatusCodes.NOT_FOUND) {
          navigate(ROUTES.notFound);
          return null;
        }
        throw e;
      }
    },
    enabled: !!evalId,
    ...options,
  });
};

export const useEvals = (options: UseEvalsOptions = {}, queryOptions = {}) => {
  return useQuery({
    queryKey: ['evals', options.teamId],
    queryFn: () => fetchEvals(options),
    enabled: true,
    ...queryOptions,
  });
};

export const useDeleteEval = (teamId: string) => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: deleteEval,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['evals', teamId] });
      showToast('Eval deleted successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to create invite link:', error);
      showToast('Failed to create invite link', 'error');
    },
  });
};

export const useShareEval = () => {
  const { showToast } = useToast();

  return useMutation({
    mutationFn: shareEvalApiCall,
    onSuccess: () => {},
    onError: (error) => {
      console.error('Failed to create share link:', error);
      showToast('Failed to create share link', 'error');
    },
  });
};
