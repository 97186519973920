import { z } from 'zod';

export const FusionAuthConfigSchema = z.object({
  apiUrl: z.string(),
  appUrl: z.string(),
  applicationId: z.string(),
  redirectUri: z.string(),
  scope: z.string(),
  useFusionAuth: z.boolean(),
});

export const ConfigSchema = z.object({
  enableServerSideJobs: z.boolean(),
  enableServerSettingsPage: z.boolean(),
  enableMakePublic: z.boolean(),
  auth: z.object({
    fusionauth: FusionAuthConfigSchema,
  }),
  isDangerousPublic: z.boolean(),
  useFusionAuth: z.boolean(),
  isPromptfooCloud: z.boolean(),
});

export type FusionAuthConfig = z.infer<typeof FusionAuthConfigSchema>;
export type ConfigDTO = z.infer<typeof ConfigSchema>;
