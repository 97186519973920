import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ConfigModal from '@cloud-ui/components/ConfigModal';
import ErrorAlert from '@cloud-ui/components/ErrorAlert';
import LoadingSpinner from '@cloud-ui/components/LoadingSpinner';
import { ROUTES } from '@cloud-ui/constants';
import { useCan } from '@cloud-ui/contexts/RbacContext';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { useJobLogs } from '@cloud-ui/hooks/useJobLogs';
import { getJobDetails, startJob, stopJob } from '@cloud-ui/utils/api/jobs';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ReplayIcon from '@mui/icons-material/Replay';
import StopIcon from '@mui/icons-material/Stop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import type { RedteamRunOptions, UnifiedConfig } from '@promptfoo/types';
import type { EvalJobDTO, SSEMessage } from '@shared/dto';
import { Actions, Subjects } from '@shared/dto/rbac';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { LogViewer } from '../../redteam/configs/edit/components/LogViewer';
import JobStatusBadge from './JobStatusBadge';

export default function JobDetails({ jobId }: { jobId: EvalJobDTO['id'] }) {
  const [isConfigDialogOpen, setIsConfigDialogOpen] = useState(false);
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const { currentTeam } = useTeamsContext();
  const canUpdateJobs = useCan(Actions.UPDATE, Subjects.JOB);
  const canStartJobs = useCan(Actions.CREATE, Subjects.JOB);
  const navigate = useNavigate();

  /**
   * Fetches the selected job details.
   */
  const {
    data: selectedJob,
    isLoading: isLoadingJob,
    error: getSelectedJobError,
    isError: isGetSelectedJobError,
    refetch: refetchSelectedJob,
  } = useQuery({
    queryKey: ['job', jobId],
    queryFn: () => getJobDetails(jobId || ''),
  });

  /**
   * Invalidates the current jobs list state, forcing a refresh from the server.
   */
  const revalidateJobs = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['jobs', currentTeam?.id] });
  }, [currentTeam?.id, queryClient]);

  const handleJobCompletion = useCallback(async () => {
    await revalidateJobs();
    await refetchSelectedJob();
  }, [refetchSelectedJob, revalidateJobs]);

  const { logs, error: logsError } = useJobLogs(jobId, {
    onMessage: async (data: SSEMessage) => {
      switch (data.type) {
        case 'progress': {
          // Update the local state of the selected job:
          queryClient.setQueryData(['job', jobId], (oldData: any) =>
            oldData ? { ...oldData, progress: data.progress } : oldData,
          );
          break;
        }
        case 'completion': {
          await handleJobCompletion();
          break;
        }
      }
    },
    maxLogs: 1000,
    batchInterval: 500,
  });

  const { mutate: runRedTeam } = useMutation({
    mutationFn: ({
      config,
      configId,
      options,
    }: {
      config: UnifiedConfig;
      configId?: string | null;
      options: RedteamRunOptions;
    }) => startJob(config, currentTeam!.id, configId, options),
    onSuccess: async (data) => {
      showToast('Job started', 'success');
      await revalidateJobs();
      // Navigate to the new job details page:
      navigate(`${ROUTES.jobs}/${data.id}`);
    },
    onError: (error) => {
      showToast(`Error starting new job: ${error}`, 'error');
    },
  });

  const { mutate: stopJobMutation } = useMutation({
    mutationFn: stopJob,
    onSuccess: () => {
      showToast('Job stopped', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs', currentTeam?.id] });
      queryClient.invalidateQueries({ queryKey: ['job', jobId] });
    },
    onError: (error) => {
      showToast(`Error stopping job: ${error}`, 'error');
    },
  });

  /**
   * Handle errors from the getSelectedJob query.
   */
  useEffect(() => {
    if (isGetSelectedJobError) {
      showToast(getSelectedJobError?.message, 'error');
    }
  }, [isGetSelectedJobError]);

  const handleOpenConfigDialog = () => {
    setIsConfigDialogOpen(true);
  };

  const handleCloseConfigDialog = () => {
    setIsConfigDialogOpen(false);
  };

  const isJobComplete = selectedJob?.status === 'completed';

  return (
    <Box flex={1}>
      {logsError && <ErrorAlert error={logsError} />}

      {selectedJob && (
        <Paper sx={{ p: 3, height: '100%' }}>
          {isLoadingJob ? (
            <LoadingSpinner />
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 3,
                }}
              >
                <Typography variant="h6">Job Details</Typography>
                <Box>
                  <Button
                    startIcon={<VisibilityIcon />}
                    onClick={handleOpenConfigDialog}
                    sx={{ mr: 1 }}
                  >
                    View Config
                  </Button>
                  {selectedJob.status === 'running' && canUpdateJobs && (
                    <Button
                      startIcon={<StopIcon />}
                      onClick={() => stopJobMutation(selectedJob.id)}
                      color="error"
                      sx={{ mr: 1 }}
                    >
                      Stop
                    </Button>
                  )}
                  {isJobComplete && selectedJob.evalId && (
                    <Button
                      component={Link}
                      to={`${ROUTES.redteam.report}/${selectedJob.evalId}`}
                      startIcon={<AssessmentIcon />}
                      color="primary"
                      sx={{ mr: 1 }}
                    >
                      View Report
                    </Button>
                  )}
                  {canStartJobs && (
                    <Button
                      startIcon={<ReplayIcon />}
                      onClick={() =>
                        runRedTeam({
                          config: selectedJob.config as UnifiedConfig,
                          configId: selectedJob.configId,
                          options: selectedJob.options,
                        })
                      }
                      color="primary"
                    >
                      Rerun
                    </Button>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                  gap: 3,
                  mb: 3,
                  '& > div': {
                    minWidth: 0, // Prevents overflow in grid items
                  },
                }}
              >
                <Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    ID
                  </Typography>
                  <Typography variant="caption" sx={{ fontFamily: 'monospace' }}>
                    {selectedJob.id}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Created
                  </Typography>
                  <Typography
                    variant="body1"
                    title={new Date(selectedJob.createdAt).toLocaleString(undefined, {
                      timeZoneName: 'short',
                      timeZone: 'UTC',
                    })}
                  >
                    {formatDistanceToNow(parseISO(selectedJob.createdAt as unknown as string), {
                      addSuffix: true,
                    })}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Status
                  </Typography>
                  <JobStatusBadge status={selectedJob.status} />
                </Box>
              </Box>

              {selectedJob.progress && (
                <>
                  {selectedJob.progress.total > 0 && (
                    <Box sx={{ mb: 3 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 4 }}>
                        {selectedJob.progress.completed > 0 && (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" color="text.secondary">
                              Pass Rate:
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                ml: 1,
                              }}
                            >
                              {Math.round(
                                (selectedJob.progress.passes / selectedJob.progress.completed) *
                                  100,
                              )}
                              %
                            </Typography>
                          </Box>
                        )}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="subtitle2" color="text.secondary">
                            Progress:
                          </Typography>
                          <Typography variant="body2" sx={{ ml: 1 }}>
                            {selectedJob.progress.completed} / {selectedJob.progress.total}
                          </Typography>
                        </Box>
                      </Box>
                      <LinearProgress
                        variant="determinate"
                        value={(selectedJob.progress.completed / selectedJob.progress.total) * 100}
                        sx={{
                          height: 6,
                          borderRadius: 3,
                          bgcolor: 'grey.100',
                          '& .MuiLinearProgress-bar': {
                            bgcolor: 'primary.main',
                          },
                        }}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 3,
                      mb: 3,
                      '& > div': {
                        flex: 1,
                        minWidth: '120px',
                        p: 2,
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: 'divider',
                        bgcolor: 'background.paper',
                      },
                    }}
                  >
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <CheckCircleIcon
                          sx={{ mr: 1, fontSize: '1.2rem', color: 'success.main' }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          Passes
                        </Typography>
                      </Box>
                      <Typography variant="h5" color="text.primary" sx={{ fontWeight: 500 }}>
                        {selectedJob.progress.passes}
                      </Typography>
                    </Box>
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <ErrorIcon sx={{ mr: 1, fontSize: '1.2rem', color: 'error.main' }} />
                        <Typography variant="body2" color="text.secondary">
                          Failures
                        </Typography>
                      </Box>
                      <Typography variant="h5" color="text.primary" sx={{ fontWeight: 500 }}>
                        {selectedJob.progress.failures}
                      </Typography>
                    </Box>
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <WarningIcon sx={{ mr: 1, fontSize: '1.2rem', color: 'warning.main' }} />
                        <Typography variant="body2" color="text.secondary">
                          Errors
                        </Typography>
                      </Box>
                      <Typography variant="h5" color="text.primary" sx={{ fontWeight: 500 }}>
                        {selectedJob.progress.errors}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}

              <Box sx={{ mt: 3 }}>
                {logs.length === 0 && !logsError ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      Loading logs...
                    </Typography>
                  </Box>
                ) : (
                  <LogViewer logs={logs} />
                )}
              </Box>
            </>
          )}
        </Paper>
      )}

      <ConfigModal
        open={isConfigDialogOpen}
        onClose={handleCloseConfigDialog}
        config={selectedJob?.config}
        title="Job Configuration"
      />
    </Box>
  );
}
