import React, { useState } from 'react';
import { convertTestCasesToCsv } from '@cloud-ui/utils/issues';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import type { EvalResultDTO } from '@shared/dto';

interface TestCaseDownloadButtonProps {
  evalResults: EvalResultDTO[];
  issueId: string;
}

const TestCaseDownloadButton: React.FC<TestCaseDownloadButtonProps> = ({
  evalResults,
  issueId,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleCsvDownload = () => {
    setIsDownloading(true);

    try {
      const csv = convertTestCasesToCsv(evalResults);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute(
          'download',
          `attacks_${issueId}_${new Date().toISOString().split('T')[0]}.csv`,
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error generating CSV:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Tooltip title="Export attacks as CSV" placement="top" open={isHovering && !isDownloading}>
      <IconButton
        onClick={handleCsvDownload}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        sx={{ mt: '4px' }}
        aria-label="export attacks as CSV"
        disabled={isDownloading || evalResults.length === 0}
      >
        {isDownloading ? <CircularProgress size={20} /> : <DownloadIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default TestCaseDownloadButton;
