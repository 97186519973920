import Chip from '@mui/material/Chip';

export default function JobStatusBadge({ status }: { status: string }) {
  const color = (() => {
    switch (status.toLowerCase()) {
      case 'running':
        return 'primary';
      case 'completed':
        return 'success';
      case 'failed':
        return 'error';
      default:
        return 'default';
    }
  })();

  return <Chip label={status} color={color} size="small" style={{ textTransform: 'capitalize' }} />;
}
