import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@cloud-ui/contexts/AuthContext';
import { useConfig } from '@cloud-ui/contexts/ConfigContext';
import { useFusionAuth } from '@cloud-ui/contexts/FusionAuthContext';
import { ApiError } from '@cloud-ui/utils/api';
import { requestMagicLinkLogin } from '@cloud-ui/utils/api/users';
import { isValidEmail } from '@cloud-ui/utils/validation';
import GroupsIcon from '@mui/icons-material/Groups';
import VersionIcon from '@mui/icons-material/History';
import AnalyticsIcon from '@mui/icons-material/QueryStats';
import SecurityIcon from '@mui/icons-material/Security';
import ShareIcon from '@mui/icons-material/Share';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, TextField, Typography, useTheme, Stack, alpha, Divider } from '@mui/material';

/**
 * Unified Login component that handles FusionAuth authentication
 * and supports isDangerousPublic mode
 */
export default function Login() {
  const navigate = useNavigate();
  const { user, login } = useAuth();
  const { config } = useConfig();
  const fusionAuth = useFusionAuth();
  const theme = useTheme();

  const isFusionAuthEnabled = fusionAuth?.fusionAuthConfig?.useFusionAuth || false;

  // For cloud deployment with existing UI
  const isDangerousPublic = config?.isDangerousPublic || false;

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleAuth = useCallback(async () => {
    if (isFusionAuthEnabled) {
      try {
        const authUrl = fusionAuth?.buildLoginUrl();
        if (authUrl) {
          window.location.href = authUrl;
        } else {
          throw new Error('Invalid login URL - FusionAuth may not be properly configured');
        }
      } catch (err) {
        console.error('Failed to build login URL:', err);
        setError('Error initiating FusionAuth login. Please contact support.');
      }
    }
  }, [fusionAuth, isFusionAuthEnabled]);

  const handleSignup = useCallback(async () => {
    const registrationUrl = fusionAuth?.buildRegistrationUrl();
    if (!registrationUrl) {
      console.error('something went terribely wrong');
      return;
    }
    window.location.href = registrationUrl;
  }, [fusionAuth]);

  // Check if the user is already logged in (for both cloud and on-prem)
  React.useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [navigate, user]);

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    setError('');
    event.preventDefault();

    try {
      const data = await requestMagicLinkLogin(email);

      if (data.token) {
        login(data.token);
        navigate('/');
      }
    } catch (e) {
      setError(e instanceof ApiError ? e.message : 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: 'calc(100vh - 68px)',
        display: 'flex',
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* Left side - Login Form */}
      <Box
        sx={{
          flex: '0 0 50%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          p: 6,
        }}
      >
        <Box sx={{ maxWidth: 480, width: '100%', mx: 'auto' }}>
          <Typography
            component="h2"
            variant="h4"
            sx={{
              mb: 4,
              fontWeight: 600,
              color: theme.palette.text.primary,
              textAlign: 'center',
            }}
          >
            Sign in to Promptfoo
          </Typography>

          {isDangerousPublic && (
            <>
              <Box
                sx={{
                  mt: 2,
                  mb: 3,
                  p: 2,
                  bgcolor: (theme) =>
                    alpha(theme.palette.warning.main, theme.palette.mode === 'dark' ? 0.2 : 0.1),
                  borderRadius: 1,
                  border: '1px solid',
                  borderColor: 'warning.main',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <WarningIcon color="warning" />
                <Typography
                  variant="body2"
                  sx={{
                    color: 'warning.main',
                    fontWeight: 500,
                  }}
                >
                  This instance is running in public mode. Any email address can be used to create a
                  new account.
                </Typography>
              </Box>

              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  disabled={loading}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                  onInput={(e) => setEmail((e.target as HTMLInputElement).value)}
                  value={email}
                  error={Boolean(error)}
                  sx={{ mb: 2 }}
                />
                <Button
                  type="submit"
                  disabled={loading || !isValidEmail(email)}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mb: 2, py: 1.5 }}
                >
                  Sign in with Email
                </Button>

                {error && (
                  <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                    {error}
                  </Typography>
                )}
              </Box>
            </>
          )}

          {isFusionAuthEnabled && (
            <Stack spacing={2}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleAuth}
                sx={{ py: 1.5 }}
              >
                Sign in
              </Button>
              <Button
                color="primary"
                variant="outlined"
                fullWidth
                onClick={handleSignup}
                sx={{ py: 1.5 }}
              >
                Create an account
              </Button>
            </Stack>
          )}
        </Box>
      </Box>

      {/* Right side - Marketing Content */}
      <Box
        sx={{
          flex: '0 0 50%',
          bgcolor: (theme) =>
            theme.palette.mode === 'dark'
              ? alpha(theme.palette.primary.main, 0.1)
              : alpha(theme.palette.primary.main, 0.05),
          display: 'flex',
          flexDirection: 'column',
          p: 6,
          borderLeft: 1,
          justifyContent: 'center',
          borderColor: (theme) =>
            theme.palette.mode === 'dark'
              ? alpha(theme.palette.primary.main, 0.2)
              : alpha(theme.palette.primary.main, 0.1),
        }}
      >
        <Box sx={{ maxWidth: 480, mx: 'auto', width: '100%' }}>
          {/* Free Version */}
          <Box mb={6}>
            <Typography
              variant="h5"
              component="h2"
              sx={{ mb: 3, fontWeight: 600, color: 'primary.main', textAlign: 'center' }}
            >
              Free
            </Typography>
            <Stack direction="row" spacing={2} alignItems="flex-start" sx={{ mb: 4 }}>
              <Box
                sx={{
                  p: 1.5,
                  borderRadius: 2,
                  bgcolor: (theme) =>
                    theme.palette.mode === 'dark'
                      ? alpha(theme.palette.primary.main, 0.2)
                      : alpha(theme.palette.primary.main, 0.1),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: 48,
                }}
              >
                <ShareIcon color="primary" />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                  Team Collaboration
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Share eval results with your team
                </Typography>
              </Box>
            </Stack>
          </Box>

          <Divider sx={{ my: 4 }} />

          {/* Redteam Enterprise */}
          <Box>
            <Typography
              variant="h5"
              component="h2"
              sx={{ mb: 3, fontWeight: 600, color: 'primary.main', textAlign: 'center' }}
            >
              Redteam Enterprise
            </Typography>
            <Stack spacing={4}>
              <Stack direction="row" spacing={2} alignItems="flex-start">
                <Box
                  sx={{
                    p: 1.5,
                    borderRadius: 2,
                    bgcolor: (theme) =>
                      theme.palette.mode === 'dark'
                        ? alpha(theme.palette.primary.main, 0.2)
                        : alpha(theme.palette.primary.main, 0.1),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: 48,
                  }}
                >
                  <SecurityIcon color="primary" />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                    Comprehensive Scanning & Compliance
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Custom probes for your application that identify failures you actually care
                    about, not just generic jailbreaks and prompt injections.
                  </Typography>
                </Box>
              </Stack>

              <Stack direction="row" spacing={2} alignItems="flex-start">
                <Box
                  sx={{
                    p: 1.5,
                    borderRadius: 2,
                    bgcolor: (theme) =>
                      theme.palette.mode === 'dark'
                        ? alpha(theme.palette.primary.main, 0.2)
                        : alpha(theme.palette.primary.main, 0.1),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: 48,
                  }}
                >
                  <AnalyticsIcon color="primary" />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                    Reports & Continuous Monitoring
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Understand your LLM security status across all projects with real-time alerts
                    and automated evaluations.
                  </Typography>
                </Box>
              </Stack>

              <Stack direction="row" spacing={2} alignItems="flex-start">
                <Box
                  sx={{
                    p: 1.5,
                    borderRadius: 2,
                    bgcolor: (theme) =>
                      theme.palette.mode === 'dark'
                        ? alpha(theme.palette.primary.main, 0.2)
                        : alpha(theme.palette.primary.main, 0.1),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: 48,
                  }}
                >
                  <VersionIcon color="primary" />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                    Issue Tracking & Guided Remediation
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Track remediation progress and get suggested steps for each issue.
                  </Typography>
                </Box>
              </Stack>

              <Stack direction="row" spacing={2} alignItems="flex-start">
                <Box
                  sx={{
                    p: 1.5,
                    borderRadius: 2,
                    bgcolor: (theme) =>
                      theme.palette.mode === 'dark'
                        ? alpha(theme.palette.primary.main, 0.2)
                        : alpha(theme.palette.primary.main, 0.1),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: 48,
                  }}
                >
                  <GroupsIcon color="primary" />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                    Team Management
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Multiple teams and role based access control
                  </Typography>
                </Box>
              </Stack>
              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 'auto' }}
                href="https://www.promptfoo.dev/contact/"
              >
                Get a Demo
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
