import { z } from 'zod';
import { OrganizationDTO } from './organizations';
import { TeamDTO, TeamWithoutMembersDTO } from './teams';

export enum AccountType {
  USER = 'user',
  SERVICE = 'service',
}

export const UserSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  accountType: z.nativeEnum(AccountType),
  createdAt: z.string(),
  updatedAt: z.string(),
  isAdmin: z.boolean().optional(),
});

export interface UserDTOWithTeams extends z.infer<typeof UserSchema> {
  teams: Array<TeamWithoutMembersDTO>;
}

export interface MeDTO {
  user: UserDTO;
  organization: OrganizationDTO;
  app: {
    url: string;
  };
  isAdmin: boolean;
}

export const RequestPasswordResetSchema = z.object({
  email: z.string().email('Invalid email format'),
});

export type RequestPasswordResetRequest = z.infer<typeof RequestPasswordResetSchema>;

export type UserDTO = z.infer<typeof UserSchema>;
