import { displayNameOverrides } from '@promptfoo/redteam/constants';
import { Severity, IssueStatus } from '@shared/constants';
import type { EvalResultDTO, IssueDTO } from '@shared/dto';
import type { ProviderDTO } from '@shared/dto/providers';
import { stringify } from 'csv-stringify/browser/esm/sync';

export function getUniqueTargets(issues: IssueDTO[], providers: ProviderDTO[]) {
  const targets = new Map<string, { id: string; name: string }>();
  issues.forEach((issue) => {
    if (issue.targetId) {
      targets.set(issue.targetId, { id: issue.targetId, name: issue.targetId });
    } else if (issue.providerId) {
      const provider = providers?.find((p) => p.id === issue.providerId);
      if (provider) {
        targets.set(issue.providerId, {
          id: provider.id,
          name: provider.name,
        });
      }
    }
  });
  return Array.from(targets.values());
}

export function getUniqueVulnerabilities(issues: IssueDTO[]) {
  const vulnerabilities = issues
    .map((issue) => displayNameOverrides[issue.pluginId])
    .sort()
    .filter((vulnerability) => vulnerability !== undefined);
  return Array.from(new Set(vulnerabilities));
}

export function convertIssuesToCsv(issues: IssueDTO[], providers: ProviderDTO[]): string {
  const rows = issues.map((issue) => {
    const provider = providers?.find((p) => p.id === issue.providerId);
    return {
      ID: issue.id,
      Weakness: issue.weakness,
      Severity: Severity[issue.severity.toUpperCase() as keyof typeof Severity],
      Status: IssueStatus[issue.status.toUpperCase() as keyof typeof IssueStatus],
      Target: issue.targetId || '',
      Provider: provider?.name || '',
      Plugin: displayNameOverrides[issue.pluginId] || issue.pluginId,
      Occurrences: issue.occurrences,
      'Created At': new Date(issue.createdAt).toISOString(),
      'Updated At': new Date(issue.updatedAt).toISOString(),
    };
  });

  return stringify(rows, {
    header: true,
    quoted: true,
    quoted_string: true,
    quoted_empty: true,
  });
}

export function convertTestCasesToCsv(evalResults: EvalResultDTO[]): string {
  const rows = evalResults.map((result) => {
    // Extract key variables from test case
    const testVars = result.testCase.vars || {};
    const varsString = Object.entries(testVars)
      .map(([key, value]) => {
        // Format value to string
        const valueStr = typeof value === 'object' ? JSON.stringify(value) : String(value);
        return `${key}: ${valueStr}`;
      })
      .join('\n');

    return {
      Date: new Date(result.createdAt).toISOString(),
      Attack: varsString,
      Response: result.response?.output || '',
      Strategy: result.testCase.metadata?.strategyId || 'Basic',
      Pass: result.success ? 'Yes' : 'No',
      Score: result.score.toString(),
      'Eval ID': result.evalId,
      ID: result.id,
    };
  });

  return stringify(rows, {
    header: true,
    quoted: true,
    quoted_string: true,
    quoted_empty: true,
  });
}
