import type { HTMLAttributes } from 'react';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthorChip } from '@cloud-ui/components/AuthorChip';
import ConfigModal from '@cloud-ui/components/ConfigModal';
import { EvalIdChip } from '@cloud-ui/components/EvalIdChip';
import { PublicChip } from '@cloud-ui/components/PublicChip';
import { ROUTES } from '@cloud-ui/constants';
import { useAuth } from '@cloud-ui/contexts/AuthContext';
import { useConfig } from '@cloud-ui/contexts/ConfigContext';
import { useCan } from '@cloud-ui/contexts/RbacContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { useStore as useMainStore } from '@cloud-ui/stores/evalConfig';
import { callApi } from '@cloud-ui/utils/api';
import { Share } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import PublicIcon from '@mui/icons-material/Public';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import EyeIcon from '@mui/icons-material/Visibility';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import type { StackProps } from '@mui/material/Stack';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/material/styles';
import { convertResultsToTable } from '@promptfoo/util/convertEvalResultsToTable';
import invariant from '@promptfoo/util/invariant';
import type { EvalWithAuthor } from '@shared/dto';
import { Actions, Subjects } from '@shared/dto/rbac';
import type { VisibilityState } from '@tanstack/table-core';
import { useDebounce } from 'use-debounce';
import { ColumnSelector } from './ColumnSelector';
import CompareEvalMenuItem from './CompareEvalMenuItem';
import DownloadMenu from './DownloadMenu';
import EvalSelectorDialog from './EvalSelectorDialog';
import EvalSelectorKeyboardShortcut from './EvalSelectorKeyboardShortcut';
import { FilterModeSelector } from './FilterModeSelector';
import ResultsCharts from './ResultsCharts';
import ResultsTable from './ResultsTable';
import SettingsModal from './ResultsViewSettingsModal';
import ShareModal from './ShareModal';
import { useStore as useResultsViewStore } from './store';
import type { EvaluateTable, FilterMode } from './types';
import './ResultsView.css';

const ResponsiveStack = styled(Stack)<StackProps>(({ theme }) => ({
  maxWidth: '100%',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

interface ResultsViewProps {
  recentEvals: EvalWithAuthor[];
  onRecentEvalSelected: (file: string) => void;
  defaultEvalId?: string;
  onDelete?: (id: string) => Promise<void>;
}

export default function ResultsView({
  recentEvals,
  onRecentEvalSelected,
  defaultEvalId,
  onDelete,
}: ResultsViewProps): React.ReactElement<HTMLAttributes<HTMLDivElement>> {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const {
    author,
    table,
    setTable,
    isPublic,
    setIsPublic,
    config,
    setConfig,
    maxTextLength,
    wordBreak,
    showInferenceDetails,
    evalId,
    setInComparisonMode,
    columnStates,
    setColumnState,
  } = useResultsViewStore();
  const { enableMakePublic } = useConfig();
  const { setStateFromConfig: _setStateFromConfig } = useMainStore();
  const { showToast } = useToast();
  const [searchText, setSearchText] = React.useState(searchParams.get('search') || '');
  const [debouncedSearchText] = useDebounce(searchText, 1000);
  const handleSearchTextChange = (text: string) => {
    setSearchText(text);
  };
  const { organization } = useAuth();
  const { config: appConfig } = useConfig();
  const canEditEvals = useCan(Actions.UPDATE, Subjects.EVAL);
  const canDeleteEvals = useCan(Actions.DELETE, Subjects.EVAL);

  const [shareModalOpen, setShareModalOpen] = React.useState(false);

  const [failureFilter, setFailureFilter] = React.useState<{ [key: string]: boolean }>({});
  const handleFailureFilterToggle = React.useCallback(
    (columnId: string, checked: boolean) => {
      setFailureFilter((prevFailureFilter) => ({ ...prevFailureFilter, [columnId]: checked }));
    },
    [setFailureFilter],
  );

  invariant(table, 'Table data must be loaded before rendering ResultsView');
  const { head } = table;

  const [filterMode, setFilterMode] = React.useState<FilterMode>('all');
  const handleFilterModeChange = (event: SelectChangeEvent<unknown>) => {
    const mode = event.target.value as FilterMode;
    setFilterMode(mode);

    const newFailureFilter: { [key: string]: boolean } = {};
    head.prompts.forEach((_, idx) => {
      const columnId = `Prompt ${idx + 1}`;
      newFailureFilter[columnId] = mode === 'failures';
    });
    setFailureFilter(newFailureFilter);
  };

  // State for anchor element
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const currentEvalId = evalId || defaultEvalId || 'default';

  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Function to open the eval actions menu
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleComparisonEvalSelected = async (compareEvalId: string) => {
    setAnchorEl(null);
    try {
      const response = await callApi(`/results/${compareEvalId}`, {
        cache: 'no-store',
      });
      const body = await response.json();

      const comparisonTable =
        body.version < 4 ? (body.results.table as EvaluateTable) : convertResultsToTable(body);

      // Combine the comparison table with the current table
      const combinedTable: EvaluateTable = {
        head: {
          prompts: [
            ...table.head.prompts.map((prompt) => ({
              ...prompt,
              label: `[${evalId || defaultEvalId || 'Eval A'}] ${prompt.label || ''}`,
            })),
            ...comparisonTable.head.prompts.map((prompt) => ({
              ...prompt,
              label: `[${compareEvalId}] ${prompt.label || ''}`,
            })),
          ],
          vars: table.head.vars, // Assuming vars are the same
        },
        body: table.body.map((row, index) => ({
          ...row,
          outputs: [...row.outputs, ...(comparisonTable.body[index]?.outputs || [])],
        })),
      };

      // Update the state with the combined table
      setTable(combinedTable);

      // Update other relevant state if needed
      setConfig({
        ...config,
        ...body.config,
        description: `Combined: "${config?.description || 'Eval A'}" and "${body.config?.description || 'Eval B'}"`,
      });
      setInComparisonMode(true);
    } catch (error) {
      console.error('Error fetching comparison eval:', error);
      alert('Failed to load comparison eval. Please try again.');
    }
  };

  const hasAnyDescriptions = React.useMemo(
    () => table.body?.some((row) => row.description),
    [table.body],
  );

  const promptOptions = head.prompts.map((prompt, idx) => {
    const label = prompt.label || prompt.display || prompt.raw;
    const provider = prompt.provider || 'unknown';
    const displayLabel = [
      label && `"${label.slice(0, 60)}${label.length > 60 ? '...' : ''}"`,
      provider && `[${provider}]`,
    ]
      .filter(Boolean)
      .join(' ');

    return {
      value: `Prompt ${idx + 1}`,
      label: displayLabel,
      description: label,
      group: 'Prompts',
    };
  });

  const columnData = React.useMemo(() => {
    return [
      ...(hasAnyDescriptions
        ? [{ value: 'description', label: 'Description', group: 'Other' }]
        : []),
      ...head.vars.map((varValue, idx) => ({
        value: `Variable ${idx + 1}`,
        label: `Var ${idx + 1}: ${varValue.length > 100 ? varValue.slice(0, 97) + '...' : varValue}`,
        description: varValue,
        group: 'Variables',
      })),
      ...promptOptions,
    ];
  }, [head.vars, promptOptions, hasAnyDescriptions]);

  const [configModalOpen, setConfigModalOpen] = React.useState(false);
  const [viewSettingsModalOpen, setViewSettingsModalOpen] = React.useState(false);

  const allColumns = React.useMemo(
    () => [
      ...(hasAnyDescriptions ? ['description'] : []),
      ...head.vars.map((_, idx) => `Variable ${idx + 1}`),
      ...head.prompts.map((_, idx) => `Prompt ${idx + 1}`),
    ],
    [hasAnyDescriptions, head.vars, head.prompts],
  );

  const currentColumnState = columnStates[currentEvalId] || {
    selectedColumns: [],
    columnVisibility: {},
  };

  const updateColumnVisibility = React.useCallback(
    (columns: string[]) => {
      const newColumnVisibility: VisibilityState = {};
      allColumns.forEach((col) => {
        newColumnVisibility[col] = columns.includes(col);
      });
      setColumnState(currentEvalId, {
        selectedColumns: columns,
        columnVisibility: newColumnVisibility,
      });
    },
    [allColumns, setColumnState, currentEvalId],
  );

  React.useEffect(() => {
    if (
      currentColumnState.selectedColumns.length === 0 ||
      !currentColumnState.selectedColumns.every((col: string) => allColumns.includes(col))
    ) {
      updateColumnVisibility(allColumns);
    }
  }, [allColumns, currentColumnState.selectedColumns, updateColumnVisibility]);

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const newSelectedColumns = Array.isArray(event.target.value)
        ? event.target.value
        : event.target.value.split(',');

      updateColumnVisibility(newSelectedColumns);
    },
    [updateColumnVisibility],
  );

  const handleShareClick = async () => {
    if (!evalId) {
      console.error('something is terribely wrong, share was called without evalId being set');
      return;
    }
    handleMenuClose();
    setShareModalOpen(true);
  };

  const handleDescriptionClick = async () => {
    invariant(config, 'Config must be loaded before clicking its description');
    const newDescription = window.prompt('Enter new description:', config.description);
    if (newDescription !== null && newDescription !== config.description) {
      const newConfig = { ...config, description: newDescription };
      try {
        const response = await callApi(`/results/${evalId}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ config: newConfig }),
        });
        if (!response.ok) {
          console.error('Failed to update table:', response.statusText);
          showToast('Failed to update table', 'error');
          return;
        }
        setConfig(newConfig);
        showToast('Eval updated', 'success');
      } catch (error) {
        console.error('Failed to update table:', error);
        showToast('Failed to update table', 'error');
      }
    }
  };

  const handleDeleteEvalClick = async () => {
    handleMenuClose();
    invariant(evalId, 'Eval ID must be set before deleting');
    if (window.confirm('Are you sure you want to delete this evaluation?')) {
      try {
        const response = await callApi(`/results/${evalId}`, {
          method: 'DELETE',
        });
        if (!response.ok) {
          console.error('Failed to delete evaluation:', response.statusText);
          showToast('Failed to delete evaluation', 'error');
          return;
        }
        showToast('Eval deleted', 'success');
        if (onDelete) {
          await onDelete(evalId);
        } else {
          navigate(ROUTES.eval);
        }
      } catch (error) {
        console.error('Failed to delete evaluation:', error);
        showToast('Failed to delete evaluation', 'error');
      }
    }
  };

  const [evalSelectorDialogOpen, setEvalSelectorDialogOpen] = React.useState(false);

  const handleEvalIdCopyClick = () => {
    navigator.clipboard.writeText(currentEvalId);
    showToast('Copied eval ID to clipboard', 'success');
  };

  const handleTogglePublic = async () => {
    if (!evalId) {
      return;
    }

    handleMenuClose();
    try {
      const response = await callApi(`/results/${evalId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ isPublic: !isPublic }),
      });
      if (!response.ok) {
        console.error('Failed to update public status:', response.statusText);
        showToast('Failed to update public status', 'error');
        return;
      }
      const updatedEval = await response.json();
      setIsPublic(updatedEval.isPublic);
      showToast(`Eval is now ${updatedEval.isPublic ? 'public' : 'private'}`, 'success');
    } catch (error) {
      console.error('Failed to update public status:', error);
      showToast('Failed to update public status', 'error');
    }
  };

  const handleSearchKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        handleSearchTextChange('');
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [handleSearchTextChange],
  );

  return (
    <div style={{ marginLeft: '1rem', marginRight: '1rem', paddingTop: '1rem' }}>
      <ResponsiveStack
        direction="row"
        mb={3}
        spacing={1}
        alignItems="center"
        className="eval-header"
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', maxWidth: 250 }}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={config?.description || evalId || ''}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowDropDownIcon />
                </InputAdornment>
              ),
            }}
            onClick={() => setEvalSelectorDialogOpen(true)}
            placeholder="Search or select an eval..."
            sx={{ cursor: 'pointer' }}
          />
          <EvalSelectorDialog
            open={evalSelectorDialogOpen}
            onClose={() => setEvalSelectorDialogOpen(false)}
            onEvalSelected={(evalId) => {
              setEvalSelectorDialogOpen(false);
              onRecentEvalSelected(evalId);
            }}
            title="Select an Eval"
            focusedEvalId={evalId as string}
          />
        </Box>
        {evalId && <EvalIdChip evalId={evalId} onCopy={handleEvalIdCopyClick} />}
        <AuthorChip author={author} />
        <PublicChip isPublic={isPublic} />
        {Object.keys(config?.tags || {}).map((tag) => (
          <Chip
            key={tag}
            size="small"
            label={`${tag}: ${config?.tags?.[tag]}`}
            sx={{ opacity: 0.7 }}
          />
        ))}
      </ResponsiveStack>
      <ResponsiveStack direction="row" spacing={1} alignItems="center" sx={{ gap: 2 }}>
        <Box>
          <FilterModeSelector filterMode={filterMode} onChange={handleFilterModeChange} />
        </Box>
        <Box>
          <TextField
            sx={(theme) => ({
              minWidth: 180,
              '& .MuiInputBase-root': {
                transition: theme.transitions.create(['background-color']),
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.04),
                },
              },
              '& .MuiInputAdornment-root': {
                transition: theme.transitions.create(['opacity', 'width', 'transform'], {
                  duration: theme.transitions.duration.shorter,
                }),
              },
              '& .clear-button': {
                opacity: searchText ? 1 : 0,
                width: searchText ? 'auto' : 0,
                transform: searchText ? 'scale(1)' : 'scale(0.8)',
                transition: theme.transitions.create(['opacity', 'width', 'transform'], {
                  duration: theme.transitions.duration.shorter,
                }),
              },
            })}
            size="small"
            label="Search"
            placeholder="Text or regex"
            value={searchText}
            onChange={(e) => handleSearchTextChange(e.target.value)}
            onKeyDown={handleSearchKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" className="clear-button">
                  <Tooltip title="Clear search (Esc)">
                    <IconButton
                      aria-label="clear search"
                      onClick={() => handleSearchTextChange('')}
                      edge="end"
                      size="small"
                      sx={{
                        visibility: searchText ? 'visible' : 'hidden',
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box flexGrow={1} />
        <Box display="flex" justifyContent="flex-end">
          <ResponsiveStack direction="row" spacing={2}>
            <ColumnSelector
              columnData={columnData}
              selectedColumns={currentColumnState.selectedColumns}
              onChange={handleChange}
            />
            <Tooltip title="Edit table view settings" placement="bottom">
              <Button
                color="primary"
                onClick={() => setViewSettingsModalOpen(true)}
                startIcon={<SettingsIcon />}
              >
                Table Settings
              </Button>
            </Tooltip>
            {user && (
              <Button color="primary" onClick={handleOpenMenu} endIcon={<ArrowDropDownIcon />}>
                Eval actions
              </Button>
            )}
            {config && user && (
              <Menu
                id="eval-actions-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {canEditEvals && (
                  <Tooltip title="Edit the name of this eval" placement="left">
                    <MenuItem onClick={handleDescriptionClick}>
                      <ListItemIcon>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      Edit name
                    </MenuItem>
                  </Tooltip>
                )}
                {!organization?.canUseRedteam && appConfig?.isPromptfooCloud && (
                  <Tooltip title="Share" placement="left">
                    <MenuItem onClick={handleShareClick}>
                      <ListItemIcon>
                        <Share fontSize="small" />
                      </ListItemIcon>
                      Create Share Link
                    </MenuItem>
                  </Tooltip>
                )}
                {enableMakePublic && canEditEvals && (
                  <Tooltip
                    title={`Make this eval ${isPublic ? 'accessible only to your organization' : 'publicly accessible to anyone with the URL'}`}
                    placement="left"
                  >
                    <MenuItem onClick={handleTogglePublic}>
                      <ListItemIcon>
                        {isPublic ? <LockIcon fontSize="small" /> : <PublicIcon fontSize="small" />}
                      </ListItemIcon>
                      Make {isPublic ? 'Private' : 'Public'}
                    </MenuItem>
                  </Tooltip>
                )}
                <CompareEvalMenuItem onComparisonEvalSelected={handleComparisonEvalSelected} />
                <Tooltip title="View the configuration that defines this eval" placement="left">
                  <MenuItem onClick={() => setConfigModalOpen(true)}>
                    <ListItemIcon>
                      <VisibilityIcon fontSize="small" />
                    </ListItemIcon>
                    View YAML
                  </MenuItem>
                </Tooltip>
                <DownloadMenu />
                {canDeleteEvals && (
                  <Tooltip title="Delete this eval" placement="left">
                    <MenuItem onClick={handleDeleteEvalClick}>
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      Delete
                    </MenuItem>
                  </Tooltip>
                )}
              </Menu>
            )}
            {(config?.redteam || config?.metadata?.redteam) && (
              <Tooltip title="View vulnerability scan report" placement="bottom">
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<EyeIcon />}
                  onClick={() => navigate(`${ROUTES.redteam.report}/${evalId || defaultEvalId}`)}
                >
                  Vulnerability Report
                </Button>
              </Tooltip>
            )}
          </ResponsiveStack>
        </Box>
      </ResponsiveStack>
      <ResultsCharts columnVisibility={currentColumnState.columnVisibility} />
      <ResultsTable
        maxTextLength={maxTextLength}
        columnVisibility={currentColumnState.columnVisibility}
        wordBreak={wordBreak}
        showStats={showInferenceDetails}
        filterMode={filterMode}
        failureFilter={failureFilter}
        searchText={searchText}
        debouncedSearchText={debouncedSearchText}
        onFailureFilterToggle={handleFailureFilterToggle}
        onSearchTextChange={handleSearchTextChange}
        setFilterMode={setFilterMode}
      />
      <ConfigModal
        open={configModalOpen}
        onClose={() => setConfigModalOpen(false)}
        config={config}
        title="Configuration"
      />

      <SettingsModal open={viewSettingsModalOpen} onClose={() => setViewSettingsModalOpen(false)} />
      <EvalSelectorKeyboardShortcut
        recentEvals={recentEvals}
        onRecentEvalSelected={onRecentEvalSelected}
      />
      <ShareModal
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        evalId={evalId || defaultEvalId || ''}
      />
    </div>
  );
}
