import React, { useContext, useState, useEffect } from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import DarkMode from '@app/components/DarkMode';
import LoggedInAs from '@cloud-ui/components/LoggedInAs';
import Logo from '@cloud-ui/components/Logo';
import { ROUTES } from '@cloud-ui/constants';
import { useConfig } from '@cloud-ui/contexts/ConfigContext';
import { useCan } from '@cloud-ui/contexts/RbacContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import MenuIcon from '@mui/icons-material/Menu';
import type { ButtonProps } from '@mui/material';
import {
  AppBar,
  Box,
  Button,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  IconButton,
  Drawer,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Actions } from '@shared/dto/rbac';
import { Subjects } from '@shared/dto/rbac';
import { AuthContext } from '../contexts/AuthContext';
import { useMenu } from '../hooks/useMenu';
import { useUIStore } from '../stores/uiStore';
import TeamMenu from './TeamMenu';

const NavButton = styled(Button)<ButtonProps & RouterLinkProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0.5, 1),
  minWidth: 'auto',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.active': {
    backgroundColor: theme.palette.action.selected,
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.85rem',
  },
}));

function NavLink({
  href,
  label,
  onClick,
  disabled = false,
  isMobileView = false,
}: {
  href: string;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  isMobileView?: boolean;
}) {
  const location = useLocation();
  const isActive = location.pathname === href;

  const button = (
    <NavButton
      component={RouterLink}
      to={href}
      className={isActive ? 'active' : ''}
      onClick={onClick}
      sx={{
        ...(disabled && {
          color: 'text.disabled',
          pointerEvents: 'auto',
        }),
        ...(isMobileView && {
          justifyContent: 'flex-start',
          textAlign: 'left',
          width: '100%',
        }),
      }}
    >
      {label}
      {disabled && <LockIcon sx={{ fontSize: 16, ml: 0.5, verticalAlign: 'middle' }} />}
    </NavButton>
  );

  return button;
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[1],
  marginBottom: theme.spacing(2),
}));

const NavToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
  width: '100%',
  padding: '0 16px',
});

const NavSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  maxWidth: '100%',
  flexWrap: 'nowrap',
  [theme.breakpoints.between('md', 'lg')]: {
    gap: '0.75rem',
  },
  [theme.breakpoints.down('md')]: {
    gap: '0.5rem',
  },
}));

interface NavigationProps {
  darkMode: boolean;
  onToggleDarkMode: () => void;
}

function RedteamMenu({
  disabled = false,
  isMobileView = false,
}: {
  disabled?: boolean;
  isMobileView?: boolean;
}) {
  const { anchorEl, setAnchorEl, handleMouseEnter, handleMouseLeave } = useMenu();
  const { enableServerSideJobs } = useConfig();
  const [isExpanded, setIsExpanded] = useState(isMobileView);
  const showTargets = useCan(Actions.READ, Subjects.PROVIDER);
  const showPlugins = useCan(Actions.READ, Subjects.PLUGIN_COLLECTION);
  const showConfigs = useCan(Actions.READ, Subjects.CONFIG);
  const showJobs = useCan(Actions.READ, Subjects.JOB);

  const handleMenuItemClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    setAnchorEl(null);
    setIsExpanded(false);
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    if (isMobileView) {
      e.stopPropagation();
      setIsExpanded(!isExpanded);
    } else {
      // For desktop, clicking should also show the menu
      setAnchorEl(e.currentTarget as HTMLElement);
    }
  };

  const mobileMenuItems = (
    <Box sx={{ pl: 2, display: 'flex', flexDirection: 'column', width: '100%', gap: 0.5, my: 1 }}>
      {showTargets && (
        <Button
          component={RouterLink}
          to={ROUTES.redteam.targets}
          onClick={handleMenuItemClick}
          disabled={disabled}
          sx={{
            justifyContent: 'flex-start',
            color: 'text.secondary',
            fontSize: '0.875rem',
            py: 0.5,
            ...(disabled && {
              color: 'text.disabled',
            }),
          }}
        >
          Targets
          {disabled && <LockIcon sx={{ fontSize: 16, ml: 0.5, verticalAlign: 'middle' }} />}
        </Button>
      )}
      {showPlugins && (
        <Button
          component={RouterLink}
          to={ROUTES.redteam.plugins}
          onClick={handleMenuItemClick}
          disabled={disabled}
          sx={{
            justifyContent: 'flex-start',
            color: 'text.secondary',
            fontSize: '0.875rem',
            py: 0.5,
            ...(disabled && {
              color: 'text.disabled',
            }),
          }}
        >
          Plugin Collections
          {disabled && <LockIcon sx={{ fontSize: 16, ml: 0.5, verticalAlign: 'middle' }} />}
        </Button>
      )}
      {showConfigs ? (
        <Button
          component={RouterLink}
          to={ROUTES.redteam.configs}
          onClick={handleMenuItemClick}
          disabled={disabled}
          sx={{
            justifyContent: 'flex-start',
            color: 'text.secondary',
            fontSize: '0.875rem',
            py: 0.5,
            ...(disabled && {
              color: 'text.disabled',
            }),
          }}
        >
          Scan Configurations
          {disabled && <LockIcon sx={{ fontSize: 16, ml: 0.5, verticalAlign: 'middle' }} />}
        </Button>
      ) : null}
      {enableServerSideJobs && showJobs && (
        <Button
          component={RouterLink}
          to={ROUTES.jobs}
          onClick={handleMenuItemClick}
          disabled={disabled}
          sx={{
            justifyContent: 'flex-start',
            color: 'text.secondary',
            fontSize: '0.875rem',
            py: 0.5,
            ...(disabled && {
              color: 'text.disabled',
            }),
          }}
        >
          Scan History
          {disabled && <LockIcon sx={{ fontSize: 16, ml: 0.5, verticalAlign: 'middle' }} />}
        </Button>
      )}
    </Box>
  );

  if (!showTargets && !showPlugins && !showConfigs && !showJobs) {
    return null;
  }
  return (
    <>
      <Button
        id="redteam-button"
        onClick={handleButtonClick}
        onMouseEnter={isMobileView ? undefined : handleMouseEnter}
        onMouseLeave={isMobileView ? undefined : (e) => handleMouseLeave(e, 'redteam-menu')}
        aria-controls={anchorEl || isExpanded ? 'redteam-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) || isExpanded}
        endIcon={
          isMobileView ? (
            <ExpandMoreIcon
              sx={{
                transform: isExpanded ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.3s',
              }}
            />
          ) : undefined
        }
        sx={{
          color: 'text.primary',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
          ...(isExpanded && {
            backgroundColor: 'action.selected',
          }),
        }}
      >
        Redteam
      </Button>

      {isMobileView ? (
        isExpanded && mobileMenuItems
      ) : (
        <Popper
          id="redteam-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          placement="bottom-start"
          onMouseLeave={(e) => handleMouseLeave(e, 'redteam-button')}
          sx={{ zIndex: 999 }}
          onClick={(e) => e.stopPropagation()}
        >
          <Paper>
            <MenuList>
              {showTargets && (
                <MenuItem
                  component={RouterLink}
                  to={ROUTES.redteam.targets}
                  onClick={() => setAnchorEl(null)}
                  sx={{
                    fontSize: '0.875rem',
                    ...(disabled && {
                      color: 'text.disabled',
                      pointerEvents: 'auto',
                    }),
                  }}
                >
                  Targets
                  {disabled && <LockIcon sx={{ fontSize: 16, ml: 0.5, verticalAlign: 'middle' }} />}
                </MenuItem>
              )}
              {showPlugins && (
                <MenuItem
                  component={RouterLink}
                  to={ROUTES.redteam.plugins}
                  onClick={handleMenuItemClick}
                  disabled={disabled}
                  sx={{
                    fontSize: '0.875rem',
                    ...(disabled && {
                      color: 'text.disabled',
                    }),
                  }}
                >
                  Plugin Collections
                  {disabled && <LockIcon sx={{ fontSize: 16, ml: 0.5, verticalAlign: 'middle' }} />}
                </MenuItem>
              )}
              {showConfigs && (
                <MenuItem
                  component={RouterLink}
                  to={ROUTES.redteam.configs}
                  onClick={handleMenuItemClick}
                  disabled={disabled}
                  sx={{
                    fontSize: '0.875rem',
                    ...(disabled && {
                      color: 'text.disabled',
                    }),
                  }}
                >
                  Scan Configurations
                  {disabled && <LockIcon sx={{ fontSize: 16, ml: 0.5, verticalAlign: 'middle' }} />}
                </MenuItem>
              )}
              {enableServerSideJobs && showJobs && (
                <MenuItem
                  component={RouterLink}
                  to={ROUTES.jobs}
                  onClick={handleMenuItemClick}
                  disabled={disabled}
                  sx={{
                    fontSize: '0.875rem',
                    ...(disabled && {
                      color: 'text.disabled',
                    }),
                  }}
                >
                  Scan History
                  {disabled && <LockIcon sx={{ fontSize: 16, ml: 0.5, verticalAlign: 'middle' }} />}
                </MenuItem>
              )}
            </MenuList>
          </Paper>
        </Popper>
      )}
    </>
  );
}

function Navigation({ darkMode, onToggleDarkMode }: NavigationProps) {
  const { user, organization } = useContext(AuthContext) || {};
  const evalOnly = !organization?.canUseRedteam;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const canViewEvals = useCan(Actions.READ, Subjects.EVAL);
  const canViewIssues = useCan(Actions.READ, Subjects.ISSUE);
  const isNavbarVisible = useUIStore(
    (state: { isNavbarVisible: boolean }) => state.isNavbarVisible,
  );

  // Force re-render when isMobile changes to update menu expanded states
  useEffect(() => {
    // This effect runs when isMobile changes
  }, [isMobile]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNavClick = () => {
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  if (!isNavbarVisible) {
    return null;
  }

  const navItems = user && (
    <>
      <RedteamMenu disabled={evalOnly} isMobileView={isMobile} />
      {canViewEvals && (
        <NavLink
          href={ROUTES.redteam.dashboard}
          label="Dashboard"
          disabled={evalOnly}
          onClick={handleNavClick}
          isMobileView={isMobile}
        />
      )}
      {canViewIssues && (
        <NavLink
          href={ROUTES.redteam.vulnerabilities}
          label="Vulnerabilities"
          disabled={evalOnly}
          onClick={handleNavClick}
          isMobileView={isMobile}
        />
      )}
      {canViewEvals && (
        <NavLink
          href={ROUTES.redteam.report}
          label="Reports"
          disabled={evalOnly}
          onClick={handleNavClick}
          isMobileView={isMobile}
        />
      )}
      {canViewEvals && (
        <NavLink
          href={ROUTES.evals}
          label="Evals"
          onClick={handleNavClick}
          isMobileView={isMobile}
        />
      )}
    </>
  );

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        '& button': {
          justifyContent: 'flex-start',
          paddingLeft: 1,
          textAlign: 'left',
          width: '100%',
        },
      }}
    >
      {user && (
        <Box sx={{ mb: 2 }}>
          <TeamMenu />
        </Box>
      )}
      <Box onClick={toggleDrawer}>{navItems}</Box>
    </Box>
  );

  return (
    <StyledAppBar position="static" elevation={0}>
      <NavToolbar>
        <NavSection sx={{ overflow: 'hidden', flexWrap: 'nowrap' }}>
          {user && isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
              sx={{ mr: 1 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Logo />
          {user && <TeamMenu />}
          {!isMobile && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: { xs: '0.5rem', sm: '0.75rem', md: '1rem' },
                flexWrap: 'nowrap',
                maxWidth: '100%',
                overflow: 'hidden',
                '& button': {
                  minWidth: 'auto',
                  px: { xs: 1, sm: 1.5, md: 2 },
                  whiteSpace: 'nowrap',
                },
              }}
            >
              {navItems}
            </Box>
          )}
        </NavSection>
        <NavSection sx={{ flexShrink: 0 }}>
          <DarkMode onToggleDarkMode={onToggleDarkMode} />
          <LoggedInAs />
        </NavSection>
      </NavToolbar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawerContent}
      </Drawer>
    </StyledAppBar>
  );
}

export default Navigation;
