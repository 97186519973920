import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useState, useEffect, useRef } from 'react';
import { useCan } from '@cloud-ui/contexts/RbacContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { useGetProvider } from '@cloud-ui/pages/redteam/hooks/providers';
import {
  getIssueEvalResults,
  getIssueRemediation,
  postIssueComment,
} from '@cloud-ui/utils/api/issues';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import ReplyIcon from '@mui/icons-material/Reply';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Chip,
  Button,
  Divider,
  Tabs,
  Tab,
  Tooltip,
  useTheme,
  TextField,
} from '@mui/material';
import { IssueStatus, type IssueStatusType } from '@shared/constants';
import { IssueEventType, type IssueHistoryDTO, type IssueDTO } from '@shared/dto';
import { Actions, Subjects } from '@shared/dto/rbac';
import ExplanationTab from './ExplanationTab';
import HistoryTab from './HistoryTab';
import SummaryTab from './SummaryTab';
import TestCasesTab from './TestCasesTab';

interface IssueProps {
  issue: IssueDTO;
  onClose: () => void;
  onIssueUpdate: (changes: Partial<IssueDTO>) => void;
  isUpdating: boolean;
}

export default function Issue({ issue, onClose, onIssueUpdate, isUpdating }: IssueProps) {
  const [activeTab, setActiveTab] = useState(0);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [comment, setComment] = useState('');
  const { showToast } = useToast();
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const canUpdateIssue = useCan(Actions.UPDATE, Subjects.ISSUE);
  const queryClient = useQueryClient();
  const [lastComment, setLastComment] = useState<IssueHistoryDTO | null>(null);

  useEffect(() => {
    if (issue.history.length > 0) {
      const lastComment = issue.history.find((event) => event.type === IssueEventType.CommentAdded);
      setLastComment(lastComment || null);
    }
  }, [issue.history]);

  // Reset scroll position when issue changes
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [issue.id]);

  const {
    data: evalResults,
    isLoading: isEvalResultsLoading,
    isError: isEvalResultsError,
  } = useQuery({
    queryKey: ['issue_evalresults', issue.id],
    queryFn: () => getIssueEvalResults(issue.id),
  });

  const { data: remediation } = useQuery({
    queryKey: ['issue_remediation', issue.id],
    queryFn: () => getIssueRemediation(issue.id),
  });

  const { provider } = useGetProvider(issue.providerId || '');

  const commentMutation = useMutation({
    mutationFn: (newComment: string) => postIssueComment(issue.id, newComment),
    onSuccess: () => {
      showToast('Comment added successfully', 'success');
      setComment('');
      setShowCommentBox(false);
      // Refresh the issue data to show the new comment
      queryClient.invalidateQueries({ queryKey: ['issue_evalresults', issue.id] });
    },
    onError: () => {
      showToast('Failed to add comment', 'error');
    },
  });

  const handleStatusChange = (newStatus: IssueStatusType) => {
    onIssueUpdate({ status: newStatus });
    issue.status = newStatus;
    setShowCommentBox(true);
  };

  const handleCommentSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (comment.trim()) {
      commentMutation.mutate(comment);
    }
  };

  const getActionButtons = () => {
    if (!canUpdateIssue) {
      return null;
    }
    switch (issue.status) {
      case 'open':
        return (
          <>
            <Button
              variant="text"
              size="small"
              onClick={() => handleStatusChange(IssueStatus.FIXED)}
              startIcon={<CheckCircleOutlineIcon />}
              sx={{ color: 'success.main' }}
            >
              Mark as Fixed
            </Button>
            <Divider orientation="vertical" flexItem sx={{ bgcolor: theme.palette.divider }} />
            <Button
              variant="text"
              size="small"
              onClick={() => handleStatusChange(IssueStatus.IGNORED)}
              startIcon={<VisibilityOffIcon />}
              sx={{ color: 'warning.main' }}
            >
              Ignore
            </Button>
            <Divider orientation="vertical" flexItem sx={{ bgcolor: theme.palette.divider }} />
            <Button
              variant="text"
              size="small"
              onClick={() => handleStatusChange(IssueStatus.FALSE_POSITIVE)}
              startIcon={<CancelOutlinedIcon />}
              sx={{ color: 'error.main' }}
            >
              False Positive
            </Button>
          </>
        );
      case 'fixed':
      case 'ignored':
      case 'false_positive':
        return (
          <Button
            variant="text"
            size="small"
            onClick={() => handleStatusChange(IssueStatus.OPEN)}
            startIcon={<CheckCircleOutlineIcon />}
            sx={{ color: 'primary.main' }}
          >
            Reopen
          </Button>
        );
    }
  };

  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        showToast('Link copied to clipboard', 'success');
      })
      .catch((err) => {
        showToast('Failed to copy link', 'error');
      });
  };

  return (
    <Paper
      ref={containerRef}
      elevation={3}
      sx={{
        p: 3,
        position: 'relative',
        borderRadius: 2,
        bgcolor: theme.palette.background.paper,
        transition: theme.transitions.create(['background-color', 'box-shadow'], {
          duration: theme.transitions.duration.standard,
        }),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mr: 1 }}>
          {issue.weakness}
        </Typography>
        <Tooltip title="Copy link to this issue">
          <IconButton onClick={handleCopyLink} size="small">
            <LinkIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Chip
          label={issue.status.charAt(0).toUpperCase() + issue.status.slice(1).replace('_', ' ')}
          color="default"
          size="small"
          sx={{
            mr: 2,
            bgcolor:
              theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[300],
            color:
              issue.status === 'open'
                ? theme.palette.primary.main
                : theme.palette.mode === 'dark'
                  ? theme.palette.grey[300]
                  : theme.palette.text.primary,
          }}
        />
        <Box sx={{ display: 'flex', gap: 1 }}>{getActionButtons()}</Box>
      </Box>
      {lastComment && activeTab === 0 && (
        <Box
          sx={{
            mb: 2,
            p: 2,
            bgcolor: theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100',
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              mb: 1,
            }}
          >
            <Typography variant="subtitle2" color="text.secondary">
              Last comment:
            </Typography>
            <Button
              size="small"
              variant="text"
              onClick={() => setActiveTab(2)}
              startIcon={<ReplyIcon />}
            >
              Reply
            </Button>
          </Box>
          <Typography>{lastComment.text}</Typography>
          <Typography variant="caption" color="text.secondary">
            {lastComment.name} {new Date(lastComment.createdAt).toLocaleString()}
          </Typography>
        </Box>
      )}

      {showCommentBox && (
        <Box sx={{ mb: 2 }}>
          <form onSubmit={handleCommentSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Add a comment about this status change..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              sx={{ mb: 1 }}
            />
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button type="submit" variant="contained" disabled={commentMutation.isPending}>
                {commentMutation.isPending ? 'Submitting...' : 'Add Comment'}
              </Button>
              <Button variant="text" onClick={() => setShowCommentBox(false)}>
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      )}

      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        sx={{
          mb: 2,
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tab label="Summary" />
        <Tab label="Attacks" />
        <Tab label="History" />
        <Tab label="Explanation and Remediations" />
      </Tabs>

      <Box
        sx={{
          bgcolor: theme.palette.background.paper,
          flex: 1,
          overflow: 'auto',
          maxHeight: 'calc(100% - 120px)', // Account for issue header and tabs
          p: 0, // Remove padding from container - will be applied in each tab
        }}
      >
        {activeTab === 0 && (
          <SummaryTab
            issue={issue}
            evalResults={evalResults || []}
            isEvalResultsLoading={isEvalResultsLoading}
            isEvalResultsError={isEvalResultsError}
            onIssueUpdate={onIssueUpdate}
            provider={provider}
          />
        )}

        {activeTab === 1 && (
          <TestCasesTab
            evalResults={evalResults || []}
            isEvalResultsLoading={isEvalResultsLoading}
            isEvalResultsError={isEvalResultsError}
            issueId={issue.id}
          />
        )}

        {activeTab === 2 && <HistoryTab issue={issue} />}
        {activeTab === 3 && <ExplanationTab remediation={remediation} />}
      </Box>
    </Paper>
  );
}
