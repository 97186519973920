import type { ProviderOptions } from '@promptfoo/types';
import type {
  ProviderDTO,
  CreateProviderRequest,
  UpdateProviderRequest,
  GetByIdProviderOptions,
} from '@shared/dto/providers';
import { ApiError, callApi } from '../api';

export async function testProvider(provider: ProviderOptions, specialProviderType?: string) {
  return await callApi('/providers/test-provider', {
    method: 'POST',
    body: JSON.stringify({ provider, type: specialProviderType }),
  });
}

// Provider functions
export async function listProviders(
  teamId?: string,
  opts?: GetByIdProviderOptions,
): Promise<ProviderDTO[]> {
  const params = new URLSearchParams();
  if (teamId) {
    params.set('teamId', teamId);
  }
  if (opts?.includeDeleted) {
    params.set('includeDeleted', 'true');
  }
  const response = await callApi(`/providers${params.toString() ? `?${params.toString()}` : ''}`);
  return response.json();
}

// Helper function to get providers with deleted items included
export async function listProvidersWithDeleted(teamId?: string): Promise<ProviderDTO[]> {
  return listProviders(teamId, { includeDeleted: true });
}

export async function getProvider(id: string, opts?: GetByIdProviderOptions): Promise<ProviderDTO> {
  const params = new URLSearchParams();
  if (opts?.includeDeleted) {
    params.set('includeDeleted', 'true');
  }
  const response = await callApi(
    `/providers/${id}${params.toString() ? `?${params.toString()}` : ''}`,
  );
  return response.json();
}

// Helper function to get a provider even if it's deleted
export async function getProviderWithDeleted(id: string): Promise<ProviderDTO> {
  return getProvider(id, { includeDeleted: true });
}

export async function createProvider(data: CreateProviderRequest): Promise<ProviderDTO> {
  const response = await callApi('/providers', {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function updateProvider(
  id: string,
  data: UpdateProviderRequest,
): Promise<ProviderDTO> {
  const response = await callApi(`/providers/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function deleteProvider(id: string): Promise<void> {
  try {
    await callApi(`/providers/${id}`, { method: 'DELETE' });
  } catch (error) {
    if (error instanceof ApiError && error.status === 409) {
      throw error.json;
    }
    throw error;
  }
}
