import { RedteamRunOptions, UnifiedConfig } from '@promptfoo/types';
import z from 'zod';

export type EvalJobStatus = 'running' | 'completed' | 'failed' | 'canceled';

export const JobProgressSchema = z.object({
  completed: z.number(),
  total: z.number(),
  passes: z.number(),
  failures: z.number(),
  errors: z.number(),
});

const LogMessageSchema = z.object({
  type: z.literal('log'),
  message: z.string(),
});

const ProgressMessageSchema = z.object({
  type: z.literal('progress'),
  progress: JobProgressSchema,
});

const CompletionMessageSchema = z.object({
  type: z.literal('completion'),
});

export const SSEMessageSchema = z.union([
  LogMessageSchema,
  ProgressMessageSchema,
  CompletionMessageSchema,
]);

export type SSEMessage = z.infer<typeof SSEMessageSchema>;

export type JobProgress = z.infer<typeof JobProgressSchema>;

export const EvalJobSchema = z.object({
  id: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  status: z.enum(['running', 'completed', 'failed', 'canceled']),
  config: z.custom<UnifiedConfig>(),
  configId: z.string().nullable(),
  redteam: z.boolean(),
  evalId: z.string().nullable(),
  options: z.custom<RedteamRunOptions>(),
  progress: JobProgressSchema.optional(),
  teamId: z.string(),
});

export const StartJobSchema = z.object({
  config: z.any(),
  force: z.boolean().optional(),
  verbose: z.boolean().optional(),
  delay: z.number().optional(),
  configId: z.string().optional().nullable(),
  teamId: z.string(),
});

export const JobsQueryParamsSchema = z.object({
  limit: z.string().optional(),
  offset: z.string().optional(),
});

export type JobsQueryParams = z.infer<typeof JobsQueryParamsSchema>;
export type EvalJobDTO = z.infer<typeof EvalJobSchema>;
export type StartJobDTO = z.infer<typeof StartJobSchema>;
