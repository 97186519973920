import { z } from 'zod';

/**
 * Schema for route parameters
 */
export const EvalIdParamSchema = z.object({
  id: z.string(),
});

export type EvalIdParam = z.infer<typeof EvalIdParamSchema>;

export const ResultIdParamSchema = z.object({
  id: z.string().uuid('Invalid eval ID format'),
  resultsId: z.string().uuid('Invalid result ID format'),
});

export type ResultIdParam = z.infer<typeof ResultIdParamSchema>;

export const EvalResultIdParamSchema = z.object({
  id: z.string(),
});

export type EvalResultIdParam = z.infer<typeof EvalResultIdParamSchema>;

export const UpdateEvalSchema = z.object({
  config: z.any().optional(),
  table: z.any().optional(),
  isPublic: z.boolean().optional(),
});

export type UpdateEvalRequest = z.infer<typeof UpdateEvalSchema>;

export const ResultsSchema = z.array(
  z.object({
    id: z.string().optional(),
    prompt: z.any(),
    provider: z.any(),
    vars: z.record(z.string(), z.any()).optional(),
    output: z.any(),
    latencyMs: z.number().optional(),
    success: z.boolean().optional(),
    error: z.string().optional(),
    gradingResult: z.any().optional(),
  }),
);

export type ResultsRequest = z.infer<typeof ResultsSchema>;

export const GradeResultSchema = z.object({
  result: z.string(),
  score: z.number().min(0).max(1).optional(),
  reasonsHtml: z.string().optional(),
  reasons: z.array(z.string()).optional(),
});

export type GradeResultRequest = z.infer<typeof GradeResultSchema>;

export const GradeResultIdSchema = z.object({
  evalResultId: z.string().uuid(),
});

export type GradeResultIdRequest = z.infer<typeof GradeResultIdSchema>;

export const CreateEvalSchema = z.object({
  createdAt: z.date().or(z.string().datetime()),
  config: z
    .object({
      metadata: z
        .object({
          teamId: z.string().uuid().optional(),
        })
        .optional(),
    })
    .optional(),
});

export type CreateEvalRequest = z.infer<typeof CreateEvalSchema>;

export const ShareEvalRequestSchema = z.object({
  roleId: z.string().uuid('Invalid role ID format').optional(),
});

export type ShareEvalRequest = z.infer<typeof ShareEvalRequestSchema>;

export const ShareEvalResponseSchema = z.object({
  link: z.string(),
});

export type ShareEvalResponse = z.infer<typeof ShareEvalResponseSchema>;
