import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import {
  useOrganizationUsers,
  useOrganizationServiceAccounts,
} from '@cloud-ui/hooks/useOrganization';
import { useRoles } from '@cloud-ui/hooks/useRoles';
import {
  useTeam,
  useTeamMembers,
  useUpdateTeam,
  useAddTeamMember,
  useRemoveTeamMember,
} from '@cloud-ui/hooks/useTeams';
import { updateTeamMemberRole } from '@cloud-ui/utils/api/teams';
import {
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Autocomplete,
  MenuItem,
  Select,
} from '@mui/material';
import type { UserDTO } from '@shared/dto/users';
import { AccountType } from '@shared/dto/users';
import { format } from 'date-fns';

export default function TeamDetails() {
  const { orgId, teamId } = useParams<{ orgId: string; teamId: string }>();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { data: team, isLoading: isLoadingTeam } = useTeam(teamId!);
  const {
    data: members,
    isLoading: isLoadingMembers,
    refetch: refetchMembers,
  } = useTeamMembers(teamId!);
  const { mutate: updateTeam } = useUpdateTeam(teamId!);
  const { mutate: addMember } = useAddTeamMember(teamId!);
  const { mutate: removeMember } = useRemoveTeamMember(teamId!);
  const { data: users } = useOrganizationUsers(orgId!);
  const { data: serviceAccounts = [] } = useOrganizationServiceAccounts(orgId!);
  const { data: roles } = useRoles(orgId!);
  const [teamName, setTeamName] = useState('');

  useEffect(() => {
    if (team?.name) {
      setTeamName(team.name);
    }
  }, [team?.name]);

  const handleSave = () => {
    updateTeam({ name: teamName });
  };

  const handleRemoveMember = (userId: string) => {
    removeMember(userId);
  };

  const handleAddUserDirectly = (user: UserDTO | null) => {
    if (!user) {
      return;
    }

    addMember(user.id, {
      onSuccess: () => {
        setTimeout(() => {}, 0);
      },
    });
  };

  const handleChangeRole = async (userId: string, roleId: string) => {
    try {
      await updateTeamMemberRole(teamId!, userId, roleId);
      showToast('Role updated successfully', 'success');
      refetchMembers();
    } catch (error) {
      console.error('Error updating role', error);
      showToast('Error updating role, check console for more details', 'error');
    }
  };

  const handleAddServiceAccountDirectly = (serviceAccount: UserDTO | null) => {
    if (!serviceAccount) {
      return;
    }

    addMember(serviceAccount.id, {
      onSuccess: () => {
        setTimeout(() => {}, 0);
      },
    });
  };

  const availableUsers = useMemo((): UserDTO[] => {
    if (!users || !members) {
      return [];
    }
    const memberIds = new Set(members.map((member) => member.id));
    return users.filter(
      (user) => !memberIds.has(user.id) && user.accountType !== AccountType.SERVICE,
    );
  }, [users, members]);

  const availableServiceAccounts = useMemo((): UserDTO[] => {
    if (!serviceAccounts || !members) {
      return [];
    }
    const memberIds = new Set(members.map((member) => member.id));
    return serviceAccounts.filter((account) => !memberIds.has(account.id));
  }, [serviceAccounts, members]);

  const serviceAccountMembers = useMemo(() => {
    if (!members) {
      return [];
    }
    return members.filter((member) => member.accountType === AccountType.SERVICE);
  }, [members]);

  const userMembers = useMemo(() => {
    if (!members) {
      return [];
    }
    return members.filter((member) => member.accountType === AccountType.USER);
  }, [members]);

  if (isLoadingTeam) {
    return <Typography>Loading...</Typography>;
  }

  if (!team) {
    return <Typography>Team not found</Typography>;
  }

  return (
    <Box maxWidth="100%" mx="auto" p={2}>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton
          onClick={() => navigate(`${ROUTES.organization.index}/${orgId}/teams`)}
          sx={{ mr: 2 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Team Details</Typography>
      </Box>

      <Card sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" mb={3}>
          Team Information
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 4 }}>
          <TextField
            label="Team Name"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            sx={{ minWidth: 300 }}
          />
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            disabled={!teamName.trim() || teamName === team.name}
          >
            Save Changes
          </Button>
        </Box>
        <Typography variant="body2" color="text.secondary">
          Created: {format(new Date(team.createdAt), 'MMM d, yyyy')}
        </Typography>
      </Card>

      <Card sx={{ mb: 4 }}>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Team Members</Typography>
        </Box>

        <Box sx={{ p: 2, mb: 2, width: '30%', minWidth: '450px' }}>
          <Autocomplete
            options={availableUsers}
            onChange={(_, newValue) => handleAddUserDirectly(newValue)}
            getOptionLabel={(option) => `${option.name} (${option.email})`}
            renderInput={(params) => <TextField {...params} label="Add team member" fullWidth />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={null}
            clearOnBlur
            blurOnSelect
          />
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell sx={{ width: '20%' }}>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingMembers ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography align="center">Loading members...</Typography>
                </TableCell>
              </TableRow>
            ) : userMembers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography align="center">No members yet</Typography>
                </TableCell>
              </TableRow>
            ) : (
              userMembers.map((member) => (
                <TableRow key={member.id}>
                  <TableCell>{member.name}</TableCell>
                  <TableCell>{member.email}</TableCell>
                  <TableCell>
                    <Select
                      value={member.role?.id}
                      onChange={(e) => handleChangeRole(member.id, e.target.value)}
                    >
                      {roles?.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <span>
                      <IconButton onClick={() => handleRemoveMember(member.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Card>

      <Card>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Service Accounts</Typography>
        </Box>

        <Box sx={{ p: 2, mb: 2, width: '30%', minWidth: '450px' }}>
          <Autocomplete
            options={availableServiceAccounts}
            onChange={(_, newValue) => handleAddServiceAccountDirectly(newValue)}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Add service account" fullWidth />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={null}
            clearOnBlur
            blurOnSelect
          />
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell sx={{ width: '20%' }}>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingMembers ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography align="center">Loading service accounts...</Typography>
                </TableCell>
              </TableRow>
            ) : serviceAccountMembers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography align="center">No service accounts yet</Typography>
                </TableCell>
              </TableRow>
            ) : (
              serviceAccountMembers.map((member) => (
                <TableRow key={member.id}>
                  <TableCell>{member.name}</TableCell>
                  <TableCell>
                    <Select
                      value={member.role?.id}
                      onChange={(e) => handleChangeRole(member.id, e.target.value)}
                    >
                      {roles?.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <span>
                      <IconButton onClick={() => handleRemoveMember(member.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Card>
    </Box>
  );
}
