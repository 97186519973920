import { useMemo } from 'react';
import DataGrid, { type GridColDef } from '@cloud-ui/components/DataGrid';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import { useReports, type ExtendedResultLightweightWithLabel } from '@cloud-ui/hooks/useReports';
import { Typography, Box, Chip } from '@mui/material';
import { type GridRenderCellParams } from '@mui/x-data-grid';
import { useGetProviders } from '../../hooks/providers';

/**
 * Displays a list of redteam reports.
 *
 * @param onReportSelected - Callback to handle when a report is selected (via clicking on its row).
 * @param focusedReportId - An optional ID of the report to focus when the grid loads.
 */
export default function ReportsDataGrid({
  onReportSelected,
  focusedReportId,
  showUtilityButtons = false,
}: {
  onReportSelected: (evalId: string) => void;
  focusedReportId?: string;
  showUtilityButtons?: boolean;
}) {
  const { currentTeam } = useTeamsContext();
  const { data, isLoading } = useReports({ teamId: currentTeam?.id });
  const { providers } = useGetProviders();

  const handleCellClick = (params: any) => {
    if (params?.row?.evalId) {
      onReportSelected(params.row.evalId);
    }
  };

  const columns: GridColDef<ExtendedResultLightweightWithLabel>[] = useMemo(
    () => [
      {
        field: 'description',
        headerName: 'Name',
        type: 'string',
        flex: 2,
        valueGetter: (params: GridRenderCellParams<ExtendedResultLightweightWithLabel>) => {
          return params || 'Untitled Evaluation';
        },
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'targetId',
        headerName: 'Target',
        type: 'string',
        flex: 1,
        renderCell: (params: GridRenderCellParams<ExtendedResultLightweightWithLabel>) => {
          if (!params || !params.row) {
            return <Chip label="No target" size="small" />;
          }

          const targetName = params.row.providerId
            ? providers?.find((p) => p.id === params.row.providerId)?.name || 'No target'
            : params.row.targetId || 'No target';

          return (
            <Chip
              label={targetName}
              size="small"
              sx={{
                bgcolor: params.row.targetId ? 'primary.50' : 'grey.100',
                color: params.row.targetId ? 'primary.700' : 'text.secondary',
                fontWeight: 500,
              }}
            />
          );
        },
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        type: 'dateTime',
        flex: 1,
        valueFormatter: (value: ExtendedResultLightweightWithLabel['createdAt']) => {
          if (!value) {
            return '';
          }
          return new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          });
        },
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'evalId',
        headerName: 'Eval ID',
        type: 'string',
        flex: 1,
        renderCell: (params: GridRenderCellParams<ExtendedResultLightweightWithLabel>) => {
          if (!params || params.value === undefined) {
            return null;
          }

          return (
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'monospace',
                color: 'text.secondary',
                fontSize: '0.875rem',
              }}
            >
              {params.value}
            </Typography>
          );
        },
        cellClassName: 'dg-cursor-pointer',
      },
    ],
    [providers],
  );

  return (
    <DataGrid<ExtendedResultLightweightWithLabel>
      rows={data ?? []}
      columns={columns}
      isLoading={isLoading}
      idColumn="evalId"
      defaultPageSize={50}
      defaultSort={{ field: 'createdAt', sort: 'desc' }}
      noResults={() => (
        <Box
          sx={{
            textAlign: 'center',
            color: 'text.secondary',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ fontSize: '2rem', mb: 1 }}>🔍</Box>
          <Typography variant="h6" gutterBottom>
            No reports found
          </Typography>
          <Typography variant="body2">
            Run a red team evaluation first to see results here.
          </Typography>
        </Box>
      )}
      selectedRowId={focusedReportId}
      showUtilityButtons={showUtilityButtons}
      onCellClick={handleCellClick}
    />
  );
}
