import { z } from 'zod';

/**
 * Shared types and constants for remediation-related functionality
 */

/**
 * Remediation path types for suggestions
 */
export const RemediationPathSchema = z.enum([
  'prompt_engineering',
  'api_guardrails',
  'model_selection',
  'fine_tuning',
  'access_controls',
]);

/**
 * Array of all remediation path values (for use in schemas, etc.)
 */
export const REMEDIATION_PATHS = [
  'prompt_engineering',
  'api_guardrails',
  'model_selection',
  'fine_tuning',
  'access_controls',
] as const;

/**
 * Impact level types for suggestions
 */
export const ImpactLevelSchema = z.enum(['high', 'medium', 'low']);

/**
 * Array of all impact level values (for use in schemas, etc.)
 */
export const IMPACT_LEVELS = ['high', 'medium', 'low'] as const;

/**
 * Implementation complexity types for suggestions
 */
export const ImplementationComplexitySchema = z.enum(['simple', 'moderate', 'complex']);

/**
 * Array of all implementation complexity values (for use in schemas, etc.)
 */
export const IMPLEMENTATION_COMPLEXITIES = ['simple', 'moderate', 'complex'] as const;

/**
 * Timeframe types for suggestions
 */
export const TimeframeSchema = z.enum(['immediate', 'near_term', 'long_term']);

/**
 * Array of all timeframe values (for use in schemas, etc.)
 */
export const TIMEFRAMES = ['immediate', 'near_term', 'long_term'] as const;

/**
 * Structure for remediation suggestions
 */
export const SuggestionSchema = z.object({
  title: z.string(),
  remediation: z.string(),
  remediation_path: RemediationPathSchema,
  impact_level: ImpactLevelSchema,
  implementation_complexity: ImplementationComplexitySchema,
  timeframe: TimeframeSchema,
});

/**
 * Map remediation paths to friendly display names
 */
export const remediationPathLabels: Record<z.infer<typeof RemediationPathSchema>, string> = {
  prompt_engineering: 'Prompt Engineering',
  api_guardrails: 'API Guardrails',
  model_selection: 'Model Selection',
  fine_tuning: 'Fine-tuning',
  access_controls: 'Access Controls',
};

/**
 * Map impact levels to color names
 */
export const impactColors: Record<z.infer<typeof ImpactLevelSchema>, string> = {
  high: 'error',
  medium: 'warning',
  low: 'info',
};

/**
 * Map complexity levels to color names
 */
export const complexityColors: Record<z.infer<typeof ImplementationComplexitySchema>, string> = {
  simple: 'success',
  moderate: 'info',
  complex: 'warning',
};

export type RemediationPath = z.infer<typeof RemediationPathSchema>;
export type ImpactLevel = z.infer<typeof ImpactLevelSchema>;
export type ImplementationComplexity = z.infer<typeof ImplementationComplexitySchema>;
export type Timeframe = z.infer<typeof TimeframeSchema>;
export type SuggestionType = z.infer<typeof SuggestionSchema>;
