import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { useRole, useUpdateRole, useDeleteRole } from '@cloud-ui/hooks/useRoles';
import {
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { PermissionSet, permissionSetDisplayNames } from '@shared/dto/rbac';

export default function RoleDetails() {
  const { orgId, roleId } = useParams<{ orgId: string; roleId: string }>();
  const theme = useTheme();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { data: role, isLoading: isLoadingRole } = useRole(orgId || '', roleId || '');
  const { mutate: updateRole } = useUpdateRole(orgId || '', roleId || '');
  const { mutate: deleteRole } = useDeleteRole(orgId || '');

  const [roleName, setRoleName] = useState('');
  const [selectedPermissionSets, setSelectedPermissionSets] = useState<PermissionSet[]>([]);
  const [initialSelectedPermissionSets, setInitialSelectedPermissionSets] = useState<
    PermissionSet[]
  >([]);

  const rowColor = useMemo(() => {
    return theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100';
  }, [theme.palette.mode]);

  useEffect(() => {
    if (role) {
      setRoleName(role.name);
      setSelectedPermissionSets(role.permissionSets);
      setInitialSelectedPermissionSets(role.permissionSets);
    }
  }, [role]);

  // Determine if any changes have been made
  const hasChanges = () => {
    // Check if name has changed
    if (role && roleName !== role.name) {
      return true;
    }

    // Check if permission sets have changed
    if (
      selectedPermissionSets.length !== initialSelectedPermissionSets.length ||
      !selectedPermissionSets.every((set) => initialSelectedPermissionSets.includes(set)) ||
      !initialSelectedPermissionSets.every((set) => selectedPermissionSets.includes(set))
    ) {
      return true;
    }

    return false;
  };

  const handleSave = () => {
    updateRole(
      {
        name: roleName,
        permissionSets: selectedPermissionSets,
      },
      {
        onSuccess: () => {
          showToast('Role updated successfully', 'success');
        },
        onError: (error) => {
          showToast('Failed to update role', 'error');
          console.error('Error updating role:', error);
        },
      },
    );
  };

  const handleDeleteRole = () => {
    if (window.confirm('Are you sure you want to delete this role?')) {
      deleteRole(roleId || '', {
        onSuccess: () => {
          showToast('Role deleted successfully', 'success');
          navigate(`${ROUTES.organization.index}/${orgId}/roles`);
        },
      });
    }
  };

  const navigateToRoles = () => {
    navigate(`${ROUTES.organization.index}/${orgId}/roles`);
  };

  // Toggle permission set selection
  const handleTogglePermissionSet = (permissionSet: PermissionSet) => {
    setSelectedPermissionSets((prev) => {
      if (prev.includes(permissionSet)) {
        return prev.filter((set) => set !== permissionSet);
      }
      return [...prev, permissionSet];
    });
  };

  // Is this permission set selected?
  const isPermissionSetSelected = (permissionSet: PermissionSet) => {
    return selectedPermissionSets.includes(permissionSet);
  };

  if (isLoadingRole) {
    return <Typography>Loading...</Typography>;
  }

  if (!role) {
    return <Typography>Role not found</Typography>;
  }

  return (
    <Box maxWidth="100%" mx="auto" p={2}>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={navigateToRoles} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Role Details</Typography>

        <Box sx={{ ml: 'auto', display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            disabled={!roleName.trim() || !hasChanges()}
          >
            Save Changes
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteRole}
          >
            Delete Role
          </Button>
        </Box>
      </Box>

      <Card sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" mb={3}>
          Role Information
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 4 }}>
          <TextField
            label="Role Name"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            sx={{ minWidth: 300 }}
          />
        </Box>
      </Card>

      <Card>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Permissions</Typography>
        </Box>

        <TableContainer sx={{ m: 2, width: 'auto', maxWidth: '900px' }}>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: rowColor }}>
                <TableCell>Permission</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={3}
                  sx={{
                    backgroundColor: rowColor,
                    fontWeight: 'bold',
                  }}
                >
                  Administrator
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 4 }}>
                  {permissionSetDisplayNames[PermissionSet.ADMINISTRATOR]}
                </TableCell>
                <TableCell>
                  <Tooltip title="Full access to manage all aspects of the organization">
                    <span>Full access to everything in the team</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={
                      isPermissionSetSelected(PermissionSet.ADMINISTRATOR) ||
                      isPermissionSetSelected(PermissionSet.ADMINISTRATOR)
                    }
                    onChange={() => handleTogglePermissionSet(PermissionSet.ADMINISTRATOR)}
                  />
                </TableCell>
              </TableRow>
              {/* Redteam Configurations Group */}
              <TableRow>
                <TableCell colSpan={3} sx={{ backgroundColor: rowColor, fontWeight: 'bold' }}>
                  Red Teaming
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 4 }}>
                  {permissionSetDisplayNames[PermissionSet.VIEW_CONFIGURATIONS]}
                </TableCell>
                <TableCell>
                  <Tooltip title="Basic access to view configuration details">
                    <span>View configurations, targets, and plugin collections</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={
                      isPermissionSetSelected(PermissionSet.ADMINISTRATOR) ||
                      isPermissionSetSelected(PermissionSet.VIEW_CONFIGURATIONS) ||
                      isPermissionSetSelected(PermissionSet.MANAGE_CONFIGURATIONS) ||
                      isPermissionSetSelected(PermissionSet.RUN_SCANS)
                    }
                    onChange={() => handleTogglePermissionSet(PermissionSet.VIEW_CONFIGURATIONS)}
                    disabled={
                      isPermissionSetSelected(PermissionSet.MANAGE_CONFIGURATIONS) ||
                      isPermissionSetSelected(PermissionSet.RUN_SCANS) ||
                      isPermissionSetSelected(PermissionSet.ADMINISTRATOR)
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 4 }}>
                  {permissionSetDisplayNames[PermissionSet.RUN_SCANS]}
                </TableCell>
                <TableCell>
                  <Tooltip title="Access to run evaluations and view results">
                    <span>Run scans and view results</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={
                      isPermissionSetSelected(PermissionSet.ADMINISTRATOR) ||
                      isPermissionSetSelected(PermissionSet.RUN_SCANS)
                    }
                    onChange={() => handleTogglePermissionSet(PermissionSet.RUN_SCANS)}
                    disabled={isPermissionSetSelected(PermissionSet.ADMINISTRATOR)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 4 }}>
                  {permissionSetDisplayNames[PermissionSet.MANAGE_CONFIGURATIONS]}
                </TableCell>
                <TableCell>
                  <Tooltip title="Full access to manage configurations and plugin collections">
                    <span>Create, edit, and delete configurations and plugin collections</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={
                      isPermissionSetSelected(PermissionSet.ADMINISTRATOR) ||
                      isPermissionSetSelected(PermissionSet.MANAGE_CONFIGURATIONS)
                    }
                    onChange={() => handleTogglePermissionSet(PermissionSet.MANAGE_CONFIGURATIONS)}
                    disabled={isPermissionSetSelected(PermissionSet.ADMINISTRATOR)}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} sx={{ backgroundColor: rowColor, fontWeight: 'bold' }}>
                  Targets
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 4 }}>
                  {permissionSetDisplayNames[PermissionSet.MANAGE_PROVIDERS]}
                </TableCell>
                <TableCell>
                  <Tooltip title="Full access to manage targets">
                    <span>Create, edit, and delete targets</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={
                      isPermissionSetSelected(PermissionSet.ADMINISTRATOR) ||
                      isPermissionSetSelected(PermissionSet.MANAGE_PROVIDERS)
                    }
                    onChange={() => handleTogglePermissionSet(PermissionSet.MANAGE_PROVIDERS)}
                    disabled={isPermissionSetSelected(PermissionSet.ADMINISTRATOR)}
                  />
                </TableCell>
              </TableRow>

              {/* Results Group */}
              <TableRow>
                <TableCell colSpan={3} sx={{ backgroundColor: rowColor, fontWeight: 'bold' }}>
                  Results
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 4 }}>
                  {permissionSetDisplayNames[PermissionSet.VIEW_RESULTS]}
                </TableCell>
                <TableCell>
                  <Tooltip title="Basic access to view results">
                    <span>View issues and evaluations</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={
                      isPermissionSetSelected(PermissionSet.ADMINISTRATOR) ||
                      isPermissionSetSelected(PermissionSet.VIEW_RESULTS) ||
                      isPermissionSetSelected(PermissionSet.MANAGE_RESULTS) ||
                      isPermissionSetSelected(PermissionSet.RUN_SCANS)
                    }
                    onChange={() => handleTogglePermissionSet(PermissionSet.VIEW_RESULTS)}
                    disabled={
                      isPermissionSetSelected(PermissionSet.MANAGE_RESULTS) ||
                      isPermissionSetSelected(PermissionSet.RUN_SCANS) ||
                      isPermissionSetSelected(PermissionSet.ADMINISTRATOR)
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 4 }}>
                  {permissionSetDisplayNames[PermissionSet.MANAGE_RESULTS]}
                </TableCell>
                <TableCell>
                  <Tooltip title="Full access to manage evaluations and issues">
                    <span>Edit and delete evaluations and issues</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={
                      isPermissionSetSelected(PermissionSet.ADMINISTRATOR) ||
                      isPermissionSetSelected(PermissionSet.MANAGE_RESULTS)
                    }
                    onChange={() => handleTogglePermissionSet(PermissionSet.MANAGE_RESULTS)}
                    disabled={isPermissionSetSelected(PermissionSet.ADMINISTRATOR)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
}
