import { Paper, Typography } from '@mui/material';
import type { ProviderOptions } from '@promptfoo/types';

export default function DisplayTargetConfig({ config }: { config?: ProviderOptions }) {
  if (!config) {
    return null;
  }
  return (
    <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Target Configuration
      </Typography>

      <Paper
        variant="outlined"
        sx={{
          p: 2,
          mb: 3,
          backgroundColor: 'rgba(25, 118, 210, 0.08)',
          display: 'inline-block',
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Target Type:{'    '}
          <span>{config.id}</span>
        </Typography>
      </Paper>

      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Configuration Details:
      </Typography>
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.03)',
          maxHeight: 300,
          overflow: 'auto',
        }}
      >
        <pre style={{ margin: 0 }}>{JSON.stringify(config.config || {}, null, 2)}</pre>
      </Paper>
    </Paper>
  );
}
