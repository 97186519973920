import { useQuery } from '@tanstack/react-query';
import { getPluginCollection, listPluginCollections } from '@cloud-ui/utils/api/redteam';

export const useGetPluginCollections = (teamId: string) => {
  const { data: pluginCollections, isLoading } = useQuery({
    queryKey: ['pluginCollections', teamId],
    queryFn: () => listPluginCollections(teamId),
    enabled: !!teamId,
  });

  return { pluginCollections, isLoading };
};

export const useGetPluginCollectionById = (id?: string) => {
  const { data: pluginCollection, isLoading } = useQuery({
    queryKey: ['pluginCollections', id],
    queryFn: () => getPluginCollection(id!),
    enabled: !!id,
    staleTime: 0,
  });

  return {
    pluginCollection: id ? pluginCollection : null,
    isLoading: id ? isLoading : false,
  };
};
