import React, { useState } from 'react';
import { convertIssuesToCsv } from '@cloud-ui/utils/issues';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import type { IssueDTO } from '@shared/dto';
import type { ProviderDTO } from '@shared/dto/providers';

interface IssueDownloadButtonProps {
  issues: IssueDTO[];
  providers: ProviderDTO[];
}

const IssueDownloadButton: React.FC<IssueDownloadButtonProps> = ({ issues, providers }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleCsvDownload = () => {
    setIsDownloading(true);

    try {
      const csv = convertIssuesToCsv(issues, providers);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute(
          'download',
          `vulnerabilities_export_${new Date().toISOString().split('T')[0]}.csv`,
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error generating CSV:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Tooltip title="Export as CSV" placement="top" open={isHovering && !isDownloading}>
      <IconButton
        onClick={handleCsvDownload}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        sx={{ mt: '4px' }}
        aria-label="export vulnerabilities as CSV"
        disabled={isDownloading || issues.length === 0}
      >
        {isDownloading ? <CircularProgress size={20} /> : <DownloadIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default IssueDownloadButton;
