import React, { useRef, useState } from 'react';
import { useAuth } from '@cloud-ui/contexts/AuthContext';
import { useShareEval } from '@cloud-ui/hooks/useEvals';
import { useRoles } from '@cloud-ui/hooks/useRoles';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, FormHelperText, InputAdornment } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';

interface ShareModalProps {
  open: boolean;
  onClose: () => void;
  evalId: string;
}

const ShareModal: React.FC<ShareModalProps> = ({ open, onClose, evalId }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [copied, setCopied] = useState(false);
  const { organization } = useAuth();
  const [selectedRole, setSelectedRole] = useState('');
  const [shareUrl, setShareUrl] = useState('');
  const { data: roles = [] } = organization?.id ? useRoles(organization.id) : { data: [] };
  const { mutate: shareMutation } = useShareEval();

  React.useEffect(() => {
    if (open && evalId) {
      shareMutation(
        {
          evalId,
          roleId: selectedRole || undefined,
        },
        {
          onSuccess: (data) => {
            setShareUrl(data.link);
          },
        },
      );
    } else {
      setShareUrl('');
      setSelectedRole('');
    }
  }, [open, evalId, shareMutation, selectedRole]);

  const handleCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard.writeText(shareUrl);
      setCopied(true);
    }
  };

  const handleClose = () => {
    onClose();
    setCopied(false);
    setSelectedRole('');
    setShareUrl('');
  };

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    setSelectedRole(event.target.value);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Share Evaluation</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          {organization?.canUseRedteam && roles.length > 0 && (
            <FormControl fullWidth>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
                value={selectedRole}
                label="Role"
                onChange={handleRoleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Select a role to assign to users who register with this link
              </FormHelperText>
            </FormControl>
          )}
          <TextField
            fullWidth
            value={shareUrl}
            inputRef={inputRef}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCopyClick} edge="end">
                    {copied ? <CheckIcon color="success" /> : <ContentCopyIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareModal;
