import { useQuery } from '@tanstack/react-query';
import ErrorAlert from '@cloud-ui/components/ErrorAlert';
import LoadingSpinner from '@cloud-ui/components/LoadingSpinner';
import RecentEvals from '@cloud-ui/pages/redteam/dashboard/components/RecentEvals';
import { getEvals } from '@cloud-ui/utils/api/dashboard';

export default function History({ providerId }: { providerId: string }) {
  const {
    data: evals,
    isPending: isPendingEvals,
    error: evalsError,
  } = useQuery({
    queryKey: ['dashboard', 'evals', providerId],
    queryFn: () => getEvals(providerId),
    enabled: !!providerId,
  });

  if (isPendingEvals) {
    return <LoadingSpinner />;
  }

  if (evalsError) {
    return <ErrorAlert error={evalsError} />;
  }

  return <RecentEvals evals={evals} selectedTargetId={providerId} />;
}
