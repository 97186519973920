import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '@cloud-ui/contexts/AuthContext';
import { useConfig } from '@cloud-ui/contexts/ConfigContext';
import { useFusionAuth } from '@cloud-ui/contexts/FusionAuthContext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Container, Paper, Typography, useTheme } from '@mui/material';

/**
 * Logout component that shows a simple message after user logs out
 */
export default function Logout() {
  const theme = useTheme();
  const { config } = useConfig();
  const fusionAuth = useFusionAuth();
  const { logout } = useContext(AuthContext) || {};

  const isFusionAuthEnabled = config?.useFusionAuth || false;

  // Clear any session data if needed
  React.useEffect(() => {
    logout?.();
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <CheckCircleOutlineIcon
            sx={{
              fontSize: 64,
              color: theme.palette.success.main,
              mb: 2,
            }}
          />
          <Typography
            component="h1"
            variant="h4"
            sx={{
              mb: 2,
              fontWeight: 500,
              color: theme.palette.primary.main,
              textAlign: 'center',
            }}
          >
            Logged Out Successfully
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              mb: 4,
            }}
          >
            Thank you for using our application. You have been securely logged out.
          </Typography>
        </Box>

        {isFusionAuthEnabled ? (
          // Use FusionAuth login URL
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            href={fusionAuth?.buildLoginUrl()}
            sx={{ mb: 2 }}
          >
            Log In Again
          </Button>
        ) : (
          // Use regular login page
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to="/login"
            sx={{ mb: 2 }}
          >
            Log In Again
          </Button>
        )}
      </Paper>
    </Container>
  );
}
