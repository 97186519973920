import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProviderEditor, { defaultHttpTarget } from '@cloud-ui/components/providers/ProviderEditor';
import { ROUTES } from '@cloud-ui/constants';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import type { ProviderOptions } from '@promptfoo/types';
import type { ServerSettingDTO, ServerSettingsKeys } from '@shared/dto/serverSettings';
import { getSettings, updateSetting, deleteSetting } from '../../../utils/api/serverSettings';

export default function ProviderSettings({ settingKey }: { settingKey: ServerSettingsKeys }) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const {
    data: serverSettings,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['serverSettings'],
    queryFn: getSettings,
  });

  const [value, setValue] = useState<ServerSettingDTO['value']>(defaultHttpTarget());
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    if (serverSettings && settingKey) {
      const setting = serverSettings.find((s) => s.key === settingKey);
      if (setting?.value) {
        const newValue = { ...setting.value };
        setValue(newValue);
      } else {
        setValue(defaultHttpTarget());
      }

      setHasUnsavedChanges(false);
    }
  }, [serverSettings, settingKey]);

  const handleValueChange = (newValue: ProviderOptions) => {
    if (!newValue) {
      return;
    }
    const clonedValue = { ...newValue };
    setValue(clonedValue);
    setHasUnsavedChanges(true);
  };

  const updateMutation = useMutation({
    mutationFn: (params: { key: ServerSettingsKeys; changes: Partial<any> }) =>
      updateSetting(params.key, params.changes),
    onSuccess: () => {
      showToast('Settings updated successfully.', 'success');
      queryClient.invalidateQueries({ queryKey: ['serverSettings'] });
    },
    onError: () => {
      showToast('Failed to update settings.', 'error');
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (key: ServerSettingsKeys) => deleteSetting(key),
    onSuccess: () => {
      showToast('Setting deleted successfully.', 'success');
      queryClient.invalidateQueries({ queryKey: ['serverSettings'] });
      navigate(ROUTES.serverSettings.index);
    },
    onError: () => {
      showToast('Failed to delete setting.', 'error');
    },
  });

  const handleSave = () => {
    if (!settingKey || !value) {
      return;
    }
    updateMutation.mutate({
      key: settingKey,
      changes: value,
    });
    setHasUnsavedChanges(false);
  };

  const handleDelete = () => {
    if (!settingKey) {
      return;
    }
    deleteMutation.mutate(settingKey);
    setDeleteDialogOpen(false);
  };

  // Add window beforeunload event handler
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  if (settingKey === undefined) {
    navigate(ROUTES.serverSettings.index);
    return;
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">Failed to load the setting.</Typography>
      </Box>
    );
  }

  const renderEditor = () => {
    const providerEditorOpts = {
      redteamProvider: {
        description: (
          <>
            <Typography>
              This provider will power parts of attack, grading, and remediation generation.
            </Typography>
            <Typography paragraph>It has to meet the following criteria:</Typography>
            <Box component="ul" sx={{ pl: 2, mt: 1 }}>
              <Typography component="li" sx={{ mb: 1 }}>
                It must be OpenAI GPT model. We recommend using GPT-4o-2024-05-13.
              </Typography>
              <Typography component="li">All guardrails must be disabled.</Typography>
            </Box>
            <Alert severity="info" sx={{ my: 2 }}>
              This provider needs to handle both plain text prompts and structured OpenAI JSON
              format requests. The default transformer below will automatically convert plain text
              inputs to the proper OpenAI JSON format. If you're using an API that doesn't support
              this dual format approach, you'll need to implement a custom request transformer to
              ensure compatibility.
            </Alert>
          </>
        ),
      },
      unalignedProvider: {
        description: (
          <>
            This provider is used to generate harmful attacks. It must meet the following criteria:
            <Box component="ul" sx={{ pl: 2, mt: 1 }}>
              <Typography component="li" sx={{ mb: 1 }}>
                It must be a Meta LLama 3+ base model.
              </Typography>
              <Typography component="li">
                It must be a text provider (aka "completion" provider), <em>not</em> a chat
                completion provider.
              </Typography>
            </Box>
          </>
        ),
      },
    };
    return (
      <>
        <ProviderEditor
          provider={value}
          setProvider={handleValueChange}
          opts={{
            disableTitle: true,
            disableNameField: true,
            availableProviderIds: ['http', 'https'],
            specialProviderType: settingKey,
            disableModelSelection: true,
            ...providerEditorOpts[settingKey as keyof typeof providerEditorOpts],
          }}
          key={settingKey}
        />
      </>
    );
  };

  const getSettingTitle = (key: ServerSettingsKeys) => {
    // Convert camelCase to Title Case with spaces
    return key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
      {hasUnsavedChanges && (
        <Alert
          severity="warning"
          sx={{
            mb: 2,
            position: 'sticky',
            top: 0,
            zIndex: 1100,
          }}
        >
          <AlertTitle>Unsaved Changes</AlertTitle>
          You have unsaved changes. Please save them before leaving this page.
        </Alert>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4">{getSettingTitle(settingKey)}</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!hasUnsavedChanges}
          >
            Save
          </Button>
          {serverSettings?.find((s) => s.key === settingKey)?.value && (
            <Button color="error" variant="outlined" onClick={() => setDeleteDialogOpen(true)}>
              Delete
            </Button>
          )}
        </Box>
      </Box>

      {renderEditor()}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Configuration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this configuration? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
