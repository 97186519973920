import { useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, Box } from '@mui/material';
import ReportsDataGrid from './ReportsDataGrid';

export default function ReportIndex() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl" sx={{ py: 4, height: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Reports
      </Typography>
      <Box sx={{ height: '100%' }}>
        <Paper sx={{ height: '100%' }}>
          <ReportsDataGrid
            onReportSelected={(evalId) => navigate(`/redteam/report/${evalId}`)}
            showUtilityButtons
          />
        </Paper>
      </Box>
    </Container>
  );
}
