import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataGrid, { type GridColDef } from '@cloud-ui/components/DataGrid';
import { defaultHttpTarget } from '@cloud-ui/components/providers/ProviderEditor';
import { ROUTES } from '@cloud-ui/constants';
import { useConfig } from '@cloud-ui/contexts/ConfigContext';
import { useCan } from '@cloud-ui/contexts/RbacContext';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { useStartJob } from '@cloud-ui/hooks/useJobs';
import {
  listRedteamConfigs,
  createRedteamConfig,
  deleteRedteamConfig,
} from '@cloud-ui/utils/api/redteam';
import { canRunOnServer as canRunOnServerFn } from '@cloud-ui/utils/providers';
import {
  Add as AddIcon,
  ContentCopy,
  Delete,
  Edit,
  PlayArrow,
  UploadFile as UploadFileIcon,
  Search as ViewIcon,
  FilterList as FilterIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  IconButton,
  Typography,
  Dialog,
  Grid,
  Paper,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import type { RedteamStrategy } from '@promptfoo/types';
import { Actions, Subjects } from '@shared/dto/rbac';
import { type RedteamConfig, type RedteamConfigDTO } from '@shared/dto/redteamConfigs';
import { getUnifiedConfig } from '@shared/utils/configs';
import { getDatabaseIdFromProvider, isCloudProvider } from '@shared/utils/providers';
import { format } from 'date-fns';
import yaml from 'js-yaml';
import { useGetProviders } from '../hooks/providers';

function CopyConfigButton({ config }: { config: RedteamConfigDTO }) {
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const handleClick = async () => {
    await createRedteamConfig({
      ...config.config,
      description: config.config.description + ' (copy)',
    });
    await queryClient.invalidateQueries({ queryKey: ['redteamConfigs'] });
    showToast('Config copied successfully', 'success');
  };

  return (
    <Tooltip title="Duplicate configuration" placement="top">
      <IconButton onClick={handleClick}>
        <ContentCopy />
      </IconButton>
    </Tooltip>
  );
}

function RunConfigButton({ config }: { config: RedteamConfigDTO }) {
  const { providers } = useGetProviders();
  const { mutate: startJob } = useStartJob();
  const { currentTeam } = useTeamsContext();

  const handleClick = () => {
    startJob({
      config: config.config,
      configId: config.id,
      getUnifiedConfig,
      teamId: config.teamId ?? currentTeam!.id,
    });
  };

  // Can this config be run on the server or is it CLI only?
  const target = config.config.target;
  const provider = isCloudProvider(target)
    ? providers?.find((p) => p.id === getDatabaseIdFromProvider(target))
    : target;
  const canRunOnServer = canRunOnServerFn(provider?.config);

  return (
    <Tooltip
      title={
        canRunOnServer
          ? 'Run configuration'
          : 'This target does not support server-side execution. Please use the CLI.'
      }
      placement="top"
    >
      <span>
        <IconButton onClick={handleClick} disabled={!canRunOnServer}>
          <PlayArrow />
        </IconButton>
      </span>
    </Tooltip>
  );
}

function DeleteConfigButton({ config }: { config: RedteamConfigDTO }) {
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (configId: string) => deleteRedteamConfig(configId),
    onSuccess: async (_, configId) => {
      queryClient.setQueryData(['redteamConfigs'], (old: RedteamConfig[] | undefined) =>
        old?.filter((config) => config.id !== configId),
      );
      await queryClient.invalidateQueries({ queryKey: ['redteamConfigs'] });
      showToast('Config deleted successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to delete config:', error);
      showToast('Failed to delete config', 'error');
    },
  });

  const handleClick = async () => {
    if (window.confirm('Are you sure you want to delete this configuration?')) {
      mutation.mutate(config.id);
    }
  };

  return (
    <Tooltip title="Delete configuration" placement="top">
      <IconButton onClick={handleClick}>
        <Delete />
      </IconButton>
    </Tooltip>
  );
}

export default function RedteamConfigsListPage() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { enableServerSideJobs } = useConfig();
  const { providers } = useGetProviders();
  const { currentTeam } = useTeamsContext();
  const [selectedOwner, setSelectedOwner] = useState<string | undefined>(undefined);

  const canCreate = useCan(Actions.CREATE, Subjects.CONFIG);
  const canDelete = useCan(Actions.DELETE, Subjects.CONFIG);
  const canEdit = useCan(Actions.UPDATE, Subjects.CONFIG);
  const canStartJobs = useCan(Actions.CREATE, Subjects.JOB);

  // Loading all of the configs
  const { data: configs, isLoading } = useQuery({
    queryKey: ['redteamConfigs', currentTeam?.id, selectedOwner],
    queryFn: () => listRedteamConfigs(currentTeam!.id, selectedOwner),
    enabled: !!currentTeam,
  });

  // Extract unique owners from configs for the dropdown
  const owners = configs
    ? Object.values(
        configs.reduce(
          (acc, config) => {
            if (config.userId && config.user) {
              acc[config.userId] = { id: config.userId, name: config.user };
            }
            return acc;
          },
          {} as Record<string, { id: string; name: string }>,
        ),
      ).sort((a, b) => a.name.localeCompare(b.name))
    : [];

  const readFileAsText = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }

      const content = await readFileAsText(file);
      const yamlConfig = yaml.load(content) as any;
      const strategies = yamlConfig?.redteam?.strategies || [];
      let target = yamlConfig.targets?.[0] || yamlConfig.providers?.[0] || defaultHttpTarget();

      const hasAnyStatefulStrategies = strategies.some(
        (strat: RedteamStrategy) => typeof strat !== 'string' && strat?.config?.stateful,
      );

      if (hasAnyStatefulStrategies) {
        if (typeof target === 'string') {
          target = { id: target, config: { stateful: true } };
        } else {
          target.config = { ...target.config, stateful: true };
        }
      }

      // Map the YAML structure to our expected Config format
      const mappedConfig: RedteamConfig = {
        description: yamlConfig.description || 'My Red Team Configuration',
        prompts: yamlConfig.prompts || ['{{prompt}}'],
        target,
        plugins: yamlConfig.redteam?.plugins || ['default'],
        strategies,
        extensions: yamlConfig.extensions || [],
        purpose: yamlConfig.redteam?.purpose || '',
        entities: yamlConfig.redteam?.entities || [],
        applicationDefinition: {
          purpose: yamlConfig.redteam?.purpose || '',
          // We could potentially parse these from redteam.purpose if it follows a specific format.
          redteamUser: '',
          accessToData: '',
          forbiddenData: '',
          accessToActions: '',
          forbiddenActions: '',
          connectedSystems: '',
        },
      };

      const result = await createRedteamConfig({
        ...mappedConfig,
        name: yamlConfig.description || `Uploaded ${new Date().toLocaleDateString()}`,
        teamId: currentTeam!.id,
      });
      showToast('Imported succesfully', 'success');
      navigate(`${ROUTES.redteam.configs}/${result.id}`);
    } catch (e) {
      console.error(`Error uploading file ${e}`);
      showToast(`Error importing Yaml config: ${e}`, 'error');
    }
  };

  const [isNewConfigDialogOpen, setIsNewConfigDialogOpen] = useState(false);

  const NewConfigDialog = () => (
    <Dialog
      open={isNewConfigDialogOpen}
      onClose={() => setIsNewConfigDialogOpen(false)}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 2 },
      }}
    >
      <Box sx={{ p: 4 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 500 }}>
          Create New Scan
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={2}
              sx={{
                p: 4,
                height: '100%',
                minHeight: 240,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 2,
                },
              }}
              onClick={() => {
                setIsNewConfigDialogOpen(false);
                navigate(`${ROUTES.redteam.configs}/new`);
              }}
            >
              <Box
                sx={{
                  mb: 2.5,
                  p: 1.5,
                  borderRadius: '50%',
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                }}
              >
                <AddIcon sx={{ fontSize: 32 }} />
              </Box>
              <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
                New Scan
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center"></Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              component="label"
              htmlFor="yaml-file-upload"
              elevation={2}
              sx={{
                p: 4,
                height: '100%',
                minHeight: 240,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 4,
                },
              }}
            >
              <input
                accept=".yml,.yaml"
                style={{ display: 'none' }}
                id="yaml-file-upload"
                type="file"
                onChange={(e) => {
                  handleFileUpload(e);
                  setIsNewConfigDialogOpen(false);
                }}
              />
              <Box
                sx={{
                  mb: 2.5,
                  p: 1.5,
                  borderRadius: '50%',
                  backgroundColor: 'success.main',
                  color: 'success.contrastText',
                }}
              >
                <UploadFileIcon sx={{ fontSize: 32 }} />
              </Box>
              <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
                Upload YAML
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
                Upload an existing Promptfoo configuration file
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );

  const handleCellClick = (params: any) => {
    if (params.field !== 'actions') {
      navigate(`${ROUTES.redteam.configs}/${params.row.id}`);
    }
  };

  const columns: GridColDef<RedteamConfigDTO>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      valueGetter: (value, row) => row.config.description,
      cellClassName: 'dg-cursor-pointer',
    },
    {
      field: 'target',
      headerName: 'Target',
      flex: 1,
      renderCell: (params) => {
        const target = params.row.config.target;
        return (
          <Box>
            {isCloudProvider(target) ? (
              providers?.find((p) => p.id === getDatabaseIdFromProvider(target))?.name
            ) : (
              <>
                {target?.label}
                {target?.config?.url && (
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    display="block"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '30rem',
                    }}
                  >
                    {target.config.url}
                  </Typography>
                )}
              </>
            )}
          </Box>
        );
      },
      cellClassName: 'dg-cursor-pointer',
    },
    {
      field: 'user',
      headerName: 'Last Updated By',
      flex: 1,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
      cellClassName: 'dg-cursor-pointer',
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      flex: 1,
      valueFormatter: (value: RedteamConfigDTO['updatedAt']) =>
        value ? format(new Date(value), 'MMM d, yyyy HH:mm') : '-',
      cellClassName: 'dg-cursor-pointer',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        const config = params.row;
        return (
          <Box display="flex" gap={1}>
            <Tooltip title={canEdit ? 'Edit configuration' : 'View Configuration'} placement="top">
              <IconButton onClick={() => navigate(`${ROUTES.redteam.configs}/${config.id}`)}>
                {canEdit ? <Edit /> : <ViewIcon />}
              </IconButton>
            </Tooltip>
            {canCreate && <CopyConfigButton config={config} />}
            {enableServerSideJobs && canStartJobs && <RunConfigButton config={config} />}
            {canDelete && <DeleteConfigButton config={config} />}
          </Box>
        );
      },
    },
  ];

  return (
    <Box maxWidth="100%" mx="auto" p={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} mb={3}>
        <Typography variant="h4">Scan Configurations</Typography>

        <Stack direction="row" spacing={2} alignItems="center">
          <FormControl size="small" sx={{ minWidth: 220 }}>
            <InputLabel id="owner-filter-label">Owner</InputLabel>
            <Select
              labelId="owner-filter-label"
              id="owner-filter"
              value={selectedOwner || ''}
              label="Owner"
              onChange={(e) => setSelectedOwner(e.target.value === '' ? undefined : e.target.value)}
              sx={{
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
              startAdornment={
                <FilterIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />
              }
            >
              <MenuItem value="" sx={{ color: 'text.secondary' }}>
                All Owners
              </MenuItem>
              {owners.map((owner) => (
                <MenuItem key={owner.id} value={owner.id}>
                  {owner.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {canCreate && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setIsNewConfigDialogOpen(true)}
            >
              New Scan
            </Button>
          )}
        </Stack>
      </Stack>

      <NewConfigDialog />

      <Card>
        {!isLoading && (!configs || configs.length === 0) ? (
          <Box p={4} textAlign="center">
            <Typography variant="h6" color="text.secondary" gutterBottom>
              No Configurations Yet
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={3}>
              Create your first red team configuration to get started
            </Typography>
            {canCreate && (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setIsNewConfigDialogOpen(true)}
              >
                Create New Scan
              </Button>
            )}
          </Box>
        ) : (
          <DataGrid<RedteamConfigDTO>
            columns={columns}
            rows={configs ?? []}
            onCellClick={handleCellClick}
          />
        )}
      </Card>
    </Box>
  );
}
