import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import Link from '@mui/material/Link';
import type { ProviderOptions } from '@promptfoo/types';
import path from 'path-browserify';

interface CodeProviderConfigurationProps {
  selectedProvider: ProviderOptions;
  updateCustomProvider: (field: string, value: any) => void;
  type: 'python' | 'javascript';
}

export default function CodeProviderConfiguration({
  selectedProvider,
  updateCustomProvider,
  type,
}: CodeProviderConfigurationProps) {
  const handlePathChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.startsWith('file://')) {
      updateCustomProvider('id', value);
    } else {
      updateCustomProvider('id', value ? `file://${value}` : '');
    }
  };

  const validatePath = (pathToValidate: string): string | undefined => {
    if (!pathToValidate) {
      return 'Provider path is required';
    }
    if (!path.isAbsolute(pathToValidate)) {
      return 'Provider path must be absolute (start with /)';
    }
    if (!pathToValidate.endsWith('.js') && !pathToValidate.endsWith('.py')) {
      return 'Provider must be a .js or .py file';
    }
    return undefined;
  };

  const pathError = validatePath(selectedProvider.id?.replace('file://', '') || '');

  return (
    <Box mt={2}>
      <Typography variant="h6" gutterBottom>
        Custom Provider Configuration
      </Typography>
      <Box mt={2} p={2} border={1} borderColor="grey.300" borderRadius={1}>
        <TextField
          fullWidth
          label={'Provider File Path'}
          value={selectedProvider.id?.replace('file://', '') ?? ''}
          onChange={handlePathChange}
          placeholder={'e.g., /path/to/provider.js'}
          helperText={
            pathError ||
            `Enter the absolute path to your ${type === 'javascript' ? 'JavaScript' : 'Python'} provider file`
          }
          error={!!pathError}
          spellCheck={false}
        />
        {type === 'javascript' && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Learn how to set up a custom JavaScript provider{' '}
            <Link
              href="https://www.promptfoo.dev/docs/providers/custom-api/"
              target="_blank"
              rel="noopener"
            >
              here
            </Link>
            .
          </Typography>
        )}
        {type === 'python' && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Learn how to set up a custom Python provider{' '}
            <Link
              href="https://www.promptfoo.dev/docs/providers/python/"
              target="_blank"
              rel="noopener"
            >
              here
            </Link>
            .
          </Typography>
        )}
      </Box>
    </Box>
  );
}
