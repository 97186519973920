import type { EvalResultDTO, IssueDTO, PatchIssueDTO } from '@shared/dto';
import { callApi } from '../api';

export const getIssueEvalResults = async (issueId: string): Promise<EvalResultDTO[]> => {
  const response = await callApi(`/issues/${issueId}/evalResults`);
  return await response.json();
};

export const getIssue = async (issueId: string): Promise<IssueDTO> => {
  const response = await callApi(`/issues/${issueId}`);
  return await response.json();
};

export const updateIssue = async (id: string, changes: PatchIssueDTO) => {
  const response = await callApi(`/issues/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(changes),
  });
  return await response.json();
};

export interface FetchIssuesOptions {
  teamId?: string;
  targetId?: string;
  severity?: string;
  status?: string;
}

export async function fetchIssues(options?: FetchIssuesOptions): Promise<IssueDTO[]> {
  const params = new URLSearchParams();

  if (options?.teamId) {
    params.append('teamId', options.teamId);
  }

  if (options?.targetId) {
    params.append('targetId', options.targetId);
  }

  if (options?.severity) {
    params.append('severity', options.severity);
  }

  if (options?.status) {
    params.append('status', options.status);
  }

  const response = await callApi(`/issues${params.toString() ? `?${params.toString()}` : ''}`);
  return await response.json();
}

export const postIssueComment = async (issueId: string, comment: string) => {
  const response = await callApi(`/issues/${issueId}/comment`, {
    method: 'POST',
    body: JSON.stringify({ comment }),
  });
  return await response.json();
};

export const getIssueRemediation = async (issueId: string) => {
  const response = await callApi(`/issues/${issueId}/remediation`);
  return await response.json();
};
