import { MULTI_TURN_STRATEGIES, subCategoryDescriptions } from '@promptfoo/redteam/constants';
import type { RedteamPluginObject } from '@promptfoo/redteam/types';
import type { ProviderOptions, UnifiedConfig } from '@promptfoo/types';
import yaml from 'js-yaml';
import { ProviderDTO } from '../dto/providers';
import type { RedteamConfig } from '../dto/redteamConfigs';

const orderRedTeam = (redteam: any): any => {
  const orderedRedTeam: any = {};
  const redTeamOrder = ['purpose', 'entities', 'plugins', 'strategies'];

  redTeamOrder.forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(redteam, key)) {
      orderedRedTeam[key] = redteam[key];
    }
  });

  return orderedRedTeam;
};

const orderKeys = (obj: any): any => {
  const orderedObj: any = {};
  const keyOrder = ['description', 'targets', 'prompts', 'extensions', 'redteam'];

  keyOrder.forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (key === 'redteam') {
        orderedObj[key] = orderRedTeam(obj[key]);
      } else {
        orderedObj[key] = obj[key];
      }
    }
  });

  Object.keys(obj).forEach((key) => {
    if (!keyOrder.includes(key)) {
      orderedObj[key] = obj[key];
    }
  });

  return orderedObj;
};

export function getUnifiedConfig(
  config: RedteamConfig,
  provider?: ProviderDTO,
): UnifiedConfig & { redteam: NonNullable<UnifiedConfig['redteam']> } {
  // Making a copy of the config to avoid mutating the original
  const redteamConfig: RedteamConfig = { ...config };

  // Remove UI specific configs from target
  const target = { ...redteamConfig.target, config: { ...redteamConfig.target?.config } };
  target.config.sessionSource = undefined;
  target.config.stateful = undefined;

  // If the provider has extensions, we need to set the extensions on the config

  const ret = {
    description: config.description,
    metadata: { teamId: config.teamId },
    targets: [target],
    prompts: config.prompts,
    sharing: true,
    extensions: config.extensions,
    defaultTest:
      config.target?.config?.sessionSource === 'client'
        ? {
            options: {
              transformVars: '{ ...vars, sessionId: context.uuid }',
            },
          }
        : undefined,

    redteam: {
      purpose: config.purpose,
      numTests: config.numTests,
      plugins: config.plugins.map((plugin): RedteamPluginObject => {
        if (typeof plugin === 'string') {
          return { id: plugin };
        }
        return {
          id: plugin.id,
          ...(plugin.config && Object.keys(plugin.config).length > 0 && { config: plugin.config }),
        };
      }),
      strategies: config.strategies.map((strategy) => {
        if (typeof strategy === 'string') {
          return { id: strategy };
        }
        return {
          id: strategy.id,
          ...(strategy.config &&
            Object.keys(strategy.config).length > 0 && { config: strategy.config }),
        };
      }),
    },
  };

  // The old school redteam config had a stateful on the config object.
  // We now store it on the provider
  const stateful = provider ? provider.stateful : redteamConfig.target?.config?.stateful;
  ret.redteam.strategies?.forEach((strategy) => {
    if (
      MULTI_TURN_STRATEGIES.includes(typeof strategy === 'string' ? (strategy as any) : strategy.id)
    ) {
      if (typeof strategy === 'string') {
        strategy = { id: strategy };
      }
      strategy.config = { ...(strategy.config || {}), stateful };
    }
  });

  // The old school redteam config had a sessionSource on the config object.
  // We now store it on the provider
  if (
    provider?.sessionSource === 'client' ||
    redteamConfig.target?.config?.sessionSource === 'client'
  ) {
    ret.defaultTest = {
      options: {
        transformVars: '{ ...vars, sessionId: context.uuid }',
      },
    };
  }

  // The old school redteam config had extensions on the config object.
  // We now store them with the provider
  ret.extensions = provider ? provider.extensions : redteamConfig.extensions;

  return ret;
}

export function generateOrderedYaml(config: RedteamConfig, provider?: ProviderDTO): string {
  const yamlConfig = getUnifiedConfig(config, provider);
  if (config.purpose) {
    yamlConfig.redteam.purpose = config.purpose;
  }
  if (config.entities && config.entities.length > 0) {
    yamlConfig.redteam.entities = config.entities;
  }
  const orderedConfig = orderKeys(yamlConfig);

  const yamlString = yaml.dump(orderedConfig, { noRefs: true, lineWidth: -1 });

  // Add comments for plugins and strategies
  const lines = yamlString.split('\n');
  const updatedLines = lines.map((line) => {
    const match = line.match(/^\s*- id: (.+)$/);
    if (match) {
      const pluginId = match[1];
      const description = subCategoryDescriptions[pluginId as keyof typeof subCategoryDescriptions];
      if (description) {
        return `${line}  # ${description}`;
      }
    }
    return line;
  });

  return `# yaml-language-server: $schema=https://promptfoo.dev/config-schema.json\n${updatedLines.join('\n')}`;
}
