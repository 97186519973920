import { useMemo } from 'react';
import DataGrid, { type GridColDef } from '@cloud-ui/components/DataGrid';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import { useEvals } from '@cloud-ui/hooks/useEvals';
import { Typography, Box, Tooltip, Stack } from '@mui/material';
import {
  type GridFilterOperator,
  type GridRenderCellParams,
  getGridDefaultColumnTypes,
  type GridComparatorFn,
} from '@mui/x-data-grid';
import type { EvalWithAuthor } from '@shared/dto';

const defaultColumnTypes = getGridDefaultColumnTypes();
const singleSelectFilterOperators = defaultColumnTypes['singleSelect'].filterOperators;
const isAnyOfFilterOperator = singleSelectFilterOperators!.find((op) => op.value === 'isAnyOf');

/**
 * Displays a list of evals.
 *
 * @param onEvalSelected - Callback to handle when an eval is selected (via clicking on its id cell).
 * @param focusedEvalId - An optional ID of the eval to focus when the grid loads.
 */
export default function EvalsDataGrid({
  onEvalSelected,
  focusedEvalId,
  showUtilityButtons = false,
}: {
  onEvalSelected: (evalId: string) => void;
  focusedEvalId?: string;
  showUtilityButtons?: boolean;
}) {
  const { currentTeam } = useTeamsContext();
  const { data, isLoading } = useEvals({ teamId: currentTeam?.id });

  const hasRedteamEvals = data?.some((eval_) => eval_.isRedteam) ?? false;

  const handleCellClick = (params: any) => onEvalSelected(params.row.evalId);

  /**
   * Construct array of unique Redteam Plugins; used for filtering.
   */
  const pluginValues = useMemo(() => {
    return Array.from(new Set(data?.flatMap((eval_) => eval_.pluginIds).filter(Boolean) ?? []));
  }, [data]);

  /**
   * Construct array of unique Redteam Strategies; used for filtering.
   */
  const strategyValues = useMemo(() => {
    return Array.from(new Set(data?.flatMap((eval_) => eval_.strategies).filter(Boolean) ?? []));
  }, [data]);

  /**
   * Sort array values (Redteam Strategies and Plugins).
   */
  const arraySortingComparator: GridComparatorFn<string[]> = (v1, v2) => {
    const a = (v1 ?? []).sort().toString();
    const b = (v2 ?? []).sort().toString();
    return a.localeCompare(b);
  };

  /**
   * Construct array filter operators for Redteam Strategies and Plugins.
   */
  const arrayFilterOperators: GridFilterOperator<any, string[]>[] = [
    {
      label: 'includes',
      value: 'includes',
      getApplyFilterFn: (filterItem) => (value: string[]) => {
        if (filterItem.value.length === 0) {
          return true;
        }
        return value.some((v) => filterItem.value.includes(v));
      },
      InputComponent: isAnyOfFilterOperator!.InputComponent,
    },
    {
      label: 'excludes',
      value: 'excludes',
      getApplyFilterFn: (filterItem) => (value: string[]) => {
        if (filterItem.value.length === 0) {
          return true;
        }
        return !value.some((v) => filterItem.value.includes(v));
      },
      InputComponent: isAnyOfFilterOperator!.InputComponent,
    },
  ];

  /**
   * Construct columns for the evals data grid.
   */
  const columns: GridColDef<EvalWithAuthor>[] = useMemo(
    () =>
      [
        {
          field: 'evalId',
          headerName: 'ID',
          flex: 2,
          cellClassName: 'dg-cursor-pointer',
        },
        {
          field: 'createdAt',
          headerName: 'Created',
          flex: 1,
          type: 'date',
          valueFormatter: (value: EvalWithAuthor['createdAt']) => new Date(value).toLocaleString(),
          cellClassName: 'dg-cursor-pointer',
        },
        {
          field: 'author',
          headerName: 'Author',
          flex: 0.5,
          cellClassName: 'dg-cursor-pointer',
        },
        {
          field: 'description',
          headerName: 'Description',
          flex: 2,
          valueGetter: (value: EvalWithAuthor['description'], row: EvalWithAuthor) =>
            value ?? row.label,
          cellClassName: 'dg-cursor-pointer',
        },
        hasRedteamEvals
          ? {
              field: 'pluginIds',
              headerName: 'Plugins',
              flex: 1,
              sortComparator: arraySortingComparator,
              renderCell: (params: GridRenderCellParams<EvalWithAuthor>) => {
                const count = params.value.length;
                const label = (
                  <Typography variant="body2" color={count > 0 ? 'primary' : 'text.secondary'}>
                    {count} plugin
                    {count === 1 ? '' : 's'}
                  </Typography>
                );
                const pluginIds = (params?.value ?? []) as string[];

                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      cursor: 'default',
                    }}
                  >
                    {count > 0 ? (
                      <Tooltip
                        title={
                          <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
                            <Stack spacing={1} sx={{ p: 1 }}>
                              {pluginIds.map((pluginId) => (
                                <Typography key={pluginId} variant="body2">
                                  {pluginId}
                                </Typography>
                              ))}
                            </Stack>
                          </Box>
                        }
                      >
                        {label}
                      </Tooltip>
                    ) : (
                      label
                    )}
                  </div>
                );
              },
              cellClassName: 'dg-cursor-pointer',
              type: 'singleSelect',
              valueOptions: pluginValues,
              filterOperators: arrayFilterOperators,
            }
          : null,
        hasRedteamEvals
          ? {
              field: 'strategies',
              headerName: 'Strategies',
              flex: 1,
              sortComparator: arraySortingComparator,
              renderCell: (params: GridRenderCellParams<EvalWithAuthor>) => {
                const count = params.value.length;
                const label = (
                  <Typography variant="body2" color={count > 0 ? 'primary' : 'text.secondary'}>
                    {count} {count === 1 ? 'strategy' : 'strategies'}
                  </Typography>
                );
                const strategies = (params?.value ?? []) as string[];

                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      cursor: 'default',
                    }}
                  >
                    {count > 0 ? (
                      <Tooltip
                        title={
                          <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
                            <Stack spacing={1} sx={{ p: 1 }}>
                              {strategies.map((strategyId) => (
                                <Typography key={strategyId} variant="body2">
                                  {strategyId}
                                </Typography>
                              ))}
                            </Stack>
                          </Box>
                        }
                      >
                        {label}
                      </Tooltip>
                    ) : (
                      label
                    )}
                  </div>
                );
              },
              cellClassName: 'dg-cursor-pointer',
              type: 'singleSelect',
              valueOptions: strategyValues,
              filterOperators: arrayFilterOperators,
            }
          : null,
        {
          field: 'passRate',
          headerName: 'Pass Rate',
          flex: 0.5,
          type: 'number',
          renderCell: (params: GridRenderCellParams<EvalWithAuthor>) => (
            <>
              <Typography
                variant="body2"
                color="success.main"
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  // `type: number` gets overwritten by flex; manually justify content.
                  justifyContent: 'end',
                }}
              >
                {params.value.toFixed(2)}%
              </Typography>
            </>
          ),
          cellClassName: 'dg-cursor-pointer',
        },
        {
          field: 'numTests',
          headerName: '# Tests',
          flex: 0.5,
          type: 'number',
          cellClassName: 'dg-cursor-pointer',
        },
      ].filter(Boolean) as GridColDef<EvalWithAuthor>[],
    [hasRedteamEvals, pluginValues, strategyValues],
  );

  return (
    <DataGrid<EvalWithAuthor>
      rows={data ?? []}
      columns={columns}
      isLoading={isLoading}
      idColumn="evalId"
      defaultPageSize={50}
      defaultSort={{ field: 'createdAt', sort: 'desc' }}
      noResults={() => (
        <Box
          sx={{
            textAlign: 'center',
            color: 'text.secondary',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ fontSize: '2rem', mb: 1 }}>🔍</Box>
          <Typography variant="h6" gutterBottom>
            No evaluations found
          </Typography>
          <Typography variant="body2">
            Try adjusting your search or create a new evaluation
          </Typography>
        </Box>
      )}
      selectedRowId={focusedEvalId}
      showUtilityButtons={showUtilityButtons}
      onCellClick={handleCellClick}
    />
  );
}
