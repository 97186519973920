import type {
  PluginCollectionResponse,
  CreatePluginCollectionRequest,
  RedteamConfigDTO,
} from '@shared/dto/redteamConfigs';
import { callApi } from '../api';
import { ApiError } from '../api';

export const createRedteamConfig = async (
  config: Record<string, any>,
): Promise<RedteamConfigDTO> => {
  const response = await callApi(`/redteam/configs`, {
    method: 'POST',
    body: JSON.stringify(config),
  });
  return await response.json();
};

export const listRedteamConfigs = async (
  teamId: string,
  userId?: string,
): Promise<RedteamConfigDTO[]> => {
  let url = `/redteam/configs?teamId=${teamId}`;
  if (userId) {
    url += `&userId=${userId}`;
  }
  const response = await callApi(url);
  return await response.json();
};

export const getRedteamConfig = async (id: string): Promise<RedteamConfigDTO> => {
  const response = await callApi(`/redteam/configs/${id}`);
  return await response.json();
};

export const deleteRedteamConfig = async (id: string): Promise<void> => {
  await callApi(`/redteam/configs/${id}`, { method: 'DELETE' });
};

export const updateRedteamConfig = async (
  id: string,
  config: Record<string, any>,
): Promise<RedteamConfigDTO> => {
  const response = await callApi(`/redteam/configs/${id}`, {
    method: 'PUT',
    body: JSON.stringify(config),
  });
  return await response.json();
};

export type PluginCollection = PluginCollectionResponse;

export async function listPluginCollections(teamId: string): Promise<PluginCollectionResponse[]> {
  const response = await callApi(`/redteam/plugins?teamId=${teamId}`);
  return response.json();
}

export async function getPluginCollection(id: string): Promise<PluginCollectionResponse> {
  const response = await callApi(`/redteam/plugins/${id}`);
  return response.json();
}

export async function createPluginCollection(
  data: Omit<CreatePluginCollectionRequest, 'id'>,
): Promise<PluginCollectionResponse> {
  const response = await callApi('/redteam/plugins', {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function updatePluginCollection(
  id: string,
  data: Partial<CreatePluginCollectionRequest>,
): Promise<PluginCollectionResponse> {
  const response = await callApi(`/redteam/plugins/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response.json();
}

export interface PluginCollectionDeleteError {
  error: string;
  message: string;
  configNames: string[];
}

export async function deletePluginCollection(id: string): Promise<void> {
  try {
    await callApi(`/redteam/plugins/${id}`, { method: 'DELETE' });
  } catch (error) {
    if (error instanceof ApiError && error.status === 409) {
      throw error.json as PluginCollectionDeleteError;
    }
    throw error;
  }
}
