import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { fetchRoles, getRole, createRole, updateRole, deleteRole } from '@cloud-ui/utils/api/roles';
import type { CreateRoleRequest, UpdateRoleRequest } from '@shared/dto/rbac';

export const useRoles = (organizationId: string) => {
  return useQuery({
    queryKey: ['roles', organizationId],
    queryFn: () => fetchRoles(organizationId),
    enabled: !!organizationId,
    staleTime: 0,
  });
};

export const useRole = (organizationId: string, roleId: string) => {
  return useQuery({
    queryKey: ['roles', organizationId, roleId],
    queryFn: () => getRole(organizationId, roleId),
    enabled: !!organizationId && !!roleId,
    staleTime: 0,
  });
};

export const useCreateRole = (organizationId: string) => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: (data: CreateRoleRequest) => createRole(organizationId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['roles', organizationId] });
    },
    onError: (error) => {
      console.error('Failed to create role:', error);
      showToast('Failed to create role', 'error');
    },
  });
};

export const useUpdateRole = (organizationId: string, roleId: string) => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: (data: UpdateRoleRequest) => updateRole(organizationId, roleId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['roles', organizationId, roleId] });
      queryClient.invalidateQueries({ queryKey: ['roles', organizationId] });
      showToast('Role updated successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to update role:', error);
      showToast('Failed to update role', 'error');
    },
  });
};

export const useDeleteRole = (organizationId: string) => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: (roleId: string) => deleteRole(organizationId, roleId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['roles', organizationId] });
      showToast('Role deleted successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to delete role:', error);
      // @ts-expect-error
      if (error.status === 400) {
        showToast('Cannot delete a role that is assigned to users', 'error');
      } else {
        showToast('Failed to delete role', 'error');
      }
    },
  });
};
