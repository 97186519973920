import { callApi } from '../api';

export async function fetchIdentityProviders() {
  const response = await callApi('/auth/fusionauth/identity-providers');
  return response.json();
}

export async function exchangeCodeForTokens(params: {
  code: string;
  redirect_uri: string;
  client_id: string;
  grant_type: string;
}) {
  const response = await callApi('/auth/fusionauth/token-proxy', {
    method: 'POST',
    body: JSON.stringify(params),
  });

  return response.json();
}
