import { z } from 'zod';

/**
 * Zod schema for validating email addresses
 */
export const emailSchema = z.string().email('Please enter a valid email address');

/**
 * Function to validate an email address using the Zod schema
 * @param email - The email address to validate
 * @returns boolean indicating if the email is valid
 */
export const isValidEmail = (email: string): boolean => {
  const result = emailSchema.safeParse(email);
  return result.success;
};
