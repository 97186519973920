import { ProviderOptionsSchema } from '@promptfoo/validators/providers';
import { z } from 'zod';

export enum ServerSettingsKeys {
  redteamProvider = 'redteamProvider',
  unalignedProvider = 'unalignedProvider',
  fusionAuthConfig = 'fusionAuthConfig',
}

export const ServerSettingSchema = z.object({
  key: z.nativeEnum(ServerSettingsKeys),
  value: z.any(),
});

export const FusionAuthConfigSchema = z.object({
  apiKey: z.string(),
});

export const ServerSettingsSchemas: Record<ServerSettingsKeys, z.ZodType<any>> = {
  [ServerSettingsKeys.redteamProvider]: z.union([z.string(), ProviderOptionsSchema as any]),
  [ServerSettingsKeys.unalignedProvider]: z.union([z.string(), ProviderOptionsSchema as any]),

  [ServerSettingsKeys.fusionAuthConfig]: z.object({
    apiKey: z.string().optional(),
  }),
};

export type ServerSettingDTO = z.infer<typeof ServerSettingSchema>;
export type FusionAuthConfig = z.infer<typeof FusionAuthConfigSchema>;
