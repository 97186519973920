import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Button, Box, Popper, Paper, MenuList, MenuItem } from '@mui/material';
import type { TeamWithoutMembersDTO } from '@shared/dto/teams';
import { useTeamsContext } from '../contexts/TeamsContext';
import { useMenu } from '../hooks/useMenu';

export function TeamMenu() {
  const { anchorEl, setAnchorEl, handleMouseEnter, handleMouseLeave } = useMenu();
  const { currentTeam, teams, setCurrentTeam, isLoading } = useTeamsContext();

  if (isLoading || !teams || teams.length <= 1) {
    return null;
  }

  const handleTeamSwitch = (team: TeamWithoutMembersDTO) => {
    setCurrentTeam(team);
    setAnchorEl(null);
  };

  // Define a consistent width for both button and dropdown
  const menuWidth = '150px';

  return (
    <>
      <Button
        id="team-button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={(e) => handleMouseLeave(e, 'team-menu')}
        aria-controls={anchorEl ? 'team-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl)}
        sx={{
          color: 'text.primary',
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          minWidth: menuWidth,
          padding: '6px 12px',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '2px',
            flexShrink: 0,
            paddingRight: '4px',
          }}
        >
          <PeopleAltOutlinedIcon sx={{ fontSize: '16px' }} />
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '8rem',
            whiteSpace: 'nowrap',
            textAlign: 'left',
            flex: 1,
          }}
        >
          {currentTeam?.name || 'My Team'}
        </Box>
      </Button>
      <Popper
        id="team-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        placement="bottom-start"
        onMouseLeave={(e) => handleMouseLeave(e, 'team-button')}
        sx={{ zIndex: 999, width: menuWidth }}
      >
        <Paper sx={{ width: '100%' }}>
          <MenuList>
            {teams.map((team) => (
              <MenuItem
                key={team.id}
                onClick={() => handleTeamSwitch(team)}
                sx={{
                  fontSize: '0.875rem',
                  backgroundColor: team.id === currentTeam?.id ? 'action.selected' : 'inherit',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                  }}
                >
                  {team.name}
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Popper>
    </>
  );
}

export default TeamMenu;
