import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { ALL_PLUGINS, Plugin } from '@promptfoo/redteam/constants';
import validator from 'validator';
import { z } from 'zod';
import { IssueStatus, IssueStatusType, Severity, SeverityLevelType } from '../constants';

extendZodWithOpenApi(z);

export enum IssueEventType {
  CommentAdded = 'comment_added',
  Created = 'created',
  FailedTestsAdded = 'failed_tests_added',
  FailedTestsRemoved = 'failed_tests_removed',
  SeverityChanged = 'severity_changed',
  StatusChanged = 'status_changed',
  Updated = 'updated', // Generic update event for when there are multiple changes
}

export const IssueEventTypeDisplayNames: Record<IssueEventType, string> = {
  [IssueEventType.CommentAdded]: 'Comment Added',
  [IssueEventType.Created]: 'Created',
  [IssueEventType.FailedTestsAdded]: 'Failed Tests Added',
  [IssueEventType.FailedTestsRemoved]: 'Failed Tests Removed',
  [IssueEventType.SeverityChanged]: 'Severity Changed',
  [IssueEventType.StatusChanged]: 'Status Changed',
  [IssueEventType.Updated]: 'Updated',
};
export const IssueHistoryTextSchema = z
  .string()
  .min(1)
  .max(5000)
  .transform((val) => validator.escape(val.trim()));

export const IssueHistoryDTOSchema = z.object({
  createdAt: z.date(),
  name: z.string(),
  text: IssueHistoryTextSchema,
  data: z.any().nullable(),
  type: z.enum(Object.values(IssueEventType) as [IssueEventType, ...IssueEventType[]]),
});

export const IssueDTOSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
  pluginId: z.custom<Plugin>((val) => ALL_PLUGINS.includes(val as Plugin)),
  weakness: z.string(),
  status: z.enum(Object.values(IssueStatus) as [IssueStatusType, ...IssueStatusType[]]),
  organizationId: z.string(),
  severity: z.enum(Object.values(Severity) as [SeverityLevelType, ...SeverityLevelType[]]),
  targetId: z.string().nullable(),
  providerId: z.string().nullable(),
  teamId: z.string(),
  occurrences: z.number().int().min(0),
  history: z.array(IssueHistoryDTOSchema),
});

export const PatchIssueDTOSchema = IssueDTOSchema.pick({
  severity: true,
  status: true,
  teamId: true,
}).partial();

export type PatchIssueDTO = z.infer<typeof PatchIssueDTOSchema>;

export type IssueDTO = z.infer<typeof IssueDTOSchema>;
export type IssueHistoryDTO = z.infer<typeof IssueHistoryDTOSchema>;

export const RemediationDTOSchema = z.object({
  id: z.string().uuid(),
  remediation: z.string(),
  genericDescription: z.string(),
});

export type RemediationDTO = z.infer<typeof RemediationDTOSchema>;
