import type { OrganizationDTO, UpdateOrganizationRequest } from '@shared/dto/organizations';
import type { UserDTOWithTeams } from '@shared/dto/users';
import { callApi } from '../api';

export const fetchOrganizationDetails = async (
  organizationId: string,
): Promise<OrganizationDTO> => {
  const response = await callApi(`/organizations/${organizationId}`);
  return await response.json();
};

export const updateOrganization = async (
  organizationId: string,
  data: UpdateOrganizationRequest,
): Promise<OrganizationDTO> => {
  const response = await callApi(`/organizations/${organizationId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
  return await response.json();
};

export const fetchOrganizationUsers = async (
  organizationId: string,
): Promise<UserDTOWithTeams[]> => {
  const response = await callApi(`/organizations/${organizationId}/users`);
  return await response.json();
};

export const setUserAdminStatus = async (
  organizationId: string,
  userId: string,
  isAdmin: boolean,
): Promise<{ isAdmin: boolean }> => {
  const response = await callApi(`/organizations/${organizationId}/users/${userId}/admin`, {
    method: 'PUT',
    body: JSON.stringify({ isAdmin }),
  });
  return await response.json();
};
