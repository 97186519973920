import React, {
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from 'react';
import { useOpenRouterModels } from '@cloud-ui/hooks/useModels';
import { testProvider } from '@cloud-ui/utils/api/providers';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import type { ProviderOptions } from '@promptfoo/types';
import { cloneDeep } from 'lodash';
import BrowserAutomationConfiguration from './BrowserAutomationConfiguration';
import BuiltInProviderConfiguration from './BuiltInProviders';
import CodeProviderConfiguration from './CodeProviderConfiguration';
import CommonConfigurationOptions from './CommonConfigurationOptions';
import HttpEndpointConfiguration from './HttpEndpointConfiguration';
import TestTargetConfiguration from './TestTargetConfiguration';
import WebSocketEndpointConfiguration from './WebSocketEndpointConfiguration';

interface ProviderConfigEditorProps {
  provider: ProviderOptions | undefined;
  setProvider: (provider: ProviderOptions) => void;
  extensions?: string[];
  onExtensionsChange?: (extensions: string[]) => void;
  opts?: {
    specialProviderType?: string;
    defaultRequestTransform?: string;
    hideErrors?: boolean;
    disableModelSelection?: boolean;
  };
  setError?: (error: string | null) => void;
  validateAll?: boolean;
  onValidate?: (isValid: boolean) => void;
}

// Helper function to validate URLs
const validateUrl = (url: string, type: 'http' | 'websocket' = 'http'): boolean => {
  try {
    const parsedUrl = new URL(url);
    if (type === 'http') {
      return ['http:', 'https:'].includes(parsedUrl.protocol);
    } else if (type === 'websocket') {
      return ['ws:', 'wss:'].includes(parsedUrl.protocol);
    }
    return false;
  } catch {
    return false;
  }
};

export interface ProviderConfigEditorRef {
  validate: () => boolean;
}

const ProviderConfigEditor = forwardRef<ProviderConfigEditorRef, ProviderConfigEditorProps>(
  (
    {
      provider,
      setProvider,
      extensions,
      onExtensionsChange,
      opts = {},
      setError,
      validateAll = false,
      onValidate,
    },
    ref,
  ) => {
    const {
      specialProviderType,
      defaultRequestTransform,
      hideErrors = false,
      disableModelSelection = false,
    } = opts;
    const theme = useTheme();

    const [testingProvider, setTestingProvider] = useState(false);
    const [testResult, setTestResult] = useState<{
      success?: boolean;
      message: string;
      suggestions?: string[];
      providerResponse?: {
        raw: string;
        output: string;
        sessionId?: string;
        metadata?: {
          headers?: Record<string, string>;
        };
      };
      unalignedProviderResult?: {
        outputs: string[];
        statusCode?: number;
      };
      redteamProviderResult?: {
        raw: string;
        output: string;
        sessionId?: string;
        metadata?: {
          headers?: Record<string, string>;
        };
      };
    } | null>(null);

    const [bodyError, setBodyError] = useState<string | null>(null);
    const [urlError, setUrlError] = useState<string | null>(null);
    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [fieldInteractions, setFieldInteractions] = useState<Record<string, boolean>>({});
    const [shouldValidate, setShouldValidate] = useState<boolean>(false);

    // OpenRouter model selection state
    const [searchTerm, setSearchTerm] = useState('');

    // Fetch OpenRouter models
    const {
      data: openRouterData,
      isLoading: isLoadingProviders,
      error: openRouterError,
    } = useOpenRouterModels({
      enabled: provider?.id?.startsWith('openrouter') && !disableModelSelection,
    });

    // Transform the OpenRouter models data
    const MODEL_PROVIDERS = useMemo(() => {
      if (!openRouterData?.data) {
        return [];
      }

      return openRouterData.data
        .map((model) => ({
          value: model.id,
          label: model.name || model.id,
        }))
        .filter((provider) => !provider.value.includes('free'));
    }, [openRouterData]);

    // Set error message if model fetch failed
    const loadError = openRouterError
      ? 'Failed to load model providers. Please try again later.'
      : null;

    // Filter models based on search term
    const filteredModels = useMemo(() => {
      if (!searchTerm) {
        return MODEL_PROVIDERS;
      }

      const lowercaseSearch = searchTerm.toLowerCase();
      return MODEL_PROVIDERS.filter(
        (model) =>
          model.label.toLowerCase().includes(lowercaseSearch) ||
          model.value.toLowerCase().includes(lowercaseSearch),
      );
    }, [searchTerm, MODEL_PROVIDERS]);

    // Group models by provider
    const modelsByProvider = useMemo(() => {
      return filteredModels.reduce(
        (acc, model) => {
          // Extract provider name from the label (typically in format "Provider: Model")
          const providerName = model.label.split(':')[0] || 'Other';
          if (!acc[providerName]) {
            acc[providerName] = [];
          }
          acc[providerName].push(model);
          return acc;
        },
        {} as Record<string, typeof filteredModels>,
      );
    }, [filteredModels]);

    // Get provider names for select groups
    const providerNames = useMemo(() => Object.keys(modelsByProvider).sort(), [modelsByProvider]);

    // Function to trigger validation - define with useCallback to avoid recreating on every render
    const validate = useCallback((): boolean => {
      setShouldValidate(true);
      const isValid = missingFields.length === 0 && bodyError === null;
      onValidate?.(isValid);
      return isValid;
    }, [missingFields, bodyError, onValidate]);

    // Expose the validate method via ref
    useImperativeHandle(ref, () => ({
      validate,
    }));

    const [testingEnabled, setTestingEnabled] = useState(provider?.id?.startsWith('http') ?? false);
    const [forceStructuredHttp, setForceStructuredHttp] = useState(false);
    const [rawConfigJson, setRawConfigJson] = useState<string>(
      JSON.stringify(provider?.config || {}, null, 2),
    );

    const getProviderType = (provider: ProviderOptions | undefined) => {
      if (provider?.id?.endsWith('.py')) {
        return 'python';
      }
      if (provider?.id?.endsWith('.js')) {
        return 'javascript';
      }
      if (provider?.id?.startsWith('file://')) {
        return 'custom';
      }
      if (provider?.id?.startsWith('http')) {
        return 'http';
      }
      return 'custom';
    };

    const [selectedProviderType, setSelectedProviderType] = useState<string>(() => {
      return getProviderType(provider);
    });

    // Handle model selection
    const handleModelSelect = (modelValue: string) => {
      if (provider) {
        setProvider({
          ...provider,
          id: `openrouter:${modelValue}`,
        });
      }
    };

    useEffect(() => {
      setSelectedProviderType(getProviderType(provider));
    }, [provider]);

    useEffect(() => {
      setTestingEnabled(provider?.id?.startsWith('http') ?? false);
    }, [provider]);

    useEffect(() => {
      const missingFields: string[] = [];

      // If there's no provider or we're not validating yet, don't show errors
      // However, if validateAll is true, always validate regardless of field interactions
      if (
        !provider?.id ||
        (!shouldValidate && !validateAll && Object.keys(fieldInteractions).length === 0)
      ) {
        setMissingFields([]);
        setError?.(null);
        return;
      }

      if (!provider) {
        return;
      }

      // Add path validation for custom providers
      if (!provider.id && (fieldInteractions['providerId'] || shouldValidate || validateAll)) {
        missingFields.push('Provider Path');
      }

      if (provider.id?.startsWith('http')) {
        if (provider.config.request) {
          // Skip URL validation for raw request mode
          setMissingFields([]);
          setError?.(null);
          return;
        } else if (
          (!provider.config.url || !validateUrl(provider.config.url)) &&
          (fieldInteractions['url'] || shouldValidate || validateAll)
        ) {
          missingFields.push('URL');
        }
      }

      setMissingFields(missingFields);
      setError?.(
        missingFields.length > 0 ? `Missing required fields: ${missingFields.join(', ')}` : null,
      );
    }, [provider, fieldInteractions, shouldValidate, validateAll, setError]);

    const updateCustomProvider = (field: string, value: any) => {
      if (typeof provider === 'object') {
        // Mark this field as interacted with
        setFieldInteractions((prev) => ({ ...prev, [field]: true }));

        const updatedProvider = cloneDeep(provider);

        if (field === 'url') {
          updatedProvider.config.url = value;
          if (validateUrl(value)) {
            setUrlError(null);
          } else {
            setUrlError('Invalid URL format');
          }
        } else if (field === 'method') {
          updatedProvider.config.method = value;
        } else if (field === 'body') {
          updatedProvider.config.body = value;
          const bodyStr = typeof value === 'object' ? JSON.stringify(value) : String(value);
          if (bodyStr.includes('{{prompt}}')) {
            setBodyError(null);
          } else {
            setBodyError('Request body must contain {{prompt}}');
          }
        } else if (field === 'request') {
          if (value === undefined) {
            updatedProvider.config.request = undefined;
            setBodyError(null);
          } else {
            try {
              const requestStr = String(value).trim();
              if (requestStr.includes('{{prompt}}')) {
                updatedProvider.config.request = requestStr;
                delete updatedProvider.config.url;
                delete updatedProvider.config.method;
                delete updatedProvider.config.headers;
                delete updatedProvider.config.body;
                setBodyError(null);
              } else {
                setBodyError('Request must contain {{prompt}} template variable');
                return;
              }
            } catch (err) {
              const errorMessage = String(err)
                .replace(/^Error:\s*/, '')
                .replace(/\bat\b.*$/, '')
                .trim();
              setBodyError(`Invalid HTTP request format: ${errorMessage}`);
              return;
            }
          }
        } else if (field === 'label') {
          updatedProvider.label = value;
          setFieldInteractions((prev) => ({ ...prev, providerName: true }));
        } else if (field === 'delay') {
          updatedProvider.delay = value;
        } else if (field === 'id') {
          updatedProvider.id = value;
          setFieldInteractions((prev) => ({ ...prev, providerId: true }));
        } else if (field === 'config') {
          updatedProvider.config = value;
        } else {
          updatedProvider.config[field] = value;
        }

        setProvider(updatedProvider);
      }
    };

    const updateWebSocketProvider = (field: string, value: any) => {
      if (typeof provider === 'object') {
        // Mark this field as interacted with
        setFieldInteractions((prev) => ({ ...prev, [field]: true }));

        const updatedProvider = { ...provider } as ProviderOptions;
        if (field === 'id') {
          updatedProvider.id = value;
          setFieldInteractions((prev) => ({ ...prev, providerId: true }));
          if (validateUrl(value, 'websocket')) {
            setUrlError(null);
          } else {
            setUrlError('Please enter a valid WebSocket URL (ws:// or wss://)');
          }
        } else if (field in updatedProvider.config) {
          (updatedProvider.config as any)[field] = value;
        }
        setProvider(updatedProvider);
      }
    };

    const handleTestProvider = async () => {
      setTestingProvider(true);
      setTestResult(null);

      if (!provider) {
        return;
      }

      try {
        const response = await testProvider(provider, specialProviderType);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = (await response.json()) as {
          test_result: any;
          provider_response: any;
          unalignedProviderResult: any;
          redteamProviderResult: any;
          testHttpStatus: number | undefined;
        };
        const result = data?.test_result;

        if (result?.error) {
          setTestResult({
            success: false,
            message: result.error,
            providerResponse: data.provider_response,
            unalignedProviderResult: data.unalignedProviderResult,
            redteamProviderResult: data.redteamProviderResult,
          });
        } else if (result?.changes_needed) {
          setTestResult({
            success: false,
            message: result.changes_needed_reason,
            suggestions: result.changes_needed_suggestions,
            providerResponse: data.provider_response,
            unalignedProviderResult: data.unalignedProviderResult,
            redteamProviderResult: data.redteamProviderResult,
          });
        } else {
          setTestResult({
            message: 'Provider configuration is valid!',
            providerResponse: data.provider_response,
            unalignedProviderResult: data.unalignedProviderResult,
            redteamProviderResult: data.redteamProviderResult,
          });
        }
      } catch (error) {
        console.error('Error testing provider:', error);
        setTestResult({
          success: false,
          message: `An error occurred while testing the provider. ${error}`,
        });
      } finally {
        setTestingProvider(false);
      }
    };

    const isOpenRouterProvider =
      provider?.id?.startsWith('openrouter') || specialProviderType === 'openrouter';

    // For non-provider or OpenRouter provider with no other configuration
    if (!provider) {
      return null;
    }

    // Render the OpenRouter model selection UI when dealing with an OpenRouter provider
    if (isOpenRouterProvider && !disableModelSelection) {
      return (
        <>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium', mb: 3 }}>
            Select OpenRouter Model
          </Typography>
          <Box>
            {/* Selected model indicator that stays visible */}
            {provider?.id && (
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  mb: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderColor: 'primary.main',
                  borderWidth: 2,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" sx={{ mr: 1 }}>
                    Currently selected:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 'bold', color: 'primary.main' }}
                  >
                    {MODEL_PROVIDERS.find((m) => `openrouter:${m.value}` === provider.id)?.label ||
                      provider.id.replace('openrouter:', '')}
                  </Typography>
                </Box>
                <CheckCircleIcon color="primary" />
              </Paper>
            )}

            <TextField
              fullWidth
              placeholder={
                isLoadingProviders
                  ? 'Loading models...'
                  : `Search ${MODEL_PROVIDERS.length.toLocaleString()} models...`
              }
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
              disabled={isLoadingProviders}
            />

            {loadError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {loadError}
              </Alert>
            )}

            <Paper
              variant="outlined"
              sx={{
                maxHeight: 300,
                overflow: 'auto',
                p: 1,
              }}
            >
              {isLoadingProviders ? (
                <Typography sx={{ p: 2, textAlign: 'center', color: 'text.secondary' }}>
                  Loading model providers...
                </Typography>
              ) : providerNames.length > 0 ? (
                providerNames.map((providerName) => (
                  <Box key={providerName} sx={{ mb: 2 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 600,
                        py: 0.5,
                        px: 1,
                        bgcolor: theme.palette.action.hover,
                        borderRadius: 1,
                      }}
                    >
                      {providerName}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1, px: 1 }}>
                      {modelsByProvider[providerName].map((model) => (
                        <Chip
                          key={model.value}
                          label={model.label.replace(`${providerName}: `, '')}
                          onClick={() => handleModelSelect(model.value)}
                          variant={
                            provider.id === `openrouter:${model.value}` ? 'filled' : 'outlined'
                          }
                          color={
                            provider.id === `openrouter:${model.value}` ? 'primary' : 'default'
                          }
                          icon={
                            provider.id === `openrouter:${model.value}` ? (
                              <CheckCircleIcon fontSize="small" />
                            ) : undefined
                          }
                          sx={{
                            mb: 1,
                            fontWeight:
                              provider.id === `openrouter:${model.value}` ? 'bold' : 'normal',
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography sx={{ p: 2, textAlign: 'center', color: 'text.secondary' }}>
                  No models found matching your search
                </Typography>
              )}
            </Paper>
          </Box>
          <Box sx={{ mt: 8 }}>
            <Typography variant="h6" gutterBottom>
              Additional Configuration
            </Typography>
          </Box>
          <CommonConfigurationOptions
            selectedTarget={provider}
            updateCustomTarget={updateCustomProvider}
            extensions={extensions}
            onExtensionsChange={onExtensionsChange}
            onValidationChange={(hasErrors) => {
              setMissingFields((prev) =>
                hasErrors
                  ? [...prev.filter((f) => f !== 'Extensions'), 'Extensions']
                  : prev.filter((f) => f !== 'Extensions'),
              );
            }}
          />
          {!hideErrors && (missingFields.length > 0 || bodyError) && (
            <Box sx={{ mt: 2 }}>
              {missingFields.length > 0 && (
                <Alert
                  severity="error"
                  sx={{
                    mb: bodyError ? 2 : 0,
                    '& .MuiAlert-message': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                >
                  <Typography variant="body2">
                    Missing required fields: {missingFields.join(', ')}
                  </Typography>
                </Alert>
              )}
              {bodyError && (
                <Alert severity="error">
                  <Typography variant="body2">
                    There was an error with the request body: {bodyError}
                  </Typography>
                </Alert>
              )}
            </Box>
          )}
        </>
      );
    }

    return (
      <>
        <Box>
          {(selectedProviderType === 'python' || selectedProviderType === 'javascript') && (
            <CodeProviderConfiguration
              selectedProvider={provider}
              updateCustomProvider={updateCustomProvider}
              type={selectedProviderType}
            />
          )}
          {selectedProviderType === 'custom' && (
            <BuiltInProviderConfiguration
              selectedProvider={provider}
              updateCustomProvider={updateCustomProvider}
              rawConfigJson={rawConfigJson}
              setRawConfigJson={setRawConfigJson}
              bodyError={bodyError}
            />
          )}

          {(provider?.id?.startsWith('http') || selectedProviderType === 'http') && (
            <HttpEndpointConfiguration
              selectedTarget={provider ?? { id: 'http', config: { url: '' } }}
              updateCustomTarget={updateCustomProvider}
              bodyError={bodyError}
              setBodyError={setBodyError}
              urlError={urlError}
              setUrlError={setUrlError}
              forceStructured={forceStructuredHttp}
              setForceStructured={setForceStructuredHttp}
              defaultRequestTransform={defaultRequestTransform}
              setError={setError}
            />
          )}

          {(provider?.id?.startsWith('websocket') || selectedProviderType === 'websocket') && (
            <WebSocketEndpointConfiguration
              selectedTarget={provider}
              updateWebSocketTarget={updateWebSocketProvider}
              urlError={urlError}
            />
          )}

          {(provider?.id?.startsWith('browser') || selectedProviderType === 'browser') && (
            <BrowserAutomationConfiguration
              selectedTarget={provider}
              updateCustomTarget={updateCustomProvider}
            />
          )}
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Additional Configuration
          </Typography>
        </Box>
        <CommonConfigurationOptions
          selectedTarget={provider}
          updateCustomTarget={updateCustomProvider}
          extensions={extensions}
          onExtensionsChange={onExtensionsChange}
          onValidationChange={(hasErrors) => {
            setMissingFields((prev) =>
              hasErrors
                ? [...prev.filter((f) => f !== 'Extensions'), 'Extensions']
                : prev.filter((f) => f !== 'Extensions'),
            );
          }}
        />
        <Box sx={{ mt: 4 }}>
          {testingEnabled && provider && (
            <TestTargetConfiguration
              testingTarget={testingProvider}
              handleTestTarget={handleTestProvider}
              selectedTarget={provider}
              testResult={testResult}
            />
          )}
        </Box>

        {!hideErrors && (missingFields.length > 0 || bodyError) && (
          <Box sx={{ mt: 2 }}>
            {missingFields.length > 0 && (
              <Alert
                severity="error"
                sx={{
                  mb: bodyError ? 2 : 0,
                  '& .MuiAlert-message': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                <Typography variant="body2">
                  Missing required fields: {missingFields.join(', ')}
                </Typography>
              </Alert>
            )}
            {bodyError && (
              <Alert severity="error">
                <Typography variant="body2">
                  There was an error with the request body: {bodyError}
                </Typography>
              </Alert>
            )}
          </Box>
        )}
      </>
    );
  },
);

ProviderConfigEditor.displayName = 'ProviderConfigEditor';

export default ProviderConfigEditor;

// Export utilities for use in other components
export { validateUrl };
