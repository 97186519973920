import type { StandaloneEval } from '@promptfoo/util';
import { z } from 'zod';

export const HistorySchema = z.custom<StandaloneEval>();

export const HistoryQueryParamsSchema = z.object({
  description: z.string().optional(),
  limit: z.string().optional(),
});

export type HistoryQueryParams = z.infer<typeof HistoryQueryParamsSchema>;
export const HistoryResponseSchema = z.object({
  data: z.array(HistorySchema),
});

export type HistoryDTO = z.infer<typeof HistorySchema>;
export type HistoryResponse = z.infer<typeof HistoryResponseSchema>;
