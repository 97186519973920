import type { AppAbility } from '@shared/dto/rbac';
import { callApi } from '../api';

/**
 * Verify user login with email
 * @param email User's email address
 * @param fromOidc Whether this login is from an OIDC flow (optional, defaults to false)
 * @returns Promise with the token data
 */
export async function verifyUserLogin(email: string, fromOidc: boolean = false) {
  const response = await callApi('/users/login', {
    method: 'POST',
    body: JSON.stringify({
      email,
      fromOidc,
    }),
  });

  return response.json();
}

/**
 * Log in user with email and password
 * @param email User's email address
 * @param password User's password
 * @returns Promise with the token data
 */
export async function loginWithPassword(email: string, password: string) {
  const response = await callApi('/users/login/password', {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
  });

  return response.json();
}

/**
 * Request magic link login
 * @param email User's email address
 * @returns Promise with the response data
 */
export async function requestMagicLinkLogin(email: string) {
  const response = await callApi('/users/login', {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  });

  return response.json();
}

/**
 * Sign up a new user
 * @param email User's email address
 * @param password User's password
 * @returns Promise with the token data
 */
export async function signupUser(email: string, password: string) {
  const response = await callApi('/users/signup', {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
  });

  return response.json();
}

/**
 * Get current user information
 * @returns Promise with the user data
 */
export async function getCurrentUser() {
  const response = await callApi('/users/me');
  return response.json();
}

export const getUserTeamAbilities = async (teamId: string): Promise<AppAbility> => {
  const response = await callApi(`/users/me/abilities?teamId=${teamId}`);
  return await response.json();
};
