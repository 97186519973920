import { TableSortLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

// Create a styled TableSortLabel component with proper theming
export const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  // Target the root class
  '&.MuiButtonBase-root.MuiTableSortLabel-root': {
    color: theme.palette.text.primary,
  },

  // Target the active state with the exact classes from your DOM
  '&.MuiButtonBase-root.MuiTableSortLabel-root.Mui-active': {
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.primary.main,
  },

  // Target the icon with the exact classes from your DOM
  '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon': {
    color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
  },

  // Target the active icon with more specific selectors
  '&.Mui-active .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon': {
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.primary.main,
  },

  // Target hover state
  '&:hover': {
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.primary.main,
  },

  // Target hover icon state
  '&:hover .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon': {
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.primary.main,
  },

  // Target the direction indicator classes
  '&.MuiTableSortLabel-root.MuiTableSortLabel-directionDesc, &.MuiTableSortLabel-root.MuiTableSortLabel-directionAsc':
    {
      color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.primary.main,
    },
}));
