import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import Link from '@mui/material/Link';
import type { ProviderOptions } from '@promptfoo/types';
import CodeEditor from '../CodeEditor';

interface BuiltInProviderConfigurationProps {
  selectedProvider: ProviderOptions;
  updateCustomProvider: (field: string, value: any) => void;
  rawConfigJson: string;
  setRawConfigJson: (value: string) => void;
  bodyError: string | null;
}

export default function BuiltInProviderConfiguration({
  selectedProvider,
  updateCustomProvider,
  rawConfigJson,
  setRawConfigJson,
  bodyError,
}: BuiltInProviderConfigurationProps) {
  const handlePathChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    updateCustomProvider('id', value);
  };

  return (
    <Box mt={2}>
      <Typography variant="h6" gutterBottom>
        Provider Configuration
      </Typography>
      <Box mt={2} p={2} border={1} borderColor="grey.300" borderRadius={1}>
        <TextField
          fullWidth
          label={'Provider ID'}
          value={selectedProvider.id?.replace('file://', '') ?? ''}
          onChange={handlePathChange}
          placeholder={'openai:chat:gpt4'}
          helperText={'Enter the provider ID for the provider you want to use'}
          error={!!bodyError}
          spellCheck={false}
        />
        <Typography variant="body2" sx={{ mt: 1 }}>
          Click here for a list of available providers{' '}
          <Link
            href="https://www.promptfoo.dev/docs/providers/#available-providers"
            target="_blank"
            rel="noopener"
          >
            here
          </Link>
        </Typography>
        <Box mt={2} p={2} border={1} borderColor="grey.300" borderRadius={1}>
          {' '}
          <CodeEditor
            label="Provider Configuration (JSON)"
            language="json"
            value={rawConfigJson}
            onChange={(value) => {
              setRawConfigJson(value);
              try {
                const config = JSON.parse(value);
                updateCustomProvider('config', config);
              } catch (error) {
                console.error('Invalid JSON configuration:', error);
              }
            }}
            placeholder={`{
  "temperature": 0.5,
  "maxTokens": 100
}`}
            minHeight="200px"
          />
        </Box>

        {bodyError && (
          <Typography color="error" variant="caption" sx={{ mt: 1 }}>
            {bodyError}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
