import Cookies from 'js-cookie';

const AUTH_TOKEN_COOKIE = 'authToken';

export class ApiError extends Error {
  constructor(
    message: string,
    public status?: number,
    public text?: string,
    public json?: any,
  ) {
    super(message);
  }
}

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL ?? window.location.origin;

export function setAuthToken(token: string) {
  Cookies.set(AUTH_TOKEN_COOKIE, token, {
    expires: 365,
    domain: window.location.hostname.replace('www.', '.'),
  });
}

export function getAuthToken(): string | undefined {
  return Cookies.get(AUTH_TOKEN_COOKIE);
}

export async function callApi(path: string, options: RequestInit = {}): Promise<Response> {
  const authToken = getAuthToken();

  const headers = new Headers(options.headers);
  if (authToken) {
    headers.set('Authorization', `Bearer ${authToken}`);
  }

  headers.set('Content-Type', 'application/json');

  const url = `${API_BASE_URL}/api/v1${path}`;
  try {
    const response = await fetch(url, {
      ...options,
      headers,
    });

    const responseClone = response.clone();
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (!contentType || (!contentType.includes('application/json') && response.status !== 204)) {
        const textResponse = await response.text();
        console.warn(`Unexpected content type: ${contentType}. Response body:`, textResponse);
      }
    } else {
      const errorBody = await response.text();
      console.error(
        `HTTP error ${response.status}: ${response.statusText}\nResponse body:`,
        errorBody,
      );

      let jsonErrorBody;
      try {
        jsonErrorBody = JSON.parse(errorBody);
      } catch {}

      throw new ApiError(
        `Error calling ${path}: ${errorBody}`,
        response.status,
        errorBody,
        jsonErrorBody,
      );
    }

    return responseClone;
  } catch (error) {
    console.error('API call failed:', error);
    console.error('Request details:', {
      url,
      method: options.method || 'GET',
      headers: Object.fromEntries(headers.entries()),
      body: options.body,
    });
    throw error;
  }
}
