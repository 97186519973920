import { useQuery } from '@tanstack/react-query';
import { createContext, type ReactNode, useContext } from 'react';
import { callApi } from '@cloud-ui/utils/api';
import type { ConfigDTO } from '@shared/dto';

interface ConfigContextType {
  config: ConfigDTO | null;
  isLoading: boolean;
  error: Error | null;
}

const ConfigContext = createContext<ConfigContextType | null>(null);

async function fetchConfig(): Promise<ConfigDTO> {
  const response = await callApi('/config');
  if (!response.ok) {
    throw new Error('Failed to load config');
  }
  return response.json();
}

export function ConfigProvider({ children }: { children: ReactNode }) {
  const {
    data: config,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['config'],
    queryFn: fetchConfig,
    staleTime: Infinity, // Config won't change during session
    retry: 2,
  });

  return (
    <ConfigContext.Provider value={{ config: config || null, isLoading, error: error || null }}>
      {children}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }

  return {
    ...context,
    ...(context.config || {}),
  };
}
