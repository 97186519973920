import { Navigate, useParams } from 'react-router-dom';
import { NavLink, Outlet } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { Shield } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import PeopleIcon from '@mui/icons-material/People';
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const NAV_ITEMS = [
  { label: 'Organization', path: 'details', icon: <BusinessIcon /> },
  { label: 'Users', path: 'users', icon: <PeopleIcon /> },
  { label: 'Teams', path: 'teams', icon: <GroupWorkIcon /> },
  { label: 'Roles', path: 'roles', icon: <Shield /> },
];

const TopBar = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const NavListItem = styled(ListItemButton)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  width: '100%',
  textDecoration: 'none',
  color: 'inherit',
  '&:hover': {
    textDecoration: 'none',
  },
  '&.active': {
    '& .MuiListItemButton-root': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        textDecoration: 'none',
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiTypography-root': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

export default function OrganizationPage() {
  const { orgId } = useParams<{ orgId: string }>();

  if (!orgId) {
    return <Navigate to="/" />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <TopBar>
        <Typography variant="h6" fontWeight="bold">
          Organization Settings
        </Typography>
      </TopBar>

      <Box sx={{ display: 'flex', flex: 1 }}>
        <Paper
          elevation={0}
          sx={{
            width: 240,
            borderRight: 1,
            borderColor: 'divider',
            borderTop: 0,
            p: 2,
            height: '100%',
            borderRadius: 0,
          }}
        >
          <List sx={{ '& .MuiListItem-root': { p: 0.5 } }}>
            {NAV_ITEMS.map((item) => (
              <ListItem key={item.path} disablePadding>
                <StyledNavLink to={`${ROUTES.organization.index}/${orgId}/${item.path}`}>
                  <NavListItem>
                    <ListItemIcon sx={{ minWidth: 40, color: 'inherit' }}>{item.icon}</ListItemIcon>
                    <Typography>{item.label}</Typography>
                  </NavListItem>
                </StyledNavLink>
              </ListItem>
            ))}
          </List>
        </Paper>

        <Box sx={{ flex: 1, p: 3, backgroundColor: 'background.paper' }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
