import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataGrid, { type GridColDef } from '@cloud-ui/components/DataGrid';
import { ROUTES } from '@cloud-ui/constants';
import { useCan } from '@cloud-ui/contexts/RbacContext';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { deleteProvider, createProvider } from '@cloud-ui/utils/api/providers';
import { Add as AddIcon, ContentCopy, Delete, Edit, Search as ViewIcon } from '@mui/icons-material';
import { Box, Button, Card, IconButton, Tooltip, Typography } from '@mui/material';
import type { ProviderDTO } from '@shared/dto/providers';
import { Actions, Subjects } from '@shared/dto/rbac';
import { format } from 'date-fns';
import { useGetProviders } from '../hooks/providers';

function CopyProviderButton({ provider }: { provider: ProviderDTO }) {
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (provider: ProviderDTO) => {
      const copy = {
        name: provider.name + ' (Copy)',
        config: provider.config,
        sessionSource: provider.sessionSource,
        stateful: provider.stateful,
        extensions: provider.extensions ?? undefined,
        applicationDescription: provider.applicationDescription,
        teamId: provider.teamId,
      };

      return createProvider(copy);
    },
    onSuccess: async (result) => {
      await queryClient.invalidateQueries({ queryKey: ['providers'] });
      showToast('Provider copied successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to copy provider:', error);
      showToast('Failed to copy provider', 'error');
    },
  });

  const handleClick = () => {
    mutation.mutate(provider);
  };

  return (
    <IconButton onClick={handleClick} title="Copy">
      <ContentCopy />
    </IconButton>
  );
}

// TODO: A better UX would be to display the number of configs that the target is used by and disable the deletion
// button unless the target is not used by any configs.
function DeleteProviderButton({ provider }: { provider: ProviderDTO }) {
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deleteProvider,
    onSuccess: async (_, providerId) => {
      queryClient.setQueryData(['providers'], (old: ProviderDTO[] | undefined) =>
        old?.filter((provider) => provider.id !== providerId),
      );
      await queryClient.invalidateQueries({
        queryKey: ['providers'],
      });
      showToast('Provider deleted successfully', 'success');
    },
    onError: (error: any) => {
      console.error('Failed to delete provider:', error);
      if (error.configNames) {
        showToast(
          <>
            <span>Cannot delete </span>
            <span style={{ fontWeight: 500 }}>{provider.name}</span>
            <span>. The following configs are still using it: </span>
            <span style={{ fontWeight: 500 }}>{error.configNames.join(', ')}</span>
          </>,
          'error',
          5000,
        );
      } else {
        showToast('Failed to delete provider', 'error');
      }
    },
  });

  const handleClick = () => {
    if (window.confirm('Are you sure you want to delete this provider?')) {
      mutation.mutate(provider.id);
    }
  };

  return (
    <IconButton onClick={handleClick} title="Delete">
      <Delete />
    </IconButton>
  );
}

export default function ProvidersListPage() {
  const navigate = useNavigate();
  const { currentTeam } = useTeamsContext();
  const canCreate = useCan(Actions.CREATE, Subjects.PROVIDER);
  const canDelete = useCan(Actions.DELETE, Subjects.PROVIDER);
  const canUpdate = useCan(Actions.UPDATE, Subjects.PROVIDER);

  const { providers, isLoading } = useGetProviders(currentTeam?.id);

  /**
   * Navigate to the target page when clicking on any except the actions column.
   */
  const handleCellClick = (params: any) => {
    if (params.field !== 'actions') {
      navigate(`${ROUTES.redteam.targets}/${params.row.id}`);
    }
  };

  const columns: GridColDef<ProviderDTO>[] = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        valueFormatter: (value: ProviderDTO['name']) => value ?? 'Unnamed Provider',
        renderCell: (params) => {
          return <span style={{ textTransform: 'capitalize' }}>{params.value}</span>;
        },
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'config',
        headerName: 'Type',
        flex: 1,
        valueFormatter: (value: ProviderDTO['config']) => value.id ?? 'Unknown',
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'updatedBy',
        headerName: 'Last Updated By',
        flex: 1,
        valueFormatter: (value: ProviderDTO['updatedBy']) => value ?? 'Unknown',
        renderCell: (params) => {
          return <span>{params.value}</span>;
        },
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'updatedAt',
        headerName: 'Last Updated',
        flex: 1,
        valueFormatter: (value: ProviderDTO['updatedAt']) =>
          format(new Date(value), 'MMM d, yyyy HH:mm'),
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params) => {
          const provider = params.row;
          return (
            <Box display="flex" gap={1}>
              <IconButton
                onClick={() => navigate(`${ROUTES.redteam.targets}/${provider.id}`)}
                title={canUpdate ? 'Edit' : 'View'}
              >
                {canUpdate ? <Edit /> : <ViewIcon />}
              </IconButton>
              {canCreate && (
                <>
                  <Tooltip title="Create new config with this target" placement="top">
                    <IconButton
                      onClick={() =>
                        navigate(`${ROUTES.redteam.configs}/new?targetId=${provider.id}`)
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <CopyProviderButton provider={provider} />
                </>
              )}
              {canDelete && <DeleteProviderButton provider={provider} />}
            </Box>
          );
        },
        sortable: false,
        filterable: false,
      },
    ];
  }, []);

  return (
    <Box maxWidth="100%" mx="auto" p={3}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <Typography variant="h4">Targets</Typography>
        {canCreate && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate(`${ROUTES.redteam.targets}/new`)}
          >
            New Target
          </Button>
        )}
      </div>
      <Card>
        {!isLoading && (!providers || providers.length === 0) ? (
          <Box p={4} textAlign="center">
            <Typography variant="h6" color="text.secondary" gutterBottom>
              No Targets Yet
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={3}>
              Create your first target to get started
            </Typography>
            {canCreate && (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate(`${ROUTES.redteam.targets}/new`)}
              >
                Create New Target
              </Button>
            )}
          </Box>
        ) : (
          <DataGrid<ProviderDTO> columns={columns} rows={providers} onCellClick={handleCellClick} />
        )}
      </Card>
    </Box>
  );
}
