import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useCan } from '@cloud-ui/contexts/RbacContext';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import EmptyStateView from '@cloud-ui/pages/eval/components/EmptyStateView';
import { getEvals, getOpenIssuesPerDay, getTargets } from '@cloud-ui/utils/api/dashboard';
import { fetchIssues } from '@cloud-ui/utils/api/issues';
import { Subjects, Actions } from '@shared/dto/rbac';
import Dashboard from './components/Dashboard';
import { useDashboardStore } from './store';

export default function Page() {
  const { targetId } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { currentTeam } = useTeamsContext();
  const { selectedTargetId, setSelectedTargetId, reset } = useDashboardStore();
  const canSeeResults = useCan(Actions.READ, Subjects.EVAL);
  const canSeeIssues = useCan(Actions.READ, Subjects.ISSUE);
  const canSeeProviders = useCan(Actions.READ, Subjects.PROVIDER);

  const {
    data: targets,
    isPending: isPendingTargets,
    error: targetsError,
  } = useQuery({
    queryKey: ['targets', currentTeam?.id],
    queryFn: () => getTargets(currentTeam?.id),
    enabled: canSeeProviders,
  });

  if (targetsError) {
    console.error('Error getting targets', targetsError);
    showToast('Error getting targets', 'error');
  }

  const {
    data: evals,
    isPending: isPendingEvals,
    error: evalsError,
  } = useQuery({
    queryKey: ['dashboard', 'evals', selectedTargetId, currentTeam?.id],
    queryFn: () => getEvals(selectedTargetId, currentTeam?.id),
    enabled: !!selectedTargetId && canSeeResults,
  });

  const {
    data: issuesPerDay,
    isPending: isPendingIssuesPerDay,
    error: issuesPerDayError,
  } = useQuery({
    queryKey: ['issuesPerDay', selectedTargetId, currentTeam?.id],
    queryFn: () => getOpenIssuesPerDay(selectedTargetId, currentTeam?.id),
    enabled: !!selectedTargetId && canSeeIssues,
  });

  const {
    data: issues,
    isLoading: isPendingIssues,
    error: issuesError,
  } = useQuery({
    queryKey: ['issues', currentTeam?.id],
    queryFn: () => fetchIssues({ teamId: currentTeam?.id }),
    enabled: canSeeIssues,
  });

  useEffect(() => {
    if (evalsError) {
      console.error('Error getting evals', evalsError);
      showToast('Error getting evals', 'error');
    }
    if (issuesPerDayError) {
      console.error('Error getting issues per day', issuesPerDayError);
      showToast('Error getting issues per day', 'error');
    }
  }, [evalsError, issuesPerDayError, showToast]);

  useEffect(() => {
    const isValidTargetId = targetId && targets?.some((target) => target.id === targetId);

    if (!isPendingTargets && targetId) {
      if (isValidTargetId) {
        setSelectedTargetId(targetId);
      } else {
        console.error(`Invalid target ID: ${targetId}`);
        reset();
        navigate(ROUTES.redteam.dashboard);
      }
    } else if (targets && targets.length > 0) {
      const targetIdToSelect = selectedTargetId ?? targets[0].id;
      setSelectedTargetId(targetIdToSelect);
      navigate(`${ROUTES.redteam.dashboard}/${encodeURIComponent(targetIdToSelect)}`);
    }
  }, [targets, targetId, selectedTargetId, navigate, isPendingTargets, reset]);

  if (issuesError) {
    console.error('Error getting issues', issuesError);
    showToast('Error getting issues', 'error');
  }

  if (!isPendingTargets && (!targets || targets.length === 0)) {
    return <EmptyStateView type="redteam" targetRoute={ROUTES.redteam.targets} />;
  }

  return (
    <Dashboard
      issues={issues || []}
      issuesPerDay={issuesPerDay || []}
      evals={evals || []}
      targets={targets?.map((target) => ({ id: target.id, label: target.name })) || []}
      isLoading={isPendingTargets || isPendingIssuesPerDay || isPendingEvals || isPendingIssues}
    />
  );
}
