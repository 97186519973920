import { useContext, useState, useEffect, useRef } from 'react';
import { createContext } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useCurrentUserTeams } from '@cloud-ui/hooks/useTeams';
import type { TeamWithoutMembersDTO } from '@shared/dto/teams';
import { useAuth } from './AuthContext';

interface TeamContextType {
  teams: TeamWithoutMembersDTO[] | undefined;
  currentTeam: TeamWithoutMembersDTO | null;
  setCurrentTeam: (team: TeamWithoutMembersDTO) => void;
  isLoading: boolean;
}

export const TeamsContext = createContext<TeamContextType | undefined>(undefined);

export const TeamsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: teams, isLoading: teamsLoading } = useCurrentUserTeams();
  const [currentTeam, setCurrentTeam] = useState<TeamWithoutMembersDTO | null>(null);
  const [hasInitialized, setHasInitialized] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (teams?.length) {
      // First try to load from local storage
      const savedTeamId = localStorage.getItem(`${user?.id}-currentTeamId`);
      if (savedTeamId) {
        const savedTeam = teams.find((team) => team.id === savedTeamId);
        if (savedTeam) {
          setCurrentTeam(savedTeam);
          setHasInitialized(true);
          return;
        }
      }
      // If no team in local storage or saved team not found, select the first team
      setCurrentTeam(teams[0]);
      localStorage.setItem(`${user?.id}-currentTeamId`, teams[0].id);
      setHasInitialized(true);
    } else if (!teamsLoading && teams?.length === 0) {
      // If we have no teams and we're done loading, mark as initialized
      setHasInitialized(true);
    }
  }, [teams, teamsLoading]);

  // Custom setter that also updates local storage
  const handleSetCurrentTeam = (team: TeamWithoutMembersDTO) => {
    setCurrentTeam(team);
    localStorage.setItem(`${user?.id}-currentTeamId`, team.id);
  };

  return (
    <TeamsContext.Provider
      value={{
        teams,
        currentTeam,
        setCurrentTeam: handleSetCurrentTeam,
        isLoading: teamsLoading || !hasInitialized,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};

export const useTeamsContext = () => {
  const context = useContext(TeamsContext);
  if (context === undefined) {
    throw new Error('useTeamsContext must be used within an TeamsProvider');
  }
  return context;
};

export const useNavigateOnTeamChange = (isNew: boolean, navigateToPath: string) => {
  const { currentTeam } = useTeamsContext();
  const navigate = useNavigate();
  const initialTeamId = useRef(currentTeam?.id);

  useEffect(() => {
    // Only redirect if:
    // 1. We're not creating a new entity (isNew is false)
    // 2. We have a current team
    // 3. We have an initial team ID (not the first render)
    // 4. The current team ID is different from the initial team ID
    if (
      currentTeam &&
      !isNew &&
      initialTeamId.current !== undefined &&
      initialTeamId.current !== currentTeam.id
    ) {
      navigate(navigateToPath);
    }

    // Update the ref after the first render
    if (initialTeamId.current === undefined && currentTeam) {
      initialTeamId.current = currentTeam.id;
    }
  }, [currentTeam, isNew, navigate, navigateToPath]);
};

export const RequireSelectedTeam: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { currentTeam, isLoading } = useTeamsContext();
  const location = useLocation();

  const exemptRoutes = [
    ROUTES.login,
    ROUTES.verifyEmail,
    ROUTES.passwordReset.request,
    ROUTES.passwordReset.confirm,
    ROUTES.upgrade,
    ROUTES.noTeam,
    ...Object.values(ROUTES.organization).flat(),

    // These are exempt because they can be public, the redirect happens in the components
    ROUTES.eval,
    ROUTES.redteam.report,
  ];

  if (isLoading) {
    return <div>Loading...</div>; // Consider adding a proper loading indicator
  }

  // Check if current path requires a team selection
  const currentPath = location.pathname;

  const exempt =
    exemptRoutes.length === 0 || // If no routes specified, require team everywhere
    exemptRoutes.some((route) => currentPath === route || currentPath.startsWith(`${route}/`));

  if (!currentTeam && !exempt) {
    return <Navigate to={ROUTES.noTeam} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};
