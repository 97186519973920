export interface OpenRouterModel {
  id: string;
  name: string;
  description?: string;
  context_length?: number;
  pricing?: {
    prompt?: number;
    completion?: number;
  };
  architecture?: string;
  provider?: string;
}

export interface OpenRouterModelsResponse {
  data: OpenRouterModel[];
  error?: string;
}

/**
 * Fetches the list of available models from OpenRouter
 * @returns Promise with the list of models
 */
export const fetchOpenRouterModels = async (): Promise<OpenRouterModelsResponse> => {
  try {
    const response = await fetch('/openrouter/models.json');
    return await response.json();
  } catch (error) {
    console.error('Failed to fetch OpenRouter models:', error);
    return {
      data: [],
      error:
        error instanceof Error ? error.message : 'Unknown error occurred while fetching models',
    };
  }
};
