import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useEffect, useState, useRef } from 'react';
import { getAuthToken, API_BASE_URL } from '@cloud-ui/utils/api';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { SSEMessageSchema, type SSEMessage } from '@shared/dto';

interface UseJobLogsOptions {
  onMessage?: (message: SSEMessage) => void;
  maxLogs?: number; // Maximum number of logs to keep
  batchInterval?: number; // Interval in ms to batch log updates
}

const DEFAULT_MAX_LOGS = 1000;
const DEFAULT_BATCH_INTERVAL = 500;

// Hook for managing job logs via SSE using fetchEventSource
export function useJobLogs(jobId: string | null | undefined, options?: UseJobLogsOptions) {
  const queryClient = useQueryClient();
  const [error, setError] = useState<Error | null>(null);
  const batchedLogsRef = useRef<string[]>([]);
  const batchTimeoutRef = useRef<NodeJS.Timeout>();

  const maxLogs = options?.maxLogs ?? DEFAULT_MAX_LOGS;
  const batchInterval = options?.batchInterval ?? DEFAULT_BATCH_INTERVAL;

  // Add this query to access the logs
  const { data: logs = [] } = useQuery({
    queryKey: ['jobLogs', jobId],
    // Initialize with empty array
    initialData: [],
  });

  // Function to flush batched logs
  const flushBatchedLogs = () => {
    if (batchedLogsRef.current.length > 0) {
      queryClient.setQueryData(['jobLogs', jobId], (oldData: string[] | undefined) => {
        const newLogs = [...(oldData ?? []), ...batchedLogsRef.current];
        // Keep only the most recent logs up to maxLogs
        return newLogs.slice(-maxLogs);
      });
      batchedLogsRef.current = [];
    }
  };

  useEffect(() => {
    setError(null);
    if (!jobId) {
      return;
    }

    const token = getAuthToken();
    if (!token) {
      setError(new Error('No authentication token found'));
      return;
    }

    const controller = new AbortController();

    const connectToEventSource = () => {
      fetchEventSource(`${API_BASE_URL}/api/jobs/${jobId}/logs`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
        },
        signal: controller.signal,
        openWhenHidden: true,
        onopen: async (response) => {
          if (
            response.status !== 200 ||
            !response.headers.get('Content-Type')?.includes('text/event-stream')
          ) {
            throw new Error(`Unexpected response: ${response.status}`);
          }

          // Clear existing logs when connecting to a new job
          queryClient.setQueryData(['jobLogs', jobId], []);
        },
        onmessage: (event) => {
          if (event.data.startsWith(':')) {
            // Heartbeat message, can be ignored or used to keep connection alive
            return;
          }

          try {
            const data = SSEMessageSchema.parse(JSON.parse(event.data));
            setError(null);

            options?.onMessage?.(data);

            switch (data.type) {
              case 'progress': {
                // no-op.
                break;
              }
              case 'log': {
                // Batch log messages
                batchedLogsRef.current.push(data.message);

                // Clear existing timeout if it exists
                if (batchTimeoutRef.current) {
                  clearTimeout(batchTimeoutRef.current);
                }

                // Set new timeout to flush logs
                batchTimeoutRef.current = setTimeout(flushBatchedLogs, batchInterval);
                break;
              }
              case 'completion': {
                // no-op.
                break;
              }
            }
          } catch (err) {
            setError(new Error('Failed to parse log data ' + err));
          }
        },
        onerror: (err) => {
          console.error('SSE connection error:', err);
          setError(new Error('Connection to logs failed: ' + err));
          controller.abort();
        },
      });
    };

    connectToEventSource();

    return () => {
      controller.abort();
      // Clear any pending batch timeout
      if (batchTimeoutRef.current) {
        clearTimeout(batchTimeoutRef.current);
      }
      // Flush any remaining logs
      flushBatchedLogs();
    };
  }, [jobId, queryClient, maxLogs, batchInterval]);

  // Return logs along with error
  return { logs, error };
}
