import type { TestCasesWithMetadata } from '@promptfoo/types';
import { z } from 'zod';

export const DatasetSchema = z.object({
  id: z.string(),
  testCases: z.union([z.string(), z.array(z.string())]),
  recentEvalDate: z.string(),
});

export const DatasetsResponseSchema = z.object({
  data: z.array(DatasetSchema),
});

export type DatasetDTO = z.infer<typeof DatasetSchema>;
export type DatasetsResponse = z.infer<typeof DatasetsResponseSchema>;
