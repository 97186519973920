import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from '@cloud-ui/contexts/AuthContext';
import { useFusionAuth } from '@cloud-ui/contexts/FusionAuthContext';
import { exchangeCodeForTokens } from '@cloud-ui/utils/api/fusionauth';
import { Alert, Box, CircularProgress, Container, Typography } from '@mui/material';

export default function OAuthCallback() {
  const navigate = useNavigate();
  const location = useLocation();

  const fusionAuth = useFusionAuth();

  const { login } = useAuth();

  const [error, setError] = useState<string | null>(null);

  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  const exchangeCodeForToken = async (code: string) => {
    try {
      const { applicationId: clientId, redirectUri } = fusionAuth?.fusionAuthConfig || {};

      if (!clientId || !redirectUri) {
        console.error('FusionAuth configuration is incomplete:', { clientId, redirectUri });
        throw new Error('FusionAuth configuration is incomplete');
      }

      return await exchangeCodeForTokens({
        code,
        redirect_uri: redirectUri,
        client_id: clientId,
        grant_type: 'authorization_code',
      });
    } catch (error) {
      console.error('Error exchanging code for tokens:', error);
      throw error;
    }
  };

  const handleAuth = useCallback(async () => {
    if (!code) {
      setError('No authorization code provided');
      return;
    }

    try {
      // Step 1: Exchange the code for tokens
      const token = await exchangeCodeForToken(code);
      await login(token);
      navigate('/');
    } catch (error) {
      console.error('Authentication error:', error);

      if (error instanceof Error) {
        setError(
          error.message.includes('verification')
            ? 'Account verification failed. Please try again or contact support.'
            : error.message,
        );
      } else {
        setError('Authentication failed');
      }
    }
  }, [code, fusionAuth?.fusionAuthConfig]);

  useEffect(() => {
    handleAuth();
  }, [handleAuth]);

  if (error) {
    return (
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Authentication Error
            </Typography>
            <Typography variant="body1">{error}</Typography>
          </Alert>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">
              <Link to="/auth/login">Return to login page</Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Processing authentication...
        </Typography>
      </Box>
    </Container>
  );
}
