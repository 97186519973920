import { useQuery } from '@tanstack/react-query';
import { callApi } from '@cloud-ui/utils/api';
import type { ResultLightweightWithLabel } from '@promptfoo/types';

export interface ExtendedResultLightweightWithLabel extends ResultLightweightWithLabel {
  targetId?: string | null;
  providerId?: string | null;
}

export const useReports = ({ teamId }: { teamId?: string }) => {
  return useQuery<ExtendedResultLightweightWithLabel[]>({
    queryKey: ['reports', teamId],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (teamId) {
        params.append('teamId', teamId);
      }

      const resp = await callApi(
        `/dashboard/evals${params.toString() ? `?${params.toString()}` : ''}`,
        { cache: 'no-store' },
      );
      if (!resp.ok) {
        throw new Error('Failed to fetch recent evals');
      }
      const body = (await resp.json()) as ExtendedResultLightweightWithLabel[];
      // Only show redteam evals
      return body.filter((eval_) => eval_.isRedteam);
    },
  });
};
