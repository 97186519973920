import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useConfig } from '@cloud-ui/contexts/ConfigContext';
import { useFusionAuth } from '@cloud-ui/contexts/FusionAuthContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import LockResetIcon from '@mui/icons-material/LockReset';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import { AuthContext } from '../contexts/AuthContext';

export default function LoggedInAs() {
  const navigate = useNavigate();
  const fusionAuth = useFusionAuth();
  const { user, logout, organization, isAdmin } = useContext(AuthContext) || {};
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { config } = useConfig();
  const theme = useTheme();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();

    if (fusionAuth && fusionAuth.fusionAuthConfig.useFusionAuth) {
      window.location.href = fusionAuth.buildLogoutUrl();
    } else {
      logout?.();
      navigate(ROUTES.login);
    }
  };

  const handleCliLoginInfo = () => {
    navigate(ROUTES.welcome);
    handleClose();
  };

  const handlePasswordReset = () => {
    navigate(ROUTES.loggedInPasswordReset);
    handleClose();
  };

  if (!user) {
    return (
      <div>
        <IconButton
          aria-label="User not logged in"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar sx={{ width: '1em', height: '1em' }} />
        </IconButton>
      </div>
    );
  }

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Avatar
          sx={{
            width: 32,
            height: 32,
            bgcolor: alpha(theme.palette.primary.main, 0.8),
            fontSize: '0.9rem',
          }}
        >
          {user.name ? user.name[0].toUpperCase() : ''}
        </Avatar>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            minWidth: 220,
            bgcolor: theme.palette.background.paper,
          },
        }}
      >
        <Box sx={{ p: 2, pb: 3 }}>
          <Box
            sx={{
              bgcolor: theme.palette.background.default,
              boxShadow: '0px 2px 10px rgba(0,0,0,0.08)',
              borderRadius: 1,
              p: 1.5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <AccountCircleIcon sx={{ fontSize: 40, color: theme.palette.primary.main, mr: 1.5 }} />
            <Box>
              <Typography variant="subtitle2" fontWeight="bold" color={theme.palette.text.primary}>
                Logged in as
              </Typography>
              <Typography variant="body2" color={theme.palette.text.primary}>
                {user.name || user.email}
              </Typography>
              <Typography variant="caption" color={theme.palette.text.secondary}>
                {user.email}
              </Typography>
            </Box>
          </Box>
        </Box>
        {isAdmin && (
          <MenuItem
            component={Link}
            to={`/organizations/${organization?.id}`}
            sx={{
              color: theme.palette.text.primary,
              '&:hover': {
                bgcolor: alpha(theme.palette.primary.main, 0.1),
              },
            }}
          >
            <ListItemIcon>
              <SettingsIcon fontSize="small" color="action" />
            </ListItemIcon>
            <ListItemText primary="Settings" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {config?.enableServerSettingsPage && isAdmin && (
          <MenuItem
            component={Link}
            to={ROUTES.serverSettings.index}
            sx={{
              color: theme.palette.text.primary,
              '&:hover': {
                bgcolor: alpha(theme.palette.primary.main, 0.1),
              },
            }}
          >
            <ListItemIcon>
              <SmartToyIcon fontSize="small" color="action" />
            </ListItemIcon>
            <ListItemText
              primary="Red Team Providers"
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          onClick={handleCliLoginInfo}
          sx={{
            color: theme.palette.text.primary,
            '&:hover': {
              bgcolor: alpha(theme.palette.primary.main, 0.1),
            },
          }}
        >
          <ListItemIcon>
            <KeyIcon fontSize="small" color="action" />
          </ListItemIcon>
          <ListItemText
            primary="CLI Login Information"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        {config?.useFusionAuth && config?.isPromptfooCloud && (
          <MenuItem
            onClick={handlePasswordReset}
            sx={{
              color: theme.palette.text.primary,
              '&:hover': {
                bgcolor: alpha(theme.palette.primary.main, 0.1),
              },
            }}
          >
            <ListItemIcon>
              <LockResetIcon fontSize="small" color="action" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>Reset Password</Box>
              }
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        <MenuItem
          onClick={handleLogout}
          sx={{
            color: theme.palette.text.primary,
            '&:hover': {
              bgcolor: alpha(theme.palette.primary.main, 0.1),
            },
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" color="action" />
          </ListItemIcon>
          <ListItemText primary="Logout" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </div>
  );
}
