import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import PageShell from '@cloud-ui/components/PageShell';
import { ROUTES } from '@cloud-ui/constants';
import {
  AuthProvider,
  RequireAuth,
  RequireOrgAdmin,
  RequireRedteamAccess,
  useAuth,
} from '@cloud-ui/contexts/AuthContext';
import { ConfigProvider, useConfig } from '@cloud-ui/contexts/ConfigContext';
import { ConfiguredFusionAuth } from '@cloud-ui/contexts/FusionAuthContext';
import { RequireSelectedTeam, TeamsProvider } from '@cloud-ui/contexts/TeamsContext';
import { ToastProvider } from '@cloud-ui/contexts/ToastContext';
import NotFoundPage from '@cloud-ui/pages/NotFound';
import LoginPage from '@cloud-ui/pages/auth/login/Login';
import LogoutPage from '@cloud-ui/pages/auth/logout/Logout';
import ResultsPage from '@cloud-ui/pages/eval/page';
import RedteamConfigsListPage from '@cloud-ui/pages/redteam/configs/list';
import RedteamDashboardPage from '@cloud-ui/pages/redteam/dashboard/page';
import RedteamReportPage from '@cloud-ui/pages/redteam/report/page';
import WelcomePage from '@cloud-ui/pages/welcome/page';
import DocumentTitle from './components/DocumentTitle';
import { RbacProvider } from './contexts/RbacContext';
import NoTeamPage from './pages/NoTeam';
import OAuthCallback from './pages/auth/OauthCallback';
import LoggedInResetPasswordPage from './pages/auth/logged-in-reset-password/page';
import Register from './pages/auth/register';
import EvalsIndexPage from './pages/evals/page';
import GuardrailsDashboard from './pages/guardrails/page';
import Jobs from './pages/jobs/jobs';
import OrganizationPage from './pages/organization';
import OrganizationDetails from './pages/organization/organization';
import RolesList from './pages/organization/roles';
import RoleDetails from './pages/organization/roles/[id]';
import TeamDetails from './pages/organization/teams/[id]';
import OrganizationTeams from './pages/organization/teams/list';
import OrganizationUsers from './pages/organization/users';
import RedteamConfigsEditPage from './pages/redteam/configs/edit/edit';
import IssuesPage from './pages/redteam/issues/page';
import EditPluginSetPage from './pages/redteam/plugins/edit';
import PluginSetsListPage from './pages/redteam/plugins/list';
import EditTargetPage from './pages/redteam/targets/edit';
import TargetListPage from './pages/redteam/targets/list';
import ServerSettingsEditPage from './pages/serverSettings/edit';
import ServerSettingsPage from './pages/serverSettings/page';
import UpgradePage from './pages/upgrade/page';

const DEFAULT_ROUTE = ROUTES.redteam.dashboard;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
      gcTime: 10 * 60 * 1000, // 10 minutes
    },
  },
});
const ProtectedRoutes = () => (
  <RequireAuth>
    <RequireSelectedTeam>
      <Outlet />
    </RequireSelectedTeam>
  </RequireAuth>
);

const RedteamRestrictedRoutes = () => (
  <RequireRedteamAccess>
    <Outlet />
  </RequireRedteamAccess>
);

const OrgAdminRestrictedRoutes = () => (
  <RequireOrgAdmin>
    <Outlet />
  </RequireOrgAdmin>
);
const SentryRoutes = Sentry.withSentryRouting(Routes);

function WrappedApp() {
  const { organization, isLoading } = useAuth();
  const { config } = useConfig();

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Router>
      <DocumentTitle />
      <PageShell>
        <SentryRoutes>
          <Route
            path="/"
            element={
              <Navigate to={organization?.canUseRedteam ? DEFAULT_ROUTE : ROUTES.eval} replace />
            }
          />

          {/* Public routes */}
          <Route path={ROUTES.login} element={<LoginPage />} />
          <Route path={ROUTES.logout} element={<LogoutPage />} />
          <Route path={ROUTES.register} element={<Register />} />

          {/* FusionAuth Routes */}
          <Route path="/auth/fusionauth/callback" element={<OAuthCallback />} />

          <Route path={ROUTES.upgrade} element={<UpgradePage />} />

          {/* Evals and Reports are private but can be made public by the user */}
          <Route path={`${ROUTES.eval}/:id`} element={<ResultsPage />} />
          <Route path={`${ROUTES.redteam.report}/:id`} element={<RedteamReportPage />} />
          <Route path={ROUTES.evals} element={<EvalsIndexPage />} />

          {/* Protected routes */}
          <Route element={<ProtectedRoutes />}>
            {/* Auth & Account */}
            <Route path={ROUTES.welcome} element={<WelcomePage />} />
            <Route path={ROUTES.loggedInPasswordReset} element={<LoggedInResetPasswordPage />} />
            <Route element={<OrgAdminRestrictedRoutes />}>
              <Route path={`${ROUTES.organization.index}/:orgId`} element={<OrganizationPage />}>
                <Route path="details" element={<OrganizationDetails />} />
                <Route index element={<Navigate to="details" replace />} />
                <Route path="users" element={<OrganizationUsers />} />
                <Route path="teams">
                  <Route index element={<OrganizationTeams />} />
                  <Route path=":teamId" element={<TeamDetails />} />
                </Route>
                <Route path="roles">
                  <Route index element={<RolesList />} />
                  <Route path=":roleId" element={<RoleDetails />} />
                </Route>
              </Route>

              {config?.enableServerSettingsPage && (
                <Route path={ROUTES.serverSettings.index} element={<ServerSettingsPage />}>
                  <Route index element={<ServerSettingsEditPage />} />
                  <Route
                    path={`${ROUTES.serverSettings.edit}/:key`}
                    element={<ServerSettingsEditPage />}
                  />
                </Route>
              )}
            </Route>

            {/* Core Features */}
            <Route path={ROUTES.eval} element={<ResultsPage />} />

            {/* Enterprise routes */}
            <Route element={<RedteamRestrictedRoutes />}>
              <Route path={ROUTES.guardrails} element={<GuardrailsDashboard />} />
              <Route path={ROUTES.redteam.dashboard} element={<RedteamDashboardPage />} />
              <Route
                path={`${ROUTES.redteam.dashboard}/:targetId`}
                element={<RedteamDashboardPage />}
              />
              <Route path={ROUTES.redteam.report} element={<RedteamReportPage />} />

              {/* Configs */}
              <Route path={ROUTES.redteam.configs} element={<RedteamConfigsListPage />} />
              <Route path={`${ROUTES.redteam.configs}/:id`} element={<RedteamConfigsEditPage />} />

              {/* Plugins */}
              <Route path={ROUTES.redteam.plugins} element={<PluginSetsListPage />} />
              <Route path={`${ROUTES.redteam.plugins}/:id`} element={<EditPluginSetPage />} />

              {/* Jobs */}
              <Route path={ROUTES.jobs} element={<Jobs />} />
              <Route path={`${ROUTES.jobs}/:id`} element={<Jobs />} />

              {/* Vulnerabilities */}
              <Route path={ROUTES.redteam.vulnerabilities} element={<IssuesPage />} />
              <Route path={`${ROUTES.redteam.vulnerabilities}/:issueId`} element={<IssuesPage />} />

              {/* Targets */}
              <Route path={ROUTES.redteam.targets} element={<TargetListPage />} />
              <Route path={`${ROUTES.redteam.targets}/:id`} element={<EditTargetPage />} />
            </Route>
          </Route>

          {/* Catch-all + explicit entry for 404 Not Found */}
          <Route path={ROUTES.notFound} element={<NotFoundPage />} />
          <Route path={ROUTES.noTeam} element={<NoTeamPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </SentryRoutes>
      </PageShell>
    </Router>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}

      <ConfigProvider>
        <ConfiguredFusionAuth>
          <AuthProvider>
            <ToastProvider>
              <TeamsProvider>
                <RbacProvider>
                  <WrappedApp />
                </RbacProvider>
              </TeamsProvider>
            </ToastProvider>
          </AuthProvider>
        </ConfiguredFusionAuth>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
