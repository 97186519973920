import { useQuery } from '@tanstack/react-query';
import { fetchOpenRouterModels, type OpenRouterModelsResponse } from '@cloud-ui/utils/api/models';

/**
 * Hook to fetch OpenRouter models
 * @param options Optional React Query configuration options
 * @returns Query object with OpenRouter models data
 */
export const useOpenRouterModels = (options = {}) => {
  return useQuery<OpenRouterModelsResponse>({
    queryKey: ['openrouter', 'models'],
    queryFn: fetchOpenRouterModels,
    staleTime: 1000 * 60 * 60, // 1 hour cache
    refetchOnWindowFocus: false,
    retry: 2,
    ...options,
  });
};
