import type { UnifiedConfig } from '@promptfoo/types';
import type { EvalJobDTO } from '@shared/dto';
import { callApi } from '../api';

export async function startJob(
  config: UnifiedConfig,
  teamId: string,
  configId?: string | null,
  opts?: { force?: boolean; verbose?: boolean; delay?: number },
): Promise<EvalJobDTO> {
  const response = await callApi('/jobs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      config,
      configId,
      teamId,
      ...opts,
    }),
  });

  return await response.json();
}

export async function listJobs(teamId: string): Promise<EvalJobDTO[]> {
  const response = await callApi(`/jobs?teamId=${teamId}`);
  return await response.json();
}

export async function getJobDetails(jobId: string): Promise<EvalJobDTO> {
  const response = await callApi(`/jobs/${jobId}`);
  return await response.json();
}

export async function getJobLogs(jobId: string): Promise<{ data: string }[]> {
  const response = await callApi(`/jobs/${jobId}/logs`);
  return await response.json();
}

export async function stopJob(jobId: string): Promise<EvalJobDTO> {
  const response = await callApi(`/jobs/${jobId}/stop`, { method: 'POST' });
  return await response.json();
}
