import { useQuery } from '@tanstack/react-query';
import { getProvider, listProviders } from '@cloud-ui/utils/api/providers';
import type { GetByIdProviderOptions, ProviderDTO } from '@shared/dto/providers';

export function useGetProviders(teamId?: string, opts?: GetByIdProviderOptions) {
  const { data: providers, isLoading } = useQuery({
    queryKey: ['providers', teamId, opts?.includeDeleted],
    queryFn: () => listProviders(teamId, opts),
  });

  return { providers: providers as ProviderDTO[], isLoading };
}

// Helper hook to get providers including deleted ones
export function useGetProvidersWithDeleted(teamId?: string) {
  return useGetProviders(teamId, { includeDeleted: true });
}

export function useGetProvider(providerId: string, opts?: GetByIdProviderOptions) {
  const { data: provider, isLoading } = useQuery({
    queryKey: ['provider', providerId, opts?.includeDeleted],
    queryFn: () => getProvider(providerId, opts),
    enabled: !!providerId,
  });

  return { provider: provider as ProviderDTO | undefined, isLoading };
}

// Helper hook to get a provider even if it's deleted
export function useGetProviderWithDeleted(providerId: string) {
  return useGetProvider(providerId, { includeDeleted: true });
}
