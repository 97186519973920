import { useParams } from 'react-router-dom';
import Results from '@cloud-ui/pages/eval/components/Results';

export default function ResultsPage() {
  const { id } = useParams();

  const evalFromLoginRedirect = window.localStorage.getItem('redirect_to_eval_on_login');
  window.localStorage.removeItem('redirect_to_eval_on_login');

  return <Results fetchId={evalFromLoginRedirect ?? id} />;
}
