export const ROUTES = {
  upgrade: '/upgrade',
  config: '/config',
  datasets: '/datasets',
  eval: '/eval',
  evals: '/evals',
  guardrails: '/guardrails',
  jobs: '/jobs',
  loggedInPasswordReset: '/logged-in-reset-password',
  login: '/login',
  logout: '/logout',
  register: '/register',
  noTeam: '/no-team',
  notFound: '/not-found',
  organization: {
    index: '/organizations',
    details: '/organizations/:orgId',
    users: '/organizations/:orgId/users',
    teams: '/organizations/:orgId/teams',
    team: {
      details: '/organizations/:orgId/teams/:teamId',
      members: '/organizations/:orgId/teams/:teamId/members',
    },
    roles: {
      index: '/organizations/:orgId/roles',
      details: '/organizations/:orgId/roles/:roleId',
    },
  },
  passwordReset: {
    confirm: '/reset-password/confirm',
    request: '/reset-password',
  },
  redteam: {
    configs: '/redteam/configs',
    dashboard: '/redteam',
    plugins: '/redteam/plugins',
    report: '/redteam/report',
    targets: '/redteam/targets',
    vulnerabilities: '/redteam/vulnerabilities',
  },
  serverSettings: {
    edit: '/server/edit',
    index: '/server',
  },
  verifyEmail: '/verify-email',
  welcome: '/welcome',
};

export const EVAL_ID_SCAN_PATTERN = /\s+from scan:\s+(eval-[\w-:.]+)$/;

export const PAGE_TITLES_BY_ROUTE = {
  [ROUTES.redteam.targets]: 'Redteam / Targets',
  [ROUTES.redteam.plugins]: 'Redteam / Plugins Collection',
  [ROUTES.redteam.configs]: 'Redteam / Scan Configurations',
  [ROUTES.jobs]: 'Redteam / Scan History',
  [ROUTES.evals]: 'Evals',
};
