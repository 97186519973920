import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { useRoles, useCreateRole, useDeleteRole } from '@cloud-ui/hooks/useRoles';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  AdminPanelSettings as RoleIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { permissionSetDisplayNames, type RoleDTO } from '@shared/dto/rbac';
import { format } from 'date-fns';

// Create role dialog component
interface CreateRoleDialogProps {
  open: boolean;
  onClose: () => void;
  roleName: string;
  onRoleNameChange: (value: string) => void;
  onCreateRole: () => void;
}

const CreateRoleDialog = ({
  open,
  onClose,
  roleName,
  onRoleNameChange,
  onCreateRole,
}: CreateRoleDialogProps) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>Create New Role</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Role Name"
        fullWidth
        value={roleName}
        onChange={(e) => onRoleNameChange(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onCreateRole} variant="contained" disabled={!roleName.trim()}>
        Create
      </Button>
    </DialogActions>
  </Dialog>
);

export default function RolesList() {
  const navigate = useNavigate();
  const { orgId } = useParams<{ orgId: string }>();
  const { showToast } = useToast();
  const { data: roles = [], isLoading, refetch: refetchRoles } = useRoles(orgId || '');
  const { mutate: createRole } = useCreateRole(orgId || '');
  const { mutate: deleteRole } = useDeleteRole(orgId || '');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [newRoleName, setNewRoleName] = useState('');

  const handleCreateRole = () => {
    // For new roles, let's just add a placeholder permission
    // The actual permissions will be configured in the details page
    if (roles.find((role) => role.name === newRoleName)) {
      showToast('A role with this name already exists', 'error');
      return;
    }
    createRole(
      {
        name: newRoleName,
        permissionSets: [], // This will be populated with default READ permissions on the server
      },
      {
        onSuccess: (data) => {
          setIsCreateDialogOpen(false);
          setNewRoleName('');
          refetchRoles();
          navigate(`${ROUTES.organization.index}/${orgId}/roles/${data.id}`);
        },
      },
    );
  };

  const handleDeleteRole = (roleId: string) => {
    if (window.confirm('Are you sure you want to delete this role?')) {
      deleteRole(roleId);
      refetchRoles();
    }
  };

  const navigateToRole = (roleId: string) => {
    navigate(`${ROUTES.organization.index}/${orgId}/roles/${roleId}`);
  };

  return (
    <Box maxWidth="100%" mx="auto" p={3}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <Typography variant="h4">Roles</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsCreateDialogOpen(true)}
        >
          New Role
        </Button>
      </div>

      <CreateRoleDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        roleName={newRoleName}
        onRoleNameChange={(value) => setNewRoleName(value)}
        onCreateRole={handleCreateRole}
      />

      <Card>
        {isLoading ? (
          <Box p={4} textAlign="center">
            <Typography>Loading roles...</Typography>
          </Box>
        ) : roles.length === 0 ? (
          <Box p={4} textAlign="center">
            <RoleIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
            <Typography variant="h6" color="text.secondary" gutterBottom>
              No Roles Yet
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={3}>
              Create your first role to manage permissions
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setIsCreateDialogOpen(true)}
            >
              Create New Role
            </Button>
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Permissions</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map((role: RoleDTO) => {
                return (
                  <TableRow
                    key={role.id}
                    hover
                    sx={{
                      cursor: 'pointer',
                      '& td:not(:last-child)': {},
                    }}
                  >
                    <TableCell onClick={() => navigateToRole(role.id)}>{role.name}</TableCell>
                    <TableCell onClick={() => navigateToRole(role.id)}>
                      {role.permissionSets
                        .map((permissionSet) => permissionSetDisplayNames[permissionSet])
                        .join(', ')}
                    </TableCell>
                    <TableCell onClick={() => navigateToRole(role.id)}>
                      {format(new Date(role.createdAt), 'MMM d, yyyy')}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Edit role" placement="top">
                        <IconButton onClick={() => navigateToRole(role.id)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={'Delete role'} placement="top">
                        <span>
                          <IconButton onClick={() => handleDeleteRole(role.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Card>
    </Box>
  );
}
