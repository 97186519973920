import { CompletedPromptSchema, PromptWithMetadata } from '@promptfoo/types';
import { z } from 'zod';

/**
 * Defines the data format for prompts as they are passed between server and client.
 * This matches what the Prompts component expects as `PromptWithMetadata & { recentEvalDate: string }`.
 *
 * The backend converts Date objects to ISO strings before sending them to the client.
 */
export const PromptSchema = z.object({
  id: z.string(),
  prompt: z.custom<PromptWithMetadata['prompt']>(),
  description: z.string().optional(),
  evals: z.array(
    z.object({
      id: z.string(),
      metrics: CompletedPromptSchema.shape.metrics.optional(),
    }),
  ),
  recentEvalDate: z.string(),
  recentEvalId: z.string(),
  count: z.number(),
});

export const PromptsQueryParamsSchema = z.object({
  limit: z.string().optional(),
});

export type PromptsQueryParams = z.infer<typeof PromptsQueryParamsSchema>;

export const PromptsResponseSchema = z.object({
  data: z.array(PromptSchema),
});

export type PromptDTO = z.infer<typeof PromptSchema>;
export type PromptsResponse = z.infer<typeof PromptsResponseSchema>;
