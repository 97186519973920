import { Box, Typography, Button, Container } from '@mui/material';

export default function UpgradePage() {
  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#f5f5f5' }}>
      <Container>
        <Box
          sx={{
            py: { xs: 6, sm: 8 },
            textAlign: 'center',
            maxWidth: '65rem',
            mx: 'auto',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2.5rem', sm: '3.75rem' },
              fontWeight: 'bold',
              color: '#1a1a1a',
              mb: 2,
            }}
          >
            Secure Your LLM Applications with Promptfoo.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: '#666',
              mb: 4,
            }}
          >
            Detect, mitigate, and monitor vulnerabilities in AI systems
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mb: 6 }}>
            <Button
              variant="contained"
              href="https://www.promptfoo.dev/contact/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                bgcolor: '#0052CC',
                '&:hover': {
                  bgcolor: '#0747A6',
                },
                px: 4,
                py: 1.5,
              }}
            >
              Get started
            </Button>
            <Button
              variant="outlined"
              href="https://www.promptfoo.dev/contact/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: '#1a1a1a',
                borderColor: '#1a1a1a',
                '&:hover': {
                  bgcolor: '#f0f0f0',
                  borderColor: '#1a1a1a',
                },
                px: 4,
                py: 1.5,
              }}
            >
              Get a demo
            </Button>
          </Box>
        </Box>

        <Box sx={{ maxWidth: '90rem', mx: 'auto', px: { xs: 2, lg: 4 } }}>
          <Box
            component="img"
            src="https://img.promptfoo.app/continuous-monitoring.png"
            alt="Dashboard showing LLM security monitoring"
            sx={{
              width: '100%',
              borderRadius: 2,
              boxShadow: 3,
              border: '1px solid rgba(0,0,0,0.1)',
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}
