import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { callApi } from '@cloud-ui/utils/api';
import {
  fetchOrganizationDetails,
  fetchOrganizationUsers,
  setUserAdminStatus,
  updateOrganization,
} from '@cloud-ui/utils/api/organization';
import { type UpdateOrganizationRequest } from '@shared/dto/organizations';
import type { UserDTOWithTeams } from '@shared/dto/users';

export const useOrganization = (organizationId: string) => {
  return useQuery({
    queryKey: ['organization', 'details'],
    queryFn: () => fetchOrganizationDetails(organizationId),
    enabled: !!organizationId,
  });
};

export const useUpdateOrganization = (organizationId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: UpdateOrganizationRequest) => updateOrganization(organizationId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organization', 'details'] });
    },
  });
};

export const useOrganizationUsers = (organizationId: string) => {
  return useQuery({
    queryKey: ['organization', 'users'],
    queryFn: () => fetchOrganizationUsers(organizationId),
    enabled: !!organizationId,
    staleTime: 0,
  });
};

export const useSetUserAdminStatus = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userId, isAdmin }: { userId: string; isAdmin: boolean }) =>
      setUserAdminStatus(organizationId, userId, isAdmin),
    onSuccess: () => {
      // Invalidate the users query to refetch the updated data
      queryClient.invalidateQueries({ queryKey: ['organization', 'users'] });
      queryClient.invalidateQueries({ queryKey: ['organization', 'service-accounts'] });
    },
  });
};

export const useOrganizationServiceAccounts = (organizationId: string) => {
  return useQuery({
    queryKey: ['organization', 'service-accounts'],
    queryFn: async () => {
      const response = await callApi(`/organizations/${organizationId}/service-accounts`);
      return response.json() as Promise<UserDTOWithTeams[]>;
    },
    enabled: !!organizationId,
    staleTime: 0,
  });
};
