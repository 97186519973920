import React from 'react';

export interface UseMenuReturn {
  anchorEl: HTMLElement | null;
  setAnchorEl: (element: HTMLElement | null) => void;
  handleMouseEnter: (event: React.MouseEvent<HTMLElement>) => void;
  handleMouseLeave: (event: React.MouseEvent<HTMLElement>, menuId: string) => void;
}

export const useMenu = (): UseMenuReturn => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>, menuId: string) => {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (!relatedTarget?.closest(`#${menuId}`)) {
      setAnchorEl(null);
    }
  };

  return {
    anchorEl,
    setAnchorEl,
    handleMouseEnter,
    handleMouseLeave,
  };
};

export default useMenu;
