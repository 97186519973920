import React, { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import type { ResultsFile } from '@promptfoo/types';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import { useUIStore } from '../../../../stores/uiStore';
import { convertEvalDataToCsv } from '../utils/csvExport';

interface ReportDownloadButtonProps {
  evalDescription: string;
  evalData: ResultsFile;
  setIsPdfGenerating?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReportDownloadButton: React.FC<ReportDownloadButtonProps> = ({
  evalDescription,
  evalData,
  setIsPdfGenerating,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const setNavbarVisible = useUIStore(
    (state: { setNavbarVisible: (visible: boolean) => void }) => state.setNavbarVisible,
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFilename = (extension: string) => {
    return evalDescription
      ? `report_${evalDescription
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, '-')
          .replace(/(^-|-$)/g, '')}.${extension}`
      : `report.${extension}`;
  };

  const handlePdfDownload = async () => {
    setIsDownloading(true);
    handleClose();
    setIsPdfGenerating?.(true);
    setNavbarVisible(false);

    setTimeout(async () => {
      try {
        const element = document.documentElement;
        // Use toPng with filter for better text quality
        const dataUrl = await toPng(element, {
          quality: 1.0,
          pixelRatio: 2,
          height: Math.max(element.scrollHeight, element.offsetHeight),
          style: {
            transform: 'scale(1)',
            transformOrigin: 'top left',
          },
          filter: (node) => {
            return node.id !== 'report-download-menu';
          },
        });

        // Get image dimensions by creating a temp image
        const tempImage = new Image();
        await new Promise((resolve, reject) => {
          tempImage.onload = resolve;
          tempImage.onerror = reject;
          tempImage.src = dataUrl;
        });

        // Create PDF with proper image dimensions
        const pdf = new jsPDF({
          orientation: 'p',
          unit: 'pt',
          format: [tempImage.width, tempImage.height],
          compress: true,
        });

        // Add image to PDF
        pdf.addImage(dataUrl, 'PNG', 0, 0, tempImage.width, tempImage.height);
        pdf.save(getFilename('pdf'));
      } catch (error) {
        console.error('Error generating PDF:', error);
      } finally {
        setIsDownloading(false);
        setIsPdfGenerating?.(false);
        setNavbarVisible(true);
      }
    }, 100);
  };

  const handleCsvDownload = () => {
    setIsDownloading(true);
    handleClose();

    try {
      const csv = convertEvalDataToCsv(evalData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', getFilename('csv'));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error generating CSV:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Tooltip title="Download report" placement="top" open={isHovering && !isDownloading}>
        <IconButton
          onClick={handleClick}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          sx={{ mt: '4px', position: 'relative' }}
          aria-label="download report"
          disabled={isDownloading}
        >
          {isDownloading ? <CircularProgress size={20} /> : <DownloadIcon />}
        </IconButton>
      </Tooltip>
      <Menu
        id="report-download-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handlePdfDownload}>PDF</MenuItem>
        <MenuItem onClick={handleCsvDownload}>Download as CSV</MenuItem>
      </Menu>
    </>
  );
};

export default ReportDownloadButton;
