import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataGrid, { type GridColDef } from '@cloud-ui/components/DataGrid';
import { ROUTES } from '@cloud-ui/constants';
import { useCan } from '@cloud-ui/contexts/RbacContext';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import {
  deletePluginCollection,
  createPluginCollection,
  type PluginCollection,
} from '@cloud-ui/utils/api/redteam';
import { Add as AddIcon, ContentCopy, Delete, Edit, Search as ViewIcon } from '@mui/icons-material';
import { Box, Button, Card, IconButton, Typography, Tooltip, Stack } from '@mui/material';
import { Actions, Subjects } from '@shared/dto/rbac';
import type { CreatePluginCollectionRequest } from '@shared/dto/redteamConfigs';
import { format } from 'date-fns';
import { useGetPluginCollections } from '../hooks/pluginCollections';

function CopyPluginCollectionButton({ collection }: { collection: PluginCollection }) {
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => {
      const copy: CreatePluginCollectionRequest = {
        name: collection.name + ' (Copy)',
        plugins: collection.plugins,
        teamId: collection.teamId,
      };

      return createPluginCollection(copy);
    },
    onSuccess: async (result) => {
      await queryClient.invalidateQueries({ queryKey: ['pluginCollections'] });
      showToast('Plugin collection copied successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to copy plugin collection:', error);
      showToast('Failed to copy plugin collection', 'error');
    },
  });

  function handleClick() {
    mutation.mutate();
  }

  return (
    <IconButton onClick={handleClick} title="Copy">
      <ContentCopy />
    </IconButton>
  );
}

// TODO: A better UX would be to display the number of configs that the collection is used by and disable the deletion
// button unless the target is not used by any configs.
function DeletePluginCollectionButton({ collection }: { collection: PluginCollection }) {
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deletePluginCollection,
    onSuccess: async (_, collectionId) => {
      queryClient.setQueryData(['pluginCollections'], (old: PluginCollection[] | undefined) =>
        old?.filter((pluginCollection) => pluginCollection.id !== collectionId),
      );
      await queryClient.invalidateQueries({ queryKey: ['pluginCollections'] });
      showToast('Plugin collection deleted successfully', 'success');
    },
    onError: (error: any) => {
      console.error('Failed to delete plugin collection:', error);
      if (error.configNames) {
        showToast(
          <>
            <span>Cannot delete collection. The following configs are still using it: </span>
            <span style={{ fontWeight: 500 }}>{error.configNames.join(', ')}</span>
          </>,
          'error',
          5000,
        );
      } else {
        showToast('Failed to delete plugin collection', 'error');
      }
    },
  });

  function handleClick() {
    if (window.confirm('Are you sure you want to delete this plugin collection?')) {
      mutation.mutate(collection.id);
    }
  }

  return (
    <IconButton onClick={handleClick} title="Delete">
      <Delete />
    </IconButton>
  );
}

export default function PluginCollectionsListPage() {
  const navigate = useNavigate();
  const { currentTeam } = useTeamsContext();
  const canCreate = useCan(Actions.CREATE, Subjects.PLUGIN_COLLECTION);
  const canDelete = useCan(Actions.DELETE, Subjects.PLUGIN_COLLECTION);
  const canEdit = useCan(Actions.UPDATE, Subjects.PLUGIN_COLLECTION);

  const { pluginCollections, isLoading } = useGetPluginCollections(currentTeam!.id);

  const handleCellClick = (params: any) => {
    if (params.field !== 'actions') {
      navigate(`${ROUTES.redteam.plugins}/${params.row.id}`);
    }
  };

  const columns: GridColDef<PluginCollection>[] = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        valueGetter: (value, row) => row.name || row.description || 'Unnamed Collection',
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'plugins',
        headerName: 'Plugins',
        flex: 1,
        renderCell: (params) => {
          const value = (params.value ?? []) as PluginCollection['plugins'];
          return (
            <Box display="flex" alignItems="center" height="100%">
              {value.length > 0 ? (
                <Tooltip
                  title={
                    <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
                      <Stack spacing={1} sx={{ p: 1 }}>
                        {value.map((plugin) => (
                          <Typography
                            key={typeof plugin === 'string' ? plugin : plugin.id}
                            variant="body2"
                          >
                            {typeof plugin === 'string' ? plugin : plugin.id}
                          </Typography>
                        ))}
                      </Stack>
                    </Box>
                  }
                >
                  <Typography variant="body2" color="primary">
                    {value.length} plugin
                    {value.length === 1 ? '' : 's'}
                  </Typography>
                </Tooltip>
              ) : (
                '0 plugins'
              )}
            </Box>
          );
        },
        // TODO: Allow for sorting / filtering by individual plugins
        sortable: false,
        filterable: false,
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'user',
        headerName: 'Last Updated By',
        flex: 1,
        renderCell: (params) => {
          return <span>{params.value}</span>;
        },
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'updatedAt',
        headerName: 'Last Updated',
        flex: 1,
        valueFormatter: (value: PluginCollection['updatedAt']) =>
          format(new Date(value), 'MMM d, yyyy HH:mm'),
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params) => {
          const id = params.row.id;
          return (
            <Box display="flex" gap={1}>
              <IconButton
                onClick={() => navigate(`${ROUTES.redteam.plugins}/${id}`)}
                title={canEdit ? 'Edit' : 'View'}
              >
                {canEdit ? <Edit /> : <ViewIcon />}
              </IconButton>
              {canCreate && <CopyPluginCollectionButton collection={params.row} />}
              {canDelete && <DeletePluginCollectionButton collection={params.row} />}
            </Box>
          );
        },
        sortable: false,
        filterable: false,
      },
    ];
  }, [navigate, canEdit, canCreate, canDelete]);

  return (
    <Box maxWidth="100%" mx="auto" p={3}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <Typography variant="h4">Plugin Collections</Typography>
        {canCreate && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate(`${ROUTES.redteam.plugins}/new`)}
          >
            New Plugin Collection
          </Button>
        )}
      </div>

      <Card>
        {!isLoading && (!pluginCollections || pluginCollections.length === 0) ? (
          <Box p={4} textAlign="center">
            <Typography variant="h6" color="text.secondary" gutterBottom>
              No Plugin Collections Yet
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={3}>
              Create your first plugin collection to get started
            </Typography>
            {canCreate && (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate(`${ROUTES.redteam.plugins}/new`)}
              >
                Create New Plugin Collection
              </Button>
            )}
          </Box>
        ) : (
          <DataGrid<PluginCollection>
            columns={columns}
            rows={pluginCollections ?? []}
            onCellClick={handleCellClick}
          />
        )}
      </Card>
    </Box>
  );
}
