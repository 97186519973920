import type {
  TeamDTO,
  CreateTeamDTO,
  UpdateTeamDTO,
  TeamWithoutMembersDTO,
} from '@shared/dto/teams';
import { callApi } from '../api';

export const fetchTeams = async (): Promise<TeamDTO[]> => {
  const response = await callApi('/teams');
  return await response.json();
};

export const getTeam = async (teamId: string): Promise<TeamDTO> => {
  const response = await callApi(`/teams/${teamId}`);
  return await response.json();
};

export const createTeam = async (name: string): Promise<TeamDTO> => {
  const response = await callApi('/teams', {
    method: 'POST',
    body: JSON.stringify({ name } as CreateTeamDTO),
  });
  return await response.json();
};

export const updateTeam = async (id: string, changes: UpdateTeamDTO): Promise<TeamDTO> => {
  const response = await callApi(`/teams/${id}`, {
    method: 'PUT',
    body: JSON.stringify(changes),
  });
  return await response.json();
};

export const deleteTeam = async (teamId: string): Promise<void> => {
  await callApi(`/teams/${teamId}`, {
    method: 'DELETE',
  });
};

export const addTeamMember = async (
  teamId: string,
  userId: string,
  roleId?: string,
): Promise<void> => {
  await callApi(`/teams/${teamId}/members`, {
    method: 'POST',
    body: JSON.stringify({ userId, roleId }),
  });
};

export const removeTeamMember = async (teamId: string, userId: string): Promise<void> => {
  await callApi(`/teams/${teamId}/members/${userId}`, {
    method: 'DELETE',
  });
};

export const getTeamMembers = async (teamId: string): Promise<TeamDTO['members']> => {
  const response = await callApi(`/teams/${teamId}/members`);
  return await response.json();
};

export const getCurrentUserTeams = async (): Promise<TeamWithoutMembersDTO[]> => {
  const response = await callApi('/users/me/teams');
  return await response.json();
};

export const updateTeamMemberRole = async (
  teamId: string,
  userId: string,
  roleId: string,
): Promise<void> => {
  await callApi(`/teams/${teamId}/members/${userId}`, {
    method: 'PUT',
    body: JSON.stringify({ roleId }),
  });
};
