import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useAuth } from '@cloud-ui/contexts/AuthContext';
import { ShiftKeyProvider } from '@cloud-ui/contexts/ShiftKeyContext';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import { useEval, useEvals, useDeleteEval } from '@cloud-ui/hooks/useEvals';
import CircularProgress from '@mui/material/CircularProgress';
import type { SharedResults } from '@promptfoo/types';
import type { EvalWithAuthor } from '@shared/dto';
import EmptyStateView from './EmptyStateView';
import ResultsView from './ResultsView';
import { useStore } from './store';
import './Results.css';

interface EvalOptions {
  fetchId?: string;
  preloadedData?: SharedResults;
  recentEvals?: EvalWithAuthor[];
  defaultEvalId?: string;
}

export default function Eval({ fetchId }: EvalOptions) {
  const navigate = useNavigate();
  const { organization, user } = useAuth();

  const {
    setTableFromResultsFile,
    setIsPublic,
    config,
    setConfig,
    evalId,
    setEvalId,
    setAuthor,
    setInComparisonMode,
    table,
  } = useStore();

  const { currentTeam } = useTeamsContext();

  const { mutate: deleteEvalMutation } = useDeleteEval(currentTeam?.id || '');
  const [defaultEvalId, setDefaultEvalId] = React.useState<string>();

  const handleRecentEvalSelection = async (id: string) => {
    navigate(`${ROUTES.eval}/${id}`);
  };

  const { data: recentEvals, isLoading: recentEvalsLoading } = useEvals(
    { teamId: currentTeam?.id },
    {
      enabled: !!currentTeam && !!currentTeam,
    },
  );
  const { data: evalData, isLoading: evalDataLoading } = useEval(fetchId || defaultEvalId || '', {
    enabled: !!(fetchId || defaultEvalId),
  });

  React.useEffect(() => {
    if (evalData) {
      setTableFromResultsFile(evalData);
      setIsPublic(evalData.isPublic);
      setConfig(evalData.config);
      setAuthor(evalData.author);
      setEvalId(fetchId || defaultEvalId || '');
    }
  }, [evalData]);
  React.useEffect(() => {
    const evalId = fetchId;

    if (evalId) {
      setDefaultEvalId(evalId);
    } else if (recentEvals && recentEvals.length > 0) {
      const defaultEvalId = recentEvals[0].evalId;
      setDefaultEvalId(defaultEvalId);
    }
    setInComparisonMode(false);
  }, [fetchId, recentEvals, setDefaultEvalId, setInComparisonMode]);

  React.useEffect(() => {
    document.title = `${config?.description || evalId || 'Eval'} | promptfoo`;
  }, [config, evalId]);

  const handleDeleteEval = async (evalId: string) => {
    // Delete the eval
    deleteEvalMutation(evalId);

    if (recentEvals && recentEvals.length > 0) {
      handleRecentEvalSelection(recentEvals[0]?.evalId);
    }
  };

  if (evalDataLoading || recentEvalsLoading) {
    return (
      <div className="notice">
        <div>
          <CircularProgress size={22} />
        </div>
        <div>Waiting for eval data</div>
      </div>
    );
  }

  if (
    user &&
    !evalDataLoading &&
    !recentEvalsLoading &&
    (recentEvals?.length === 0 || !recentEvals)
  ) {
    if (organization?.canUseRedteam) {
      return <EmptyStateView type="redteam" targetRoute={ROUTES.redteam.targets} />;
    } else {
      navigate(ROUTES.welcome);
    }
  }

  return (
    <ShiftKeyProvider>
      {table ? (
        <ResultsView
          defaultEvalId={defaultEvalId}
          recentEvals={recentEvals || []}
          onRecentEvalSelected={handleRecentEvalSelection}
          onDelete={handleDeleteEval}
        />
      ) : (
        <div>
          <CircularProgress size={22} />
        </div>
      )}
    </ShiftKeyProvider>
  );
}
