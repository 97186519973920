import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import LoadingSpinner from '@cloud-ui/components/LoadingSpinner';
import { ROUTES } from '@cloud-ui/constants';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import type { PolicyPlugin } from '@cloud-ui/pages/redteam/components/plugins/CustomPoliciesSection';
import PluginCard from '@cloud-ui/pages/redteam/components/plugins/PluginCard';
import { useGetPluginCollections } from '@cloud-ui/pages/redteam/hooks/pluginCollections';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  type SelectChangeEvent,
  Divider,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import type { Plugin } from '@promptfoo/redteam/constants';
import PluginSelector from '../../../components/plugins/Plugins';
import { useRedTeamConfig } from '../hooks/useRedTeamConfig';

interface PluginsProps {
  onNext?: () => void;
  onBack?: () => void;
}

const CUSTOM_PLUGIN_COLLECTION_VALUE = 'Custom';
export default function Plugins({ onNext, onBack }: PluginsProps) {
  const theme = useTheme();
  const { config, updateConfig } = useRedTeamConfig();
  const { currentTeam } = useTeamsContext();

  const customPolicies = useMemo(() => {
    return config.plugins.filter(
      (p): p is PolicyPlugin => typeof p === 'object' && p.id === 'policy',
    );
  }, [config.plugins]);

  const intents = useMemo(() => {
    return config.plugins
      .filter(
        (p): p is { id: 'intent'; config: { intent: string | string[] } } =>
          typeof p === 'object' && p.id === 'intent' && p.config?.intent !== undefined,
      )
      .map((p) => p.config.intent)
      .flat()
      .filter((intent): intent is string => typeof intent === 'string' && intent.trim() !== '');
  }, [config.plugins]);

  const { pluginCollections, isLoading } = useGetPluginCollections(currentTeam?.id ?? '');

  const [dropdownValue, setDropdownValue] = React.useState<string | null>(
    config.pluginCollectionId ?? CUSTOM_PLUGIN_COLLECTION_VALUE,
  );

  const selectedPluginCollection = React.useMemo(() => {
    return pluginCollections?.find((pluginCollection) => pluginCollection.id === dropdownValue);
  }, [pluginCollections, dropdownValue]);

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    setDropdownValue(event.target.value);

    if (event.target.value === CUSTOM_PLUGIN_COLLECTION_VALUE) {
      updateConfig('pluginCollectionId', null);
      updateConfig('plugins', []);
    } else {
      const plugins = pluginCollections?.find(
        (pluginCollection) => pluginCollection.id === event.target.value,
      )?.plugins;
      updateConfig('pluginCollectionId', event.target.value);
      updateConfig('plugins', plugins);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
        Plugin Configuration
      </Typography>
      <Typography variant="body1" paragraph>
        Select from a collection of plugins or create your own.
      </Typography>

      {pluginCollections && pluginCollections.length > 0 && (
        <Box sx={{ maxWidth: '400px', mb: 4 }}>
          <FormControl fullWidth size="medium">
            <InputLabel>Plugin Collection</InputLabel>
            <Select
              label="Plugin Collection"
              value={dropdownValue ?? ''}
              onChange={handleDropdownChange}
              sx={{ backgroundColor: 'background.paper' }}
            >
              {pluginCollections?.map((pluginCollection) => (
                <MenuItem key={pluginCollection.id} value={pluginCollection.id}>
                  {pluginCollection.name}
                </MenuItem>
              ))}
              {pluginCollections?.length && pluginCollections.length > 0 && (
                <Divider sx={{ my: 1 }} />
              )}
              <MenuItem key={CUSTOM_PLUGIN_COLLECTION_VALUE} value={CUSTOM_PLUGIN_COLLECTION_VALUE}>
                Custom
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
      {pluginCollections?.length === 0 && (
        <Paper
          elevation={0}
          sx={{
            mt: 2,
            p: 2,
            bgcolor: 'rgba(25, 118, 210, 0.08)',
            borderRadius: 2,
            border: `1px solid ${theme.palette.info.main}`,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box sx={{ color: theme.palette.info.main }}>
            <InfoIcon fontSize="small" />
          </Box>
          <Box>
            <Typography variant="body1" color="info.main">
              Did you know you can create your own plugin collections and re-use them across
              multiple redteam configs?{' '}
              <Link to={ROUTES.redteam.plugins} style={{ textDecoration: 'none' }}>
                <Typography
                  component="span"
                  sx={{
                    color: theme.palette.info.main,
                    fontWeight: 'medium',
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 0.5,
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Create a plugin collection
                  <ArrowForwardIcon fontSize="small" />
                </Typography>
              </Link>
            </Typography>
          </Box>
        </Paper>
      )}
      <Box sx={{ mt: 2 }}>
        {dropdownValue === 'Custom' ? (
          <PluginSelector
            showHeader={false}
            value={config.plugins || []}
            onChange={(plugins) => {
              updateConfig('plugins', plugins);
            }}
          />
        ) : (
          <Box>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
              {selectedPluginCollection?.name ?? 'Custom'}
              <Link to={`${ROUTES.redteam.plugins}/${dropdownValue}`}>
                <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                  Edit Plugin Collection
                </Typography>
              </Link>
            </Typography>
            <Grid container spacing={2}>
              {pluginCollections
                ?.find((pluginCollection) => pluginCollection.id === dropdownValue)
                ?.plugins.map((plugin) => (
                  <PluginCard
                    plugin={typeof plugin === 'string' ? (plugin as Plugin) : (plugin.id as Plugin)}
                    selectedPlugins={
                      new Set(
                        config.plugins.map((p) => (typeof p === 'string' ? p : p.id)),
                      ) as Set<Plugin>
                    }
                    handlePluginToggle={() => {}}
                    handleConfigClick={() => {}}
                    isPluginConfigured={() => false}
                    isReadOnly
                  />
                ))}
            </Grid>
            {customPolicies.length > 0 && (
              <Grid item xs={12} md={6}>
                <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
                  <Typography variant="h6" gutterBottom>
                    Custom Policies ({customPolicies.length})
                  </Typography>
                  <Stack spacing={1}>
                    {customPolicies.map((policy, index) => (
                      <Box
                        key={index}
                        sx={{
                          p: 1.5,
                          borderRadius: 1,
                          bgcolor: theme.palette.action.hover,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {policy.config.policy}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                </Paper>
              </Grid>
            )}

            {intents.length > 0 && (
              <Grid item xs={12} md={6}>
                <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
                  <Typography variant="h6" gutterBottom>
                    Intents ({intents.length})
                  </Typography>
                  <Stack spacing={1}>
                    {intents.map((intent, index) => (
                      <Box
                        key={index}
                        sx={{
                          p: 1.5,
                          borderRadius: 1,
                          bgcolor: theme.palette.action.hover,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {intent}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                </Paper>
              </Grid>
            )}
          </Box>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button
          variant="outlined"
          onClick={onBack}
          startIcon={<KeyboardArrowLeftIcon />}
          sx={{ px: 4, py: 1 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          endIcon={<KeyboardArrowRightIcon />}
          disabled={false}
          sx={{
            backgroundColor: theme.palette.primary.main,
            '&:hover': { backgroundColor: theme.palette.primary.dark },
            px: 4,
            py: 1,
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
