import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PAGE_TITLES_BY_ROUTE } from '@cloud-ui/constants';

/**
 * Sets the current page's document title if one is defined.
 *
 * TODO: Fix this for dashboard: the route definition is incorrect w/
 * how we're populating the targetId.
 */
export default function DocumentTitle() {
  const location = useLocation();

  useEffect(() => {
    const title = PAGE_TITLES_BY_ROUTE[location.pathname];
    if (title) {
      document.title = `${title} | promptfoo`;
    } else {
      // Default title:
      document.title = 'promptfoo';
    }
  }, [location.pathname]);

  return null;
}
