import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@cloud-ui/contexts/AuthContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import {
  fetchTeams,
  getTeam,
  createTeam,
  updateTeam,
  deleteTeam,
  addTeamMember,
  removeTeamMember,
  getTeamMembers,
  getCurrentUserTeams,
} from '@cloud-ui/utils/api/teams';

export const useTeams = () => {
  return useQuery({
    queryKey: ['teams'],
    queryFn: fetchTeams,
  });
};

export const useTeam = (teamId: string) => {
  return useQuery({
    queryKey: ['teams', teamId],
    queryFn: () => getTeam(teamId),
    enabled: !!teamId,
    refetchOnMount: true,
    staleTime: 0,
  });
};

export const useTeamMembers = (teamId: string) => {
  return useQuery({
    queryKey: ['teams', teamId, 'members'],
    queryFn: () => getTeamMembers(teamId),
    enabled: !!teamId,
    refetchOnMount: true,
    staleTime: 0,
  });
};

export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: createTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      showToast('Team created successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to create team:', error);
      showToast('Failed to create team', 'error');
    },
  });
};

export const useUpdateTeam = (teamId: string) => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: (changes: Parameters<typeof updateTeam>[1]) => updateTeam(teamId, changes),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams', teamId] });
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      queryClient.invalidateQueries({ queryKey: ['organization', 'users'] });
      queryClient.invalidateQueries({ queryKey: ['organization', 'service-accounts'] });
      showToast('Team updated successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to update team:', error);
      showToast('Failed to update team', 'error');
    },
  });
};

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: deleteTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      queryClient.invalidateQueries({ queryKey: ['organization', 'users'] });
      queryClient.invalidateQueries({ queryKey: ['organization', 'service-accounts'] });
      showToast('Team deleted successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to delete team:', error);
      showToast('Failed to delete team', 'error');
    },
  });
};

export const useAddTeamMember = (teamId: string) => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: (userId: string) => addTeamMember(teamId, userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams', teamId, 'members'] });
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      queryClient.invalidateQueries({ queryKey: ['organization', 'users'] });
      queryClient.invalidateQueries({ queryKey: ['organization', 'service-accounts'] });
      showToast('Team member added successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to add team member:', error);
      showToast('Failed to add team member', 'error');
    },
  });
};

export const useRemoveTeamMember = (teamId: string) => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: (userId: string) => removeTeamMember(teamId, userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams', teamId, 'members'] });
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      queryClient.invalidateQueries({ queryKey: ['organization', 'users'] });
      queryClient.invalidateQueries({ queryKey: ['organization', 'service-accounts'] });
      showToast('Team member removed successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to remove team member:', error);
      showToast('Failed to remove team member', 'error');
    },
  });
};

export const useCurrentUserTeams = () => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['currentUserTeams'],
    queryFn: () => getCurrentUserTeams(),
    enabled: !!user,
    staleTime: 30 * 1000,
  });
};
