import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';
import { type MongoAbility, createMongoAbility } from '@casl/ability';
import { useUserTeamAbilities } from '@cloud-ui/hooks/useUser';
import type { Subjects } from '@shared/dto/rbac';
import { useTeamsContext } from './TeamsContext';

export type Action = 'manage' | 'read' | 'create' | 'update' | 'delete' | 'comment';

export type AppAbility = MongoAbility;

// Type definition matching the shape of permission rules
type PermissionRule = { action: string; subject: string };

interface RbacContextType {
  ability: AppAbility | null | undefined;
  isOrgAdmin: boolean;
  isLoading: boolean;
  isError: boolean;
}

export const RbacContext = createContext<RbacContextType | null>(null);

interface RbacProviderProps {
  children: ReactNode;
}

export const useRbac = () => {
  const context = useContext(RbacContext);
  if (context == null) {
    throw new Error('useRbac must be used within an RbacProvider');
  }
  return context;
};

export const useCan = (action: Action, subject: Subjects) => {
  const { ability } = useRbac();
  return ability?.can(action, subject) || false;
};

export const RbacProvider = ({ children }: RbacProviderProps) => {
  const { currentTeam } = useTeamsContext();
  const { data, isLoading, isError } = useUserTeamAbilities(currentTeam?.id);

  // Create a new ability instance with the rules from the server
  // The server now returns rules directly as an array of {action, subject} objects
  const ability = data ? createMongoAbility(data as unknown as PermissionRule[]) : null;

  // If still loading, return null to prevent flash of unauthorized content
  if (isLoading) {
    return null;
  }

  return (
    <RbacContext.Provider value={{ ability, isOrgAdmin: false, isLoading, isError }}>
      {children}
    </RbacContext.Provider>
  );
};
