import { useState, useCallback, useEffect } from 'react';

/**
 * Custom hook to handle copying text to clipboard with automatic reset
 *
 * @param resetDelay - Time in ms before resetting the copied state (default: 2000ms)
 * @returns [copied, copyToClipboard] - Whether text was copied and function to copy text
 */
export function useCopyToClipboard(resetDelay = 2000) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = useCallback((text: string) => {
    if (typeof navigator !== 'undefined' && navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopied(true);
        })
        .catch((err) => {
          console.error('Failed to copy text:', err);
        });
    }
  }, []);

  useEffect(() => {
    if (copied) {
      const id = setTimeout(() => setCopied(false), resetDelay);
      return () => clearTimeout(id);
    }
  }, [copied, resetDelay]);

  return [copied, copyToClipboard] as const;
}

export default useCopyToClipboard;
