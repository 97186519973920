import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DataGrid, { type GridColDef } from '@cloud-ui/components/DataGrid';
import { ROUTES } from '@cloud-ui/constants';
import { Typography, Box } from '@mui/material';
import type { DashboardEvalDTO } from '@shared/dto';

type ExtendedDashboardEvalDTO = DashboardEvalDTO & {
  testCount: number;
  passCount: number;
  failCount: number;
  successRate: number;
};

// BUG: Run an eval; navigate to this report. You won't have the most recent data until refreshing the page (stale cache)!
const RecentEvals: React.FC<{ evals: DashboardEvalDTO[]; selectedTargetId: string | null }> = ({
  evals,
  selectedTargetId,
}) => {
  const navigate = useNavigate();

  const filteredEvals = useMemo(() => {
    return (
      evals
        .filter((eval_) => eval_.isRedteam)
        /**
         * Compute defaults and aggregate counts once, ensuring consistency.
         */
        .map((eval_) => {
          const passCount = eval_.metrics?.testPassCount ?? 0;
          const failCount = eval_.metrics?.testFailCount ?? 0;
          const testCount = passCount + failCount;
          return {
            ...eval_,
            testCount,
            passCount,
            failCount,
            successRate: testCount > 0 ? passCount / testCount : 0,
          };
        })
    );
  }, [evals]);

  const handleCellClick = (params: any) => {
    if (params.field !== 'vulnerabilities') {
      navigate(`${ROUTES.redteam.report}/${params.row.evalId}`);
    }
  };

  const columns: GridColDef<ExtendedDashboardEvalDTO>[] = useMemo(
    () => [
      {
        field: 'eval',
        headerName: 'Eval',
        flex: 1,
        valueGetter: (value, row) => row.description || row.evalId,
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        flex: 1,
        valueGetter: (value, row) => new Date(row.createdAt),
        valueFormatter: (value: Date) => value.toLocaleString(),
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'vulnerabilities',
        headerName: 'Vulnerabilities',
        flex: 1,
        valueGetter: (value, row) =>
          Object.entries(row.pluginFailCount).filter(([_, count]) => count > 0).length,
        renderCell: (params) => (
          <Link
            to={`${ROUTES.redteam.vulnerabilities}${selectedTargetId ? `?targetId=${selectedTargetId}` : ''}`}
          >
            {params.value}
          </Link>
        ),
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'testCount',
        headerName: 'Attacks Run',
        flex: 1,
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'attacksSucceeded',
        headerName: 'Attacks Succeeded',
        flex: 1,
        valueGetter: (value, row) => row.successRate,
        renderCell: (params) => `${params.row.failCount} / ${params.row.testCount}`,
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'successRate',
        headerName: 'Attack Success Rate',
        flex: 1,
        renderCell: (params) => `${(params.value * 100).toFixed(1)}%`,
        cellClassName: 'dg-cursor-pointer',
      },
    ],
    [selectedTargetId],
  );

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Recent Runs
      </Typography>
      {filteredEvals.length === 0 ? (
        <Box sx={{ py: 4, textAlign: 'center' }}>
          <Typography variant="body1" color="text.secondary">
            No red team evaluations found. Run an evaluation to see results here.
          </Typography>
        </Box>
      ) : (
        <DataGrid<ExtendedDashboardEvalDTO>
          columns={columns}
          rows={filteredEvals}
          defaultSort={{ field: 'createdAt', sort: 'desc' }}
          idColumn="evalId"
          onCellClick={handleCellClick}
        />
      )}
    </>
  );
};

export default RecentEvals;
