import type { RedteamPlugin as RedteamPluginType } from '@promptfoo/redteam/types';
import {
  ProviderOptions,
  RedteamPlugin,
  RedteamRunOptions,
  RedteamStrategy,
} from '@promptfoo/types';
import { z } from 'zod';

export const RedteamConfigDTOSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
  config: z.custom<RedteamConfig>(),
  organizationId: z.string(),
  teamId: z.string().nullable(),
  pluginCollectionId: z.string().optional(),
  userId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date().optional(),
  user: z.string().optional(),
});

// Types needed for the UI
export const BrowserStepSchema = z.object({
  action: z.enum([
    'navigate',
    'click',
    'type',
    'extract',
    'screenshot',
    'wait',
    'waitForNewChildren',
  ]),
  args: z
    .object({
      url: z.string().optional(),
      selector: z.string().optional(),
      text: z.string().optional(),
      path: z.string().optional(),
      ms: z.number().optional(),
      fullPage: z.boolean().optional(),
      parentSelector: z.string().optional(),
      delay: z.number().optional(),
      timeout: z.number().optional(),
      optional: z.boolean().optional(),
    })
    .optional(),
  name: z.string().optional(),
});

export const ApplicationDescriptionSchema = z.object({
  purpose: z.string().optional(),
  systemPrompt: z.string().optional(),
  redteamUser: z.string().optional(),
  accessToData: z.string().optional(),
  forbiddenData: z.string().optional(),
  accessToActions: z.string().optional(),
  forbiddenActions: z.string().optional(),
  connectedSystems: z.string().optional(),
});

export const RedteamConfigSchema = z.object({
  id: z.string().optional(),
  sharing: z.boolean().optional(),
  description: z.string(),
  prompts: z.array(z.string()),
  target: z.custom<ProviderOptions>().optional(),
  plugins: z.array(
    z.union([z.custom<RedteamPlugin>(), z.object({ id: z.string(), config: z.any().optional() })]),
  ),
  pluginCollectionId: z.string().optional(),
  strategies: z.array(z.custom<RedteamStrategy>()),
  purpose: z.string().optional(),
  numTests: z.number().optional(),
  extensions: z.array(z.string()).optional(),
  applicationDefinition: z.custom<ApplicationDescription>(),
  entities: z.array(z.string()),
  updatedAt: z.date().optional(),
  createdAt: z.date().optional(),
  userId: z.string().optional(),
  organizationId: z.string().optional(),
  teamId: z.string().optional(),
  runOptions: z.custom<RedteamRunOptions>().optional(),
});

export const UpdatePluginCollectionRequestSchema = z.object({
  plugins: z.array(z.custom<RedteamPlugin>()).optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  teamId: z.string().optional(),
});

export const PluginCollectionResponseSchema = z.object({
  id: z.string(),
  plugins: z.array(z.custom<RedteamPlugin>()),
  organizationId: z.string(),
  userId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
  version: z.number(),
  user: z.string().optional(),
  description: z.string().nullable(),
  name: z.string(),
  teamId: z.string(),
});

export const CreatePluginCollectionSchema = z.object({
  name: z.string().min(1, 'Name is required').trim(),
  description: z.string().nullable().optional(),
  plugins: z
    .array(z.any() as z.ZodType<RedteamPluginType>)
    .min(1, 'At least one plugin must be selected'),
  teamId: z.string(),
});

export const CreateConfigSchema = z.object({
  name: z.string().min(1, 'Name is required').trim(),
  description: z.string().nullable().optional(),
  config: z.any(),
  teamId: z.string(),
  pluginCollectionId: z.string().nullable().optional(),
  providerId: z.string().nullable().optional(),
});

export type RedteamConfigDTO = z.infer<typeof RedteamConfigDTOSchema>;
export type BrowserStep = z.infer<typeof BrowserStepSchema>;
export type ApplicationDescription = z.infer<typeof ApplicationDescriptionSchema>;
export type RedteamConfig = z.infer<typeof RedteamConfigSchema>;
export type UpdatePluginCollectionRequest = z.infer<typeof UpdatePluginCollectionRequestSchema>;
export type PluginCollectionResponse = z.infer<typeof PluginCollectionResponseSchema>;
export type PluginCollectionFormData = z.infer<typeof CreatePluginCollectionSchema>;
export type CreatePluginCollectionRequest = z.infer<typeof CreatePluginCollectionSchema>;
export type CreateConfigRequest = z.infer<typeof CreateConfigSchema>;
