import React from 'react';
import { getAuthToken, API_BASE_URL } from '@cloud-ui/utils/api';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import CommandBox from './CommandBox';
import './CliLoginPanel.css';

export interface CliLoginPanelProps {
  /** Optional custom title, defaults to "Welcome to Promptfoo" */
  title?: string;
  /** Optional custom icon, defaults to AssessmentIcon */
  icon?: React.ReactNode;
  /** Optional additional content to render after the standard sections */
  additionalContent?: React.ReactNode;
  /** Optional custom styles for the Paper component */
  paperSx?: Record<string, any>;
}

/**
 * A reusable panel that displays login instructions for Promptfoo CLI
 */
export const CliLoginPanel: React.FC<CliLoginPanelProps> = ({
  title = 'Welcome to Promptfoo',
  icon = <AssessmentIcon sx={{ fontSize: 60, mb: 2, color: 'primary.main' }} />,
  additionalContent,
  paperSx = {},
}) => {
  const theme = useTheme();

  // Get API key
  const apiKey = getAuthToken() || '';

  // Generate CLI login command
  const loginCommand = `promptfoo auth login --host ${API_BASE_URL} --api-key ${apiKey}`;

  // Custom scrollbar styles based on current theme
  const scrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor:
          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.3)',
      },
    },
    // Firefox support
    scrollbarWidth: 'thin',
    scrollbarColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.05)',
  };

  // Command box styles with scrollbar customization
  const commandBoxStyles = {
    overflow: 'auto',
    whiteSpace: 'nowrap',
    ...scrollbarStyles,
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <Paper
        elevation={3}
        sx={{
          p: 4,
          textAlign: 'center',
          maxWidth: 1000,
          bgcolor: 'background.paper',
          ...paperSx,
        }}
        role="region"
        aria-label="CLI Login Instructions"
      >
        {icon}
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Typography variant="body1" color="text.secondary">
            To get started, log in with Promptfoo on the command line:
          </Typography>
          <CommandBox command={loginCommand} sx={commandBoxStyles} />
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" color="text.secondary">
            Then share your latest eval:
          </Typography>
          <Box
            component="code"
            sx={{
              display: 'block',
              mt: 1,
              p: 2,
              bgcolor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
              borderRadius: 1,
              color: 'text.primary',
            }}
            aria-label="Share command"
          >
            promptfoo share
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="body1" color="text.secondary">
              Your API Key:
            </Typography>
            <CommandBox command={apiKey} sx={commandBoxStyles} />
          </Box>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" color="text.secondary">
            For more details, see{' '}
            <a
              href="https://www.promptfoo.dev/docs/cloud/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Open Cloud Documentation in a new tab"
            >
              Cloud Documentation
            </a>
            .
          </Typography>
        </Box>

        {additionalContent && <Box sx={{ mt: 4 }}>{additionalContent}</Box>}
      </Paper>
    </Box>
  );
};

export default CliLoginPanel;
