import { useEffect } from 'react';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import type { ApplicationDescription } from '@shared/dto/redteamConfigs';

interface ApplicationDescriptionProps {
  data: ApplicationDescription;
  onUpdate: (data: ApplicationDescription) => void;
  setError: (error: string | null) => void;
  canEdit: boolean;
}

export default function ApplicationDescriptionForm({
  data,
  onUpdate,
  setError,
  canEdit,
}: ApplicationDescriptionProps) {
  useEffect(() => {
    if (data.purpose) {
      setError(null);
    } else {
      setError('Please provide a purpose for the application');
    }
  }, [data.purpose, setError]);

  return (
    <Stack direction="column" spacing={4}>
      <Box>
        <Stack direction="column" spacing={3}>
          <Box>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium' }}>
              Purpose*
            </Typography>
            <Typography variant="body1">
              The primary objective of the application and any rules it should follow.
            </Typography>
            <TextField
              fullWidth
              value={data.purpose}
              onChange={(e) => onUpdate({ ...data, purpose: e.target.value })}
              placeholder="e.g. This application is a travel agent specialized in budget trips to Europe. It should only provide information about budget trips to Europe."
              required
              error={!data.purpose}
              helperText={!data.purpose && 'This field is required'}
              margin="normal"
              multiline
              rows={4}
              disabled={!canEdit}
            />
          </Box>

          <Alert
            severity="info"
            sx={{
              '& .MuiAlert-icon': {
                color: 'info.main',
              },
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark'
                  ? alpha(theme.palette.info.main, 0.1)
                  : alpha(theme.palette.info.main, 0.05),
              border: (theme) =>
                `1px solid ${
                  theme.palette.mode === 'dark'
                    ? alpha(theme.palette.info.main, 0.3)
                    : alpha(theme.palette.info.main, 0.2)
                }`,
              '& .MuiAlert-message': {
                color: 'text.primary',
              },
            }}
          >
            The more information you provide, the better the redteam attacks and grading will be.
          </Alert>

          <Box>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium' }}>
              Describe the user the redteamer is impersonating
            </Typography>
            <TextField
              fullWidth
              value={data.redteamUser}
              onChange={(e) => onUpdate({ ...data, redteamUser: e.target.value })}
              placeholder="e.g. A traveler looking for budget flights to Europe. An employee of the company."
              disabled={!canEdit}
            />
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}
