import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import EvalOutputPromptDialog from '@cloud-ui/pages/eval/components/EvalOutputPromptDialog';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';
import type { EvalResultDTO } from '@shared/dto';
import TestCaseDownloadButton from './TestCaseDownloadButton';

interface TestCasesTabProps {
  evalResults: EvalResultDTO[];
  isEvalResultsLoading: boolean;
  isEvalResultsError: boolean;
  issueId?: string;
}

export default function TestCasesTab({
  evalResults,
  isEvalResultsLoading,
  isEvalResultsError,
  issueId,
}: TestCasesTabProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedResult, setSelectedResult] = useState<any | null>(null);

  const handleOpenDialog = (result: any) => {
    setSelectedResult(result);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedResult(null);
  };

  const formatContent = (content: any): string => {
    if (typeof content === 'object') {
      return JSON.stringify(content, null, 2);
    }
    if (typeof content === 'string') {
      try {
        // Attempt to parse and re-stringify if it's a JSON string
        return JSON.stringify(JSON.parse(content), null, 2);
      } catch {
        // If it's not valid JSON, return the original string
        return content;
      }
    }
    return String(content);
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };

  return (
    <ErrorBoundary fallbackRender={({ error }) => <Typography>{error.message}</Typography>}>
      <Box sx={{ p: 3, height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">{evalResults?.length || 0} records</Typography>
          {issueId && evalResults?.length > 0 && (
            <TestCaseDownloadButton evalResults={evalResults} issueId={issueId} />
          )}
        </Box>

        {isEvalResultsLoading ? (
          <Typography>Loading evaluation results...</Typography>
        ) : isEvalResultsError ? (
          <Typography color="error">Error loading evaluation results</Typography>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              width: '100%',
              backgroundColor: 'background.paper',
              borderRadius: 1,
              height: 'calc(100% - 40px)', // Account for header
              '& .MuiTableCell-root': {
                maxWidth: {
                  xs: '150px',
                  sm: '200px',
                  md: '250px',
                },
              },
            }}
          >
            <Table sx={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  <TableCell width="120px">Date</TableCell>
                  <TableCell width="300px">Attack</TableCell>
                  <TableCell width="300px">Result</TableCell>
                  <TableCell width="100px">Strategy</TableCell>
                  <TableCell width="100px">Report</TableCell>
                  <TableCell width="50px">Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {evalResults?.map((result) => (
                  <TableRow key={result.id}>
                    <TableCell
                      sx={{
                        verticalAlign: 'top',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                      }}
                    >
                      {new Date(result.createdAt).toLocaleString()}
                    </TableCell>
                    <TableCell
                      sx={{
                        verticalAlign: 'top',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        maxWidth: '250px',
                        p: 1,
                      }}
                    >
                      {Object.entries(result.testCase.vars || {}).map(([key, value]) => (
                        <Box
                          key={key}
                          sx={{
                            mb: 1,
                            overflowWrap: 'break-word',
                          }}
                        >
                          <Tooltip
                            title={
                              <Box sx={{ p: 1, maxWidth: 400 }}>
                                <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                                  {key}: {formatContent(value)}
                                </pre>
                              </Box>
                            }
                          >
                            <Box>
                              <Typography
                                variant="body2"
                                component="span"
                                sx={{
                                  fontWeight: 'bold',
                                  display: 'block',
                                }}
                              >
                                {key}:
                              </Typography>
                              <Typography
                                variant="body2"
                                component="span"
                                sx={{
                                  display: 'block',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {truncateText(value as string, 100)}
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                      ))}
                    </TableCell>
                    <TableCell
                      sx={{
                        verticalAlign: 'top',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                      }}
                    >
                      <Tooltip
                        title={
                          <Box sx={{ p: 1, maxWidth: 400 }}>
                            <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                              {formatContent(result.response?.output)}
                            </pre>
                          </Box>
                        }
                      >
                        <Typography variant="body2">
                          {truncateText(formatContent(result.response?.output), 200)}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      sx={{
                        verticalAlign: 'top',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                      }}
                    >
                      {result.testCase.metadata?.strategyId || 'Basic'}
                    </TableCell>
                    <TableCell
                      sx={{
                        verticalAlign: 'top',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                      }}
                    >
                      <RouterLink to={`${ROUTES.redteam.report}/${result.evalId}`}>
                        {result.evalId}
                      </RouterLink>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <SearchIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleOpenDialog(result)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <EvalOutputPromptDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          prompt={selectedResult?.prompt.raw || ''}
          output={
            typeof selectedResult?.response.output === 'object'
              ? JSON.stringify(selectedResult?.response.output, null, 2)
              : selectedResult?.response.output || ''
          }
          gradingResults={selectedResult?.gradingResult?.componentResults || []}
          metadata={selectedResult?.metadata || {}}
        />
      </Box>
    </ErrorBoundary>
  );
}
