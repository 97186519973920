import React from 'react';
import { Paper, List, useTheme } from '@mui/material';
import type { IssueDTO } from '@shared/dto';
import IssueItem from './IssueItem';

interface IssueListProps {
  issues: IssueDTO[];
  isCompact: boolean;
  selectedIssue: IssueDTO | null;
}

export default function IssueList({ issues, isCompact, selectedIssue }: IssueListProps) {
  const theme = useTheme();

  return (
    <Paper
      elevation={2}
      sx={{
        bgcolor: theme.palette.background.paper,
        transition: theme.transitions.create(['background-color', 'box-shadow'], {
          duration: theme.transitions.duration.standard,
        }),
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <List
        sx={{
          overflow: 'auto',
          height: '100%',
          padding: 0,
        }}
      >
        {issues.map((issue) => (
          <IssueItem
            key={issue.id}
            issue={issue}
            isCompact={isCompact}
            isSelected={selectedIssue?.id === issue.id}
          />
        ))}
      </List>
    </Paper>
  );
}
