import { useQuery } from '@tanstack/react-query';
import { useMemo, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import DataGrid, { type GridColDef } from '@cloud-ui/components/DataGrid';
import { ROUTES } from '@cloud-ui/constants';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { listJobs } from '@cloud-ui/utils/api/jobs';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import type { EvalJobDTO } from '@shared/dto';
import { formatDistanceToNow, parseISO } from 'date-fns';
import JobDetails from './components/JobDetails';
import JobStatusBadge from './components/JobStatusBadge';

export default function Jobs() {
  const { id: selectedJobId } = useParams();
  const { currentTeam } = useTeamsContext();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const {
    data: jobs = [],
    isLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ['jobs', currentTeam?.id],
    queryFn: () => listJobs(currentTeam?.id ?? ''),
    staleTime: 0,
  });

  useEffect(() => {
    if (isError) {
      showToast(error instanceof Error ? error.message : 'An unknown error occurred', 'error');
    }
  }, [isError, error]);

  const handleCellClick = (params: any) => {
    if (params.field !== 'evalId') {
      navigate(`${ROUTES.jobs}/${params.row.id}`);
    }
  };

  const columns: GridColDef<EvalJobDTO>[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        flex: 1,
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        flex: 1,
        valueFormatter: (value) =>
          formatDistanceToNow(parseISO(value as unknown as string), {
            addSuffix: true,
          }),
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => <JobStatusBadge status={params.value} />,
        cellClassName: 'dg-cursor-pointer',
      },
      {
        field: 'evalId',
        headerName: 'Eval ID',
        flex: 1,
        renderCell: (params) => {
          const value = params.value;
          return value ? (
            <Link to={`${ROUTES.eval}/${value}`} onClick={(e) => e.stopPropagation()}>
              {value}
            </Link>
          ) : (
            '-'
          );
        },
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        valueGetter: (value, params) => params.config?.description || 'No description',
        cellClassName: 'dg-cursor-pointer',
      },
    ],
    [],
  );

  return (
    <Box maxWidth="100%" mx="auto" p={3}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Red Team Scans
        </Typography>

        {selectedJobId && (
          <Link to={`${ROUTES.jobs}`}>
            <Button startIcon={<CloseIcon />}>Close</Button>
          </Link>
        )}
      </div>

      <div
        style={{
          display: 'grid',
          gap: '1rem',
          gridTemplateColumns: selectedJobId ? '6fr 6fr' : '1fr',
        }}
      >
        <Card>
          <DataGrid<EvalJobDTO>
            columns={columns}
            rows={jobs}
            isLoading={isLoading}
            onCellClick={handleCellClick}
          />
        </Card>
        {selectedJobId && <JobDetails jobId={selectedJobId} />}
      </div>
    </Box>
  );
}
