import { useQuery } from '@tanstack/react-query';
import { getUserTeamAbilities } from '@cloud-ui/utils/api/users';

export const useUserTeamAbilities = (teamId?: string) => {
  return useQuery({
    queryKey: ['users', 'me', 'teams', teamId, 'abilities'],
    queryFn: () => (teamId ? getUserTeamAbilities(teamId) : null),
    enabled: !!teamId,
    staleTime: 30000, // 30 seconds
  });
};
