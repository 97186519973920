import { useState, useMemo, useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTelemetry } from '@app/hooks/useTelemetry';
import ErrorFallback from '@cloud-ui/components/ErrorFallback';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  HARM_PLUGINS,
  DEFAULT_PLUGINS,
  ALL_PLUGINS,
  NIST_AI_RMF_MAPPING,
  OWASP_LLM_TOP_10_MAPPING,
  OWASP_API_TOP_10_MAPPING,
  MITRE_ATLAS_MAPPING,
  displayNameOverrides,
  subCategoryDescriptions,
  categoryAliases,
  type Plugin,
  riskCategories,
  PLUGIN_PRESET_DESCRIPTIONS,
  FOUNDATION_PLUGINS,
} from '@promptfoo/redteam/constants';
import type { RedteamPlugin } from '@promptfoo/redteam/types';
import type { LocalPluginConfig } from '../../configs/edit/types';
import CustomIntentSection from './CustomIntentPluginSection';
import { CustomPoliciesSection } from './CustomPoliciesSection';
import PluginCard, { PLUGINS_REQUIRING_CONFIG } from './PluginCard';
import PluginConfigDialog from './PluginConfigDialog';
import PresetCard from './PresetCard';

interface PluginsProps {
  onNext?: () => void;
  onBack?: () => void;
  onChange?: (plugins: RedteamPlugin[]) => void;
  value?: RedteamPlugin[];
  onBlur?: () => void;
  error?: string | string[] | false;
  showCustomSections?: boolean;
  showHeader?: boolean;
}

function findPolicyPlugins(plugins: RedteamPlugin[]) {
  return plugins.filter((p) => (typeof p === 'object' ? p.id === 'policy' : p === 'policy'));
}

function findIntentPlugin(plugins: RedteamPlugin[]) {
  return plugins.find(
    (p): p is { id: string; config: any } =>
      typeof p === 'object' && p.id === 'intent' && 'config' in p,
  );
}

export default function Plugins({
  onNext,
  onBack,
  onChange,
  value = [],
  onBlur,
  error,
  showCustomSections = true,
  showHeader = true,
}: PluginsProps) {
  const { recordEvent } = useTelemetry();
  const [isCustomMode, setIsCustomMode] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [configDialogOpen, setConfigDialogOpen] = useState(false);
  const [selectedConfigPlugin, setSelectedConfigPlugin] = useState<Plugin | null>(null);
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(new Set());

  const updateIntents = useCallback(
    (intents: string[]) => {
      const intentPlugin = findIntentPlugin(value);
      if (intentPlugin) {
        if (typeof intentPlugin === 'string') {
          onChange?.([
            ...value.filter((p) => typeof p === 'string' || p.id !== 'intent'),
            { id: 'intent', config: { intent: intents } },
          ]);
        } else {
          const newPlugin = { ...intentPlugin, config: { intent: intents } };
          onChange?.([
            ...value.filter((p) => typeof p === 'string' || p.id !== 'intent'),
            newPlugin,
          ]);
        }
      } else {
        onChange?.([...value, { id: 'intent', config: { intent: intents } }]);
      }
    },
    [value],
  );

  const updatePolicy = useCallback(
    (policies: string[]) => {
      const policyPlugins = findPolicyPlugins(value);
      if (policyPlugins.length === 0) {
        onChange?.([...value, ...policies.map((policy) => ({ id: 'policy', config: { policy } }))]);
      } else {
        onChange?.([
          ...value.filter((p) => (typeof p === 'string' ? p !== 'policy' : p.id !== 'policy')),
          ...policies.map((policy) => ({ id: 'policy', config: { policy } })),
        ]);
      }
    },
    [value],
  );

  // Get the current set of selected plugins from the value prop
  const selectedPlugins = useMemo(() => {
    return new Set(
      value.map((plugin) => (typeof plugin === 'string' ? plugin : plugin.id)) as Plugin[],
    );
  }, [value]);

  // Get the current plugin configs from the value prop
  const pluginConfig = useMemo(() => {
    const configs: LocalPluginConfig = {};
    value.forEach((plugin) => {
      if (typeof plugin === 'object' && plugin.config) {
        configs[plugin.id] = plugin.config;
      }
    });
    return configs;
  }, [value]);

  const handlePluginToggle = useCallback(
    (plugin: Plugin) => {
      if (!onChange) {
        return;
      }

      const newPlugins = new Set(selectedPlugins);
      if (newPlugins.has(plugin)) {
        newPlugins.delete(plugin);
      } else {
        newPlugins.add(plugin);
        if (PLUGINS_REQUIRING_CONFIG.includes(plugin)) {
          setSelectedConfigPlugin(plugin);
          setConfigDialogOpen(true);
        }
      }

      // Create plugin objects and call onChange
      const plugins = Array.from(newPlugins).map((p): RedteamPlugin => {
        const config = pluginConfig[p];
        if (config && Object.keys(config).length > 0) {
          return { id: p, config };
        }
        return p;
      });

      onChange(plugins);
    },
    [onChange, selectedPlugins, pluginConfig],
  );

  const handlePresetSelect = useCallback(
    (preset: { name: string; plugins: Set<Plugin> | ReadonlySet<Plugin> }) => {
      recordEvent('feature_used', {
        feature: 'redteam_config_plugins_preset_selected',
        preset: preset.name,
      });

      if (preset.name === 'Custom') {
        setIsCustomMode(true);
      } else {
        setIsCustomMode(false);
        if (onChange) {
          onChange(Array.from(preset.plugins));
        }
      }
    },
    [onChange, recordEvent],
  );

  const updatePluginConfig = useCallback(
    (plugin: string, newConfig: any) => {
      if (!onChange) {
        return;
      }

      const newPlugins = value.map((p): RedteamPlugin => {
        if (typeof p === 'string') {
          if (p === plugin) {
            return { id: p, config: newConfig };
          }
          return p;
        }
        if (p.id === plugin) {
          return { ...p, config: newConfig };
        }
        return p;
      });

      onChange(newPlugins);
    },
    [onChange, value],
  );

  useEffect(() => {
    recordEvent('webui_page_view', { page: 'redteam_config_plugins' });
  }, []);

  const filteredPlugins = useMemo(() => {
    if (!searchTerm) {
      return ALL_PLUGINS;
    }
    return ALL_PLUGINS.filter((plugin) => {
      const lowerSearchTerm = searchTerm.toLowerCase();
      return (
        plugin.toLowerCase().includes(lowerSearchTerm) ||
        HARM_PLUGINS[plugin as keyof typeof HARM_PLUGINS]
          ?.toLowerCase()
          .includes(lowerSearchTerm) ||
        displayNameOverrides[plugin as keyof typeof displayNameOverrides]
          ?.toLowerCase()
          .includes(lowerSearchTerm) ||
        categoryAliases[plugin as keyof typeof categoryAliases]
          ?.toLowerCase()
          .includes(lowerSearchTerm) ||
        subCategoryDescriptions[plugin]?.toLowerCase().includes(lowerSearchTerm)
      );
    });
  }, [searchTerm]);

  const presets: { name: string; plugins: Set<Plugin> | ReadonlySet<Plugin> }[] = [
    { name: 'Recommended', plugins: DEFAULT_PLUGINS },
    { name: 'Minimal Test', plugins: new Set(['harmful:hate', 'harmful:self-harm']) },
    {
      name: 'RAG',
      plugins: new Set([...DEFAULT_PLUGINS, 'bola', 'bfla', 'rbac']),
    },
    {
      name: 'Foundation',
      plugins: new Set(Object.values(FOUNDATION_PLUGINS)),
    },
    {
      name: 'NIST',
      plugins: new Set(Object.values(NIST_AI_RMF_MAPPING).flatMap((v) => v.plugins)),
    },
    {
      name: 'OWASP LLM',
      plugins: new Set(Object.values(OWASP_LLM_TOP_10_MAPPING).flatMap((v) => v.plugins)),
    },
    {
      name: 'OWASP API',
      plugins: new Set(Object.values(OWASP_API_TOP_10_MAPPING).flatMap((v) => v.plugins)),
    },
    {
      name: 'MITRE',
      plugins: new Set(Object.values(MITRE_ATLAS_MAPPING).flatMap((v) => v.plugins)),
    },
  ];

  const isConfigValid = useCallback(() => {
    for (const plugin of selectedPlugins) {
      if (PLUGINS_REQUIRING_CONFIG.includes(plugin)) {
        const config = pluginConfig[plugin];
        if (!config || Object.keys(config).length === 0) {
          return false;
        }
        for (const key in config) {
          const value = config[key];
          if (Array.isArray(value) && value.length === 0) {
            return false;
          }
          if (typeof value === 'string' && value.trim() === '') {
            return false;
          }
        }
      }
    }
    return true;
  }, [selectedPlugins, pluginConfig]);

  const handleConfigClick = (plugin: Plugin) => {
    setSelectedConfigPlugin(plugin);
    setConfigDialogOpen(true);
  };

  const isPluginConfigured = (plugin: Plugin) => {
    if (!PLUGINS_REQUIRING_CONFIG.includes(plugin) || plugin === 'policy') {
      return true;
    }
    const config = pluginConfig[plugin];
    if (!config || Object.keys(config).length === 0) {
      return false;
    }

    for (const key in config) {
      const value = config[key];
      if (Array.isArray(value) && value.length === 0) {
        return false;
      }
      if (typeof value === 'string' && value.trim() === '') {
        return false;
      }
    }
    return true;
  };

  const renderPluginCategory = (category: string, plugins: readonly Plugin[]) => {
    const pluginsToShow = plugins
      .filter((plugin) => plugin !== 'intent')
      .filter((plugin) => plugin !== 'policy')
      .filter((plugin) => filteredPlugins.includes(plugin));
    if (pluginsToShow.length === 0) {
      return null;
    }

    const isExpanded = expandedCategories.has(category);
    const selectedCount = pluginsToShow.filter((plugin) => selectedPlugins.has(plugin)).length;

    return (
      <Accordion
        key={category}
        expanded={isExpanded}
        onChange={(event, expanded) => {
          setExpandedCategories((prev) => {
            const newSet = new Set(prev);
            if (expanded) {
              newSet.add(category);
            } else {
              newSet.delete(category);
            }
            return newSet;
          });
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography variant="h6" sx={{ fontWeight: 'medium', flex: 1 }}>
              {category} ({selectedCount}/{pluginsToShow.length})
            </Typography>
            {isExpanded && (
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  mr: 2,
                  '& > *': {
                    color: 'primary.main',
                    cursor: 'pointer',
                    fontSize: '0.875rem',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  },
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <Box
                  component="span"
                  onClick={() => {
                    if (onChange) {
                      const currentSelected = new Set(
                        value.map((plugin) => (typeof plugin === 'string' ? plugin : plugin.id)),
                      );
                      // Add every plugin from the current category (pluginsToShow) to the selection
                      pluginsToShow.forEach((plugin) => {
                        currentSelected.add(plugin);
                      });

                      const newPlugins = Array.from(currentSelected).map(
                        (pluginId): RedteamPlugin => {
                          const config = pluginConfig[pluginId];
                          return config && Object.keys(config).length > 0
                            ? { id: pluginId, config }
                            : pluginId;
                        },
                      );

                      onChange(newPlugins);
                    }
                  }}
                >
                  Select all
                </Box>
                <Box
                  component="span"
                  onClick={() => {
                    if (onChange) {
                      const currentSelected = new Set(
                        value.map((plugin) => (typeof plugin === 'string' ? plugin : plugin.id)),
                      );
                      // Remove every plugin from the current category (pluginsToShow) from the selection
                      pluginsToShow.forEach((plugin) => {
                        currentSelected.delete(plugin);
                      });

                      const newPlugins = Array.from(currentSelected).map(
                        (pluginId): RedteamPlugin => {
                          const config = pluginConfig[pluginId];
                          return config && Object.keys(config).length > 0
                            ? { id: pluginId, config }
                            : pluginId;
                        },
                      );

                      onChange(newPlugins);
                    }
                  }}
                >
                  Select none
                </Box>
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {pluginsToShow.map((plugin) => (
              <PluginCard
                key={plugin}
                plugin={plugin}
                selectedPlugins={selectedPlugins}
                handlePluginToggle={handlePluginToggle}
                handleConfigClick={handleConfigClick}
                isPluginConfigured={isPluginConfigured}
              />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const currentlySelectedPreset = presets.find(
    (p) =>
      Array.from(p.plugins as Set<Plugin>).every((plugin) => selectedPlugins.has(plugin)) &&
      p.plugins.size === selectedPlugins.size,
  );

  const intentValue = useMemo(() => {
    const intentPlugin = findIntentPlugin(value);

    return intentPlugin?.config?.intent || [];
  }, [value]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Box>
        {showHeader && (
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
            Plugin Configuration
          </Typography>
        )}

        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h5"
            sx={{
              mb: 3,
              fontWeight: 500,
              color: 'text.primary',
            }}
          >
            Available presets
          </Typography>

          <Box>
            <Grid
              container
              spacing={3}
              sx={{
                mb: 4,
                justifyContent: {
                  xs: 'center',
                  sm: 'flex-start',
                },
              }}
            >
              {presets.map((preset) => {
                const isSelected =
                  preset.name === 'Custom'
                    ? isCustomMode
                    : preset.name === currentlySelectedPreset?.name;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={preset.name}
                    sx={{
                      minWidth: { xs: '280px', sm: '320px' },
                      maxWidth: { xs: '100%', sm: '380px' },
                    }}
                  >
                    <PresetCard
                      name={preset.name}
                      isSelected={isSelected}
                      onClick={() => handlePresetSelect(preset)}
                      description={PLUGIN_PRESET_DESCRIPTIONS[preset.name] || ''}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>

        <TextField
          fullWidth
          variant="outlined"
          label="Filter Plugins"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          sx={{ mb: 3 }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            mb: 2,
            '& > *': {
              color: 'primary.main',
              cursor: 'pointer',
              fontSize: '0.875rem',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            },
          }}
        >
          <Box
            component="span"
            onClick={() => {
              if (onChange) {
                const currentSelected = new Set(
                  value.map((plugin) => (typeof plugin === 'string' ? plugin : plugin.id)),
                );
                // Add every plugin that matches the global filtered list
                filteredPlugins.forEach((plugin) => {
                  currentSelected.add(plugin);
                });

                const newPlugins = Array.from(currentSelected).map((pluginId): RedteamPlugin => {
                  const config = pluginConfig[pluginId];
                  return config && Object.keys(config).length > 0
                    ? { id: pluginId, config }
                    : pluginId;
                });

                onChange(newPlugins);
              }
            }}
          >
            Select all
          </Box>
          <Box
            component="span"
            onClick={() => {
              if (onChange) {
                const currentSelected = new Set(
                  value.map((plugin) => (typeof plugin === 'string' ? plugin : plugin.id)),
                );
                // Remove every plugin that matches the global filtered list
                filteredPlugins.forEach((plugin) => {
                  currentSelected.delete(plugin);
                });

                const newPlugins = Array.from(currentSelected).map((pluginId): RedteamPlugin => {
                  const config = pluginConfig[pluginId];
                  return config && Object.keys(config).length > 0
                    ? { id: pluginId, config }
                    : pluginId;
                });

                onChange(newPlugins);
              }
            }}
          >
            Select none
          </Box>
        </Box>
        <Box sx={{ mb: 3 }}>
          {Object.entries(riskCategories).map(([category, plugins]) =>
            renderPluginCategory(category, plugins),
          )}

          <Accordion
            expanded={expandedCategories.has('Custom Prompts')}
            onChange={(event, expanded) => {
              setExpandedCategories((prev) => {
                const newSet = new Set(prev);
                if (expanded) {
                  newSet.add('Custom Prompts');
                } else {
                  newSet.delete('Custom Prompts');
                }
                return newSet;
              });
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
                Custom Prompts (
                {findIntentPlugin(value)?.config?.intent?.filter((i: string) => i.trim() !== '')
                  .length || 0}
                )
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CustomIntentSection value={intentValue} onChange={updateIntents} />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expandedCategories.has('Custom Policies')}
            onChange={(event, expanded) => {
              setExpandedCategories((prev) => {
                const newSet = new Set(prev);
                if (expanded) {
                  newSet.add('Custom Policies');
                } else {
                  newSet.delete('Custom Policies');
                }
                return newSet;
              });
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
                Custom Policies ({findPolicyPlugins(value).length || 0})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CustomPoliciesSection
                policies={findPolicyPlugins(value).map((p) =>
                  typeof p === 'string' ? '' : (p.config?.policy as string),
                )}
                onChange={updatePolicy}
                readOnly={false}
              />
            </AccordionDetails>
          </Accordion>
        </Box>

        {onNext && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Button
              variant="outlined"
              onClick={onBack}
              startIcon={<KeyboardArrowLeftIcon />}
              sx={{ px: 4, py: 1 }}
            >
              Back
            </Button>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {selectedPlugins.size === 0 && (
                <Typography variant="body2" color="text.secondary">
                  Select at least one plugin to continue.
                </Typography>
              )}
              <Button
                variant="contained"
                onClick={onNext}
                endIcon={<KeyboardArrowRightIcon />}
                disabled={!isConfigValid() || selectedPlugins.size === 0}
                sx={{ px: 4, py: 1 }}
              >
                Next
              </Button>
            </Box>
          </Box>
        )}

        <PluginConfigDialog
          open={configDialogOpen}
          plugin={selectedConfigPlugin}
          config={selectedConfigPlugin ? pluginConfig[selectedConfigPlugin] || {} : {}}
          onClose={() => {
            setConfigDialogOpen(false);
            setSelectedConfigPlugin(null);
          }}
          onSave={(plugin, newConfig) => {
            updatePluginConfig(plugin, newConfig);
          }}
        />
      </Box>
    </ErrorBoundary>
  );
}
