import type { RoleDTO, CreateRoleRequest, UpdateRoleRequest } from '@shared/dto/rbac';
import { callApi } from '../api';

export const fetchRoles = async (organizationId: string): Promise<RoleDTO[]> => {
  const response = await callApi(`/organizations/${organizationId}/roles`);
  return await response.json();
};

export const getRole = async (organizationId: string, roleId: string): Promise<RoleDTO> => {
  const response = await callApi(`/organizations/${organizationId}/roles/${roleId}`);
  return await response.json();
};

export const createRole = async (
  organizationId: string,
  data: CreateRoleRequest,
): Promise<RoleDTO> => {
  const response = await callApi(`/organizations/${organizationId}/roles`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return await response.json();
};

export const updateRole = async (
  organizationId: string,
  roleId: string,
  data: UpdateRoleRequest,
): Promise<RoleDTO> => {
  const response = await callApi(`/organizations/${organizationId}/roles/${roleId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
  return await response.json();
};

export const deleteRole = async (organizationId: string, roleId: string): Promise<void> => {
  await callApi(`/organizations/${organizationId}/roles/${roleId}`, {
    method: 'DELETE',
  });
};
