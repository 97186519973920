import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '@cloud-ui/contexts/AuthContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import {
  useOrganizationServiceAccounts,
  useOrganizationUsers,
  useSetUserAdminStatus,
} from '@cloud-ui/hooks/useOrganization';
import { callApi } from '@cloud-ui/utils/api';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyIcon from '@mui/icons-material/Key';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Alert,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Popover,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import type { OrganizationDTO } from '@shared/dto/organizations';

// Define a Team type for use in this component
type Team = {
  id: string;
  name: string;
};

interface ServiceAccount {
  id: string;
  name: string;
  createdAt: string;
  teams?: Team[];
}

interface ServiceAccountWithToken extends ServiceAccount {
  token: string;
}

interface InviteUserDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (e: React.FormEvent) => void;
  name: string;
  email: string;
  onNameChange: (value: string) => void;
  onEmailChange: (value: string) => void;
  error: string | null;
}

const InviteUserDialog = ({
  open,
  onClose,
  onSubmit,
  name,
  email,
  onNameChange,
  onEmailChange,
  error,
}: InviteUserDialogProps) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>Invite New User</DialogTitle>
    <form onSubmit={onSubmit}>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => onNameChange(e.target.value)}
            required
            fullWidth
            autoFocus
          />
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => onEmailChange(e.target.value)}
            required
            fullWidth
          />
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" variant="contained" disabled={!name.trim() || !email.trim()}>
          Invite
        </Button>
      </DialogActions>
    </form>
  </Dialog>
);

interface TeamMembershipProps {
  teams: Team[];
}

const TeamMembership: React.FC<TeamMembershipProps> = ({ teams }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!teams || teams.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary">
        -
      </Typography>
    );
  }

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        cursor: 'pointer',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
      }}
    >
      <Typography
        variant="body2"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
          display: 'block',
        }}
      >
        {teams.map((team) => team.name).join(', ')}
      </Typography>
      <Popover
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleMouseLeave}
        disableRestoreFocus
      >
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'medium' }}>
            Member of:
          </Typography>
          <Box component="ul" sx={{ m: 0, pl: 2, pt: 1 }}>
            {teams.map((team) => (
              <Box component="li" key={team.id} sx={{ mb: 0.5 }}>
                <Typography variant="body2">{team.name}</Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default function OrganizationUsers() {
  const { orgId } = useParams<{ orgId: string }>();
  const queryClient = useQueryClient();
  const {
    data: users = [],
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useOrganizationUsers(orgId || '');
  const setUserAdminStatus = useSetUserAdminStatus(orgId || '');

  const [organization, setOrganization] = useState<OrganizationDTO | null>(null);
  const { data: serviceAccounts, refetch: refetchServiceAccounts } = useOrganizationServiceAccounts(
    orgId || '',
  );
  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [userError, setUserError] = useState<string | null>(null);
  const [showNewTokenDialog, setShowNewTokenDialog] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [newServiceAccountName, setNewServiceAccountName] = useState(
    `Service Account ${new Date().toISOString().split('T')[0]}`,
  );
  const [newServiceAccount, setNewServiceAccount] = useState<ServiceAccountWithToken | null>(null);
  const [showCopiedSnackbar, setShowCopiedSnackbar] = useState(false);
  const { showToast } = useToast();
  const { user: loggedInUser } = useAuth();
  const fetchOrganizationDetails = async () => {
    try {
      const response = await callApi(`/organizations/${orgId}`);
      const data = await response.json();
      setOrganization(data);
    } catch (error) {
      console.error('Error fetching organization details:', error);
    }
  };

  useEffect(() => {
    fetchOrganizationDetails();
  }, [orgId]);

  const removeUser = async (userId: string) => {
    const confirmed = window.confirm('Are you sure you want to remove this user?');
    if (!confirmed) {
      return;
    }
    try {
      await callApi(`/organizations/${orgId}/users/${userId}`, {
        method: 'DELETE',
      });
      refetchUsers();

      if (organization?.idpEnabled) {
        showToast(
          'User is removed from Promptfoo but make sure to remove this user from your identity provider or they will be able to log back in.',
          'warning',
        );
      }
    } catch (error) {
      console.error('Error removing user:', error);
    }
  };

  const removeServiceAccount = async (accountId: string) => {
    try {
      await callApi(`/organizations/${orgId}/service-accounts/${accountId}`, {
        method: 'DELETE',
      });

      refetchServiceAccounts();
      queryClient.invalidateQueries({ queryKey: ['organization', 'service-accounts'] });
    } catch (error) {
      console.error('Error removing service account:', error);
    }
  };

  const addUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setUserError(null);
    try {
      const result = await callApi(`/organizations/${orgId}/users`, {
        method: 'POST',
        body: JSON.stringify({ name: newUserName, email: newUserEmail }),
      });
      if (result.status > 299) {
        throw new Error('Failed to add user. Please try again.');
      }
      setNewUserName('');
      setNewUserEmail('');
      setShowInviteDialog(false);
      refetchUsers();
    } catch (error) {
      console.error('Error adding user:', error);
      setUserError('Failed to add user. Please try again.');
    }
  };

  const createServiceAccount = async () => {
    try {
      const response = await callApi(`/organizations/${orgId}/service-accounts`, {
        method: 'POST',
        body: JSON.stringify({ name: newServiceAccountName }),
      });
      const data = await response.json();
      setNewServiceAccount(data);
      setShowCreateDialog(false);
      setShowNewTokenDialog(true);
      setNewServiceAccountName('');
      refetchServiceAccounts();
    } catch (error) {
      console.error('Error creating service account:', error);
    }
  };

  const copyToken = () => {
    if (newServiceAccount?.token) {
      navigator.clipboard.writeText(newServiceAccount.token);
      setShowCopiedSnackbar(true);
    }
  };

  const handleAdminToggle = (userId: string, currentStatus: boolean) => {
    setUserAdminStatus.mutate({ userId, isAdmin: !currentStatus });
  };

  if (!organization) {
    return;
  }

  return (
    <Box maxWidth="100%" mx="auto" p={2}>
      <Typography variant="h4">Users</Typography>
      <Card sx={{ mb: 4, mt: 2 }}>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Users </Typography>

          {organization.idpEnabled ? (
            <Tooltip
              sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}
              title="Add or remove user access in your identity provider."
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}>
                <Typography variant="body2">
                  User access is managed in your identity provider
                </Typography>
                <InfoOutlinedIcon />
              </Box>
            </Tooltip>
          ) : (
            <Button
              variant="contained"
              startIcon={<PersonAddIcon />}
              onClick={() => setShowInviteDialog(true)}
            >
              Invite User
            </Button>
          )}
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Teams</TableCell>
              <TableCell>System Administrator</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingUsers ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography align="center">Loading...</Typography>
                </TableCell>
              </TableRow>
            ) : users.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography align="center">No users yet</Typography>
                </TableCell>
              </TableRow>
            ) : (
              users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell sx={{ maxWidth: 300, width: 300 }}>
                    <TeamMembership teams={user.teams || []} />
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={user.isAdmin ? 'Remove admin privileges' : 'Grant admin privileges'}
                    >
                      <Switch
                        checked={!!user.isAdmin}
                        onChange={() => handleAdminToggle(user.id, !!user.isAdmin)}
                        disabled={users.length === 1 || user.id === loggedInUser?.id}
                        color="primary"
                        icon={<AdminPanelSettingsIcon fontSize="small" />}
                        checkedIcon={<AdminPanelSettingsIcon fontSize="small" color="primary" />}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeUser(user.id)}
                      disabled={users.length === 1 || user.id === loggedInUser?.id}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Card>

      <Card>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Service Accounts</Typography>
          <Button
            variant="contained"
            startIcon={<KeyIcon />}
            onClick={() => setShowCreateDialog(true)}
          >
            Create Service Account
          </Button>
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Teams</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>System Administrator</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceAccounts?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography align="center">No service accounts yet</Typography>
                </TableCell>
              </TableRow>
            ) : (
              serviceAccounts?.map((account) => (
                <TableRow key={account.id}>
                  <TableCell>{account.name}</TableCell>
                  <TableCell sx={{ maxWidth: 300, width: 300 }}>
                    <TeamMembership teams={account.teams || []} />
                  </TableCell>
                  <TableCell>{new Date(account.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <Tooltip
                      title={account.isAdmin ? 'Remove admin privileges' : 'Grant admin privileges'}
                    >
                      <Switch
                        checked={!!account.isAdmin}
                        onChange={() => handleAdminToggle(account.id, !!account.isAdmin)}
                        color="primary"
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeServiceAccount(account.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Card>

      <InviteUserDialog
        open={showInviteDialog}
        onClose={() => {
          setShowInviteDialog(false);
          setUserError(null);
          setNewUserName('');
          setNewUserEmail('');
        }}
        onSubmit={addUser}
        name={newUserName}
        email={newUserEmail}
        onNameChange={setNewUserName}
        onEmailChange={setNewUserEmail}
        error={userError}
      />

      <Dialog
        open={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create Service Account</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Service accounts are used for API access and automation. Choose a descriptive name to
            help identify its purpose.
          </Typography>
          <TextField
            label="Service Account Name"
            value={newServiceAccountName}
            onChange={(e) => setNewServiceAccountName(e.target.value)}
            fullWidth
            required
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCreateDialog(false)}>Cancel</Button>
          <Button
            onClick={createServiceAccount}
            variant="contained"
            disabled={!newServiceAccountName.trim()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showNewTokenDialog}
        onClose={() => setShowNewTokenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>New Service Account Created</DialogTitle>
        <DialogContent>
          <Alert severity="warning" sx={{ mb: 2 }}>
            Make sure to copy your API key now. You won't be able to see it again!
          </Alert>
          <Typography variant="subtitle1" gutterBottom>
            Service Account Name:
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <Paper
              sx={{
                p: 2,
                bgcolor: 'grey.100',
                wordBreak: 'break-all',
                fontFamily: 'monospace',
              }}
            >
              {newServiceAccount?.name}
            </Paper>
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            API Key:
          </Typography>
          <Paper
            sx={{
              p: 2,
              bgcolor: 'grey.100',
              wordBreak: 'break-all',
              fontFamily: 'monospace',
              cursor: 'pointer',
            }}
            onClick={copyToken}
          >
            {newServiceAccount?.token}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={copyToken} startIcon={<KeyIcon />}>
            Copy API Key
          </Button>
          <Button onClick={() => setShowNewTokenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showCopiedSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowCopiedSnackbar(false)}
        message="API key copied to clipboard"
      />
    </Box>
  );
}
