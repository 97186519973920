import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useAuth } from '@cloud-ui/contexts/AuthContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { useTeams, useCreateTeam, useDeleteTeam } from '@cloud-ui/hooks/useTeams';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Group as GroupIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import type { TeamDTO } from '@shared/dto/teams';
import { format } from 'date-fns';

// Move dialog component outside main component
interface CreateTeamDialogProps {
  open: boolean;
  onClose: () => void;
  teamName: string;
  onTeamNameChange: (value: string) => void;
  onCreateTeam: () => void;
}

const CreateTeamDialog = ({
  open,
  onClose,
  teamName,
  onTeamNameChange,
  onCreateTeam,
}: CreateTeamDialogProps) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>Create New Team</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Team Name"
        fullWidth
        value={teamName}
        onChange={(e) => onTeamNameChange(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onCreateTeam} variant="contained" disabled={!teamName.trim()}>
        Create
      </Button>
    </DialogActions>
  </Dialog>
);

export default function TeamsList() {
  const navigate = useNavigate();
  const toast = useToast();
  const { orgId } = useParams<{ orgId: string }>();
  const { data: teams, isLoading } = useTeams();
  const { mutate: createTeam } = useCreateTeam();
  const { mutate: deleteTeam } = useDeleteTeam();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [newTeamName, setNewTeamName] = useState('');
  const { organization } = useAuth();

  const handleCreateTeam = () => {
    createTeam(newTeamName, {
      onSuccess: () => {
        setIsCreateDialogOpen(false);
        setNewTeamName('');
        toast.showToast('Team created successfully.', 'success');
      },
      onError: () => {
        toast.showToast('Failed to create team.', 'error');
      },
    });
  };

  const handleDeleteTeam = (teamId: string) => {
    // Don't allow deletion of the only team
    const isOnlyTeam = teams && teams.length === 1;
    if (isOnlyTeam) {
      toast.showToast('You cannot delete the only team in your organization.', 'error');
      return;
    }
    if (window.confirm('Are you sure you want to delete this team?')) {
      deleteTeam(teamId, {
        onSuccess: () => {
          toast.showToast('Team deleted successfully.', 'success');
        },
        onError: () => {
          toast.showToast('Failed to delete team.', 'error');
        },
      });
    }
  };

  const navigateToTeam = (teamId: string) => {
    navigate(`${ROUTES.organization.index}/${orgId}/teams/${teamId}`);
  };

  return (
    <Box maxWidth="100%" mx="auto" p={3}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <Typography variant="h4">Teams</Typography>
        <Tooltip
          title="Teams are only available for enterprise customers."
          open={!organization?.canUseRedteam}
        >
          <span>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setIsCreateDialogOpen(true)}
              disabled={!organization?.canUseRedteam}
            >
              New Team
            </Button>
          </span>
        </Tooltip>
      </div>

      <CreateTeamDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        teamName={newTeamName}
        onTeamNameChange={(value) => setNewTeamName(value)}
        onCreateTeam={handleCreateTeam}
      />

      <Card>
        {!isLoading && (!teams || teams.length === 0) ? (
          <Box p={4} textAlign="center">
            <GroupIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
            <Typography variant="h6" color="text.secondary" gutterBottom>
              No Teams Yet
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={3}>
              Create your first team to get started
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setIsCreateDialogOpen(true)}
            >
              Create New Team
            </Button>
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Members</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teams?.map((team: TeamDTO) => {
                return (
                  <TableRow
                    key={team.id}
                    hover
                    sx={{
                      cursor: 'pointer',
                      '& td:not(:last-child)': {},
                    }}
                  >
                    <TableCell onClick={() => navigateToTeam(team.id)}>{team.name}</TableCell>
                    <TableCell onClick={() => navigateToTeam(team.id)}>
                      {team.members?.length || 0} members
                    </TableCell>
                    <TableCell onClick={() => navigateToTeam(team.id)}>
                      {format(new Date(team.createdAt), 'MMM d, yyyy')}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Edit team" placement="top">
                        <IconButton onClick={() => navigateToTeam(team.id)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={teams.length === 1 ? "Can't delete the only team" : 'Delete team'}
                        placement="top"
                      >
                        <span>
                          <IconButton
                            onClick={() => handleDeleteTeam(team.id)}
                            disabled={teams.length === 1}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Card>
    </Box>
  );
}
