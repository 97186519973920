import { Severity } from '@promptfoo/redteam/constants';
import { CompletedPromptSchema } from '@promptfoo/types';
import { z } from 'zod';

export const DashboardIssueSchema = z.object({
  severity: z.nativeEnum(Severity),
  count: z.number(),
});

export const DashboardIssuesSchema = z.array(DashboardIssueSchema);

export const DashboardTargetSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const DashboardTargetsSchema = z.array(DashboardTargetSchema);

export const DashboardQueryParamsSchema = z.object({
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  tag: z.string().optional(),
  targetId: z.string().optional(),
  limit: z.coerce.number().optional().default(100),
  teamId: z.string().optional(),
});

export const DashboardTargetsDTOSchema = z.object({
  targets: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      description: z.string().optional(),
      createdAt: z.date(),
      updatedAt: z.date(),
      lastEvalAt: z.date().optional(),
      lastIssueAt: z.date().optional(),
      issueCount: z.number(),
      evalCount: z.number(),
    }),
  ),
});

export const IssuesOverTimeDataPointDTOSchema = z.object({
  date: z.string(),
  data: z.object({
    [Severity.Critical]: z.number(),
    [Severity.High]: z.number(),
    [Severity.Medium]: z.number(),
    [Severity.Low]: z.number(),
  }),
});

export const IssuesOverTimeDTOSchema = z.array(IssuesOverTimeDataPointDTOSchema);

export const DashboardEvalSchema = z.object({
  raw: z.string(),
  label: z.string(),
  provider: z.string(),
  function: z.function().optional(),
  id: z.string().optional(),
  display: z.string().optional(),
  config: z.any().optional(),
  metrics: CompletedPromptSchema.shape.metrics.optional(),
  evalId: z.string(),
  description: z.string().nullable(),
  datasetId: z.string().nullable(),
  promptId: z.string().nullable(),
  isRedteam: z.boolean(),
  createdAt: z.number(),
  pluginFailCount: z.record(z.number()),
  pluginPassCount: z.record(z.number()),
});

export type DashboardIssueDTO = z.infer<typeof DashboardIssueSchema>;
export type DashboardIssuesDTO = z.infer<typeof DashboardIssuesSchema>;
export type DashboardTargetDTO = z.infer<typeof DashboardTargetSchema>;
export type DashboardTargetsDTO = z.infer<typeof DashboardTargetsSchema>;
export type DashboardQueryParams = z.infer<typeof DashboardQueryParamsSchema>;
export type DashboardTargetsResponse = z.infer<typeof DashboardTargetsDTOSchema>;
export type IssuesOverTimeDataPointDTO = z.infer<typeof IssuesOverTimeDataPointDTOSchema>;
export type IssuesOverTimeDTO = z.infer<typeof IssuesOverTimeDTOSchema>;
export type DashboardEvalDTO = z.infer<typeof DashboardEvalSchema>;
