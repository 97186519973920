import { useEffect, useState } from 'react';
import { Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type { ApplicationDescription } from '@shared/dto/redteamConfigs';

interface ExternalSystemsFormProps {
  data: ApplicationDescription;
  onUpdate: (data: ApplicationDescription) => void;
  canEdit: boolean;
}

export default function ExternalSystemsForm({ data, onUpdate, canEdit }: ExternalSystemsFormProps) {
  const [externalSystemEnabled, setExternalSystemEnabled] = useState(
    Boolean(data.connectedSystems?.trim()),
  );

  useEffect(() => {
    if (data.connectedSystems) {
      setExternalSystemEnabled(true);
    }
  }, [data.connectedSystems]);

  return (
    <Box sx={{ py: 4 }}>
      <Typography variant="h6" sx={{ fontWeight: 'medium', mb: 1 }}>
        External System Access
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <Switch
          checked={externalSystemEnabled}
          onChange={(e) => setExternalSystemEnabled(e.target.checked)}
          inputProps={{ 'aria-label': 'toggle external system access' }}
          disabled={!canEdit}
        />
        <Typography>This application connects to external systems</Typography>
      </Box>

      {externalSystemEnabled && (
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
            What external systems are connected to this application?
          </Typography>
          <TextField
            fullWidth
            value={data.connectedSystems}
            onChange={(e) => onUpdate({ ...data, connectedSystems: e.target.value })}
            multiline
            rows={2}
            placeholder="e.g. A CRM system for managing customer relationships. Flight booking system. Internal company knowledge base."
            disabled={!canEdit}
          />
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
            What data is available to the LLM from connected systems that the user has access to?
          </Typography>
          <TextField
            fullWidth
            value={data.accessToData}
            onChange={(e) => onUpdate({ ...data, accessToData: e.target.value })}
            multiline
            rows={2}
            placeholder="e.g. Flight prices and schedules, their own profile and purchase history. Basic HR information like holiday schedules, expense policy. 2024 Company plans, budget allocations and org chart."
            disabled={!canEdit}
          />

          <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
            What data is available to the LLM from connected systems that the user shouldn't have
            access to?
          </Typography>
          <TextField
            fullWidth
            value={data.forbiddenData}
            onChange={(e) => onUpdate({ ...data, forbiddenData: e.target.value })}
            multiline
            rows={2}
            placeholder="e.g. Other users' profiles and purchase history. Sensitive company information like financials, strategy, other employee data."
            disabled={!canEdit}
          />

          <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
            What actions can the user take on connected systems?
          </Typography>
          <TextField
            fullWidth
            value={data.accessToActions}
            onChange={(e) => onUpdate({ ...data, accessToActions: e.target.value })}
            multiline
            rows={2}
            placeholder="e.g. Update their profile, search for flights, book flights, view purchase history, view HR information."
            disabled={!canEdit}
          />

          <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
            What actions shouldn't the user be able to take on connected systems?
          </Typography>
          <TextField
            fullWidth
            value={data.forbiddenActions}
            onChange={(e) => onUpdate({ ...data, forbiddenActions: e.target.value })}
            multiline
            rows={2}
            placeholder="e.g. Update other users' profile, cancel other users' flights."
            disabled={!canEdit}
          />
        </Stack>
      )}
    </Box>
  );
}
