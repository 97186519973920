import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { Container, Paper } from '@mui/material';
import EvalsDataGrid from './components/EvalsDataGrid';

export default function EvalsIndexPage() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl" sx={{ py: 4, height: '100%' }}>
      <Paper sx={{ height: '100%' }}>
        <EvalsDataGrid
          onEvalSelected={(evalId) => navigate(`${ROUTES.eval}/${evalId}`)}
          showUtilityButtons
        />
      </Paper>
    </Container>
  );
}
