import type { ServerSettingDTO, ServerSettingsKeys } from '@shared/dto/serverSettings';
import { callApi } from '../api';

export const getSettings = async (): Promise<ServerSettingDTO[]> => {
  const response = await callApi('/server-settings');
  return await response.json();
};

export const updateSetting = async (
  key: ServerSettingsKeys,
  changes: Partial<ServerSettingDTO>,
) => {
  const response = await callApi(`/server-settings/${String(key)}`, {
    method: 'PUT',
    body: JSON.stringify(changes),
  });
  return await response.json();
};

export const deleteSetting = async (key: ServerSettingsKeys) => {
  const response = await callApi(`/server-settings/${key}`, {
    method: 'DELETE',
  });

  if (!response.ok) {
    throw new Error('Failed to delete setting');
  }

  return;
};
