import { MongoAbility } from '@casl/ability';
import z from 'zod';

export type AppAbility = MongoAbility<[Actions, Subjects]>;

export const UpdateRbacTagSchema = z.object({
  name: z.string().min(1),
  description: z.string().optional(),
});

export type UpdateRbacTagRequest = z.infer<typeof UpdateRbacTagSchema>;

export enum Actions {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export const ADMIN_ACTION = 'manage';

export enum Subjects {
  PROVIDER = 'Provider',
  CONFIG = 'Config',
  PLUGIN_COLLECTION = 'PluginCollection',
  JOB = 'EvalJob',
  EVAL = 'Eval',
  ISSUE = 'Issue',
}

export const SubjectDisplayNames: Record<Subjects, string> = {
  [Subjects.CONFIG]: 'Redteam Configurations',
  [Subjects.EVAL]: 'Evals',
  [Subjects.ISSUE]: 'Vulnerabilities',
  [Subjects.JOB]: 'Scans',
  [Subjects.PLUGIN_COLLECTION]: 'Plugin Collections',
  [Subjects.PROVIDER]: 'Targets',
};

export enum PermissionSet {
  ADMINISTRATOR = 'ADMINISTRATOR',
  VIEW_CONFIGURATIONS = 'VIEW_CONFIGURATIONS',
  MANAGE_CONFIGURATIONS = 'MANAGE_CONFIGURATIONS',
  MANAGE_PROVIDERS = 'MANAGE_PROVIDERS',
  RUN_SCANS = 'RUN_SCANS',
  MANAGE_RESULTS = 'MANAGE_RESULTS',
  VIEW_RESULTS = 'VIEW_RESULTS',
}

export const permissionSetDisplayNames: Record<PermissionSet, string> = {
  [PermissionSet.ADMINISTRATOR]: 'Administrator',
  [PermissionSet.VIEW_CONFIGURATIONS]: 'View Configurations',
  [PermissionSet.MANAGE_CONFIGURATIONS]: 'Manage Configurations',
  [PermissionSet.MANAGE_PROVIDERS]: 'Manage Targets',
  [PermissionSet.RUN_SCANS]: 'Run Scans',
  [PermissionSet.MANAGE_RESULTS]: 'Manage Results',
  [PermissionSet.VIEW_RESULTS]: 'View Results',
};

export const permissionSetMappings: Record<PermissionSet, PermissionDTO[]> = {
  [PermissionSet.ADMINISTRATOR]: Object.values(Subjects).flatMap((subject) =>
    Object.values(Actions).map((action) => ({
      subject,
      action,
    })),
  ),
  [PermissionSet.VIEW_CONFIGURATIONS]: [
    { subject: Subjects.CONFIG, action: Actions.READ },
    { subject: Subjects.PROVIDER, action: Actions.READ },
    { subject: Subjects.PLUGIN_COLLECTION, action: Actions.READ },
  ],
  [PermissionSet.MANAGE_CONFIGURATIONS]: [
    { subject: Subjects.CONFIG, action: Actions.CREATE },
    { subject: Subjects.CONFIG, action: Actions.UPDATE },
    { subject: Subjects.CONFIG, action: Actions.DELETE },
    { subject: Subjects.PLUGIN_COLLECTION, action: Actions.READ },
    { subject: Subjects.PLUGIN_COLLECTION, action: Actions.CREATE },
    { subject: Subjects.PLUGIN_COLLECTION, action: Actions.UPDATE },
    { subject: Subjects.PLUGIN_COLLECTION, action: Actions.DELETE },
    // Dependencies
    { subject: Subjects.PROVIDER, action: Actions.READ },
  ],
  [PermissionSet.MANAGE_PROVIDERS]: [
    { subject: Subjects.PROVIDER, action: Actions.CREATE },
    { subject: Subjects.PROVIDER, action: Actions.UPDATE },
    { subject: Subjects.PROVIDER, action: Actions.DELETE },
  ],

  [PermissionSet.RUN_SCANS]: [
    { subject: Subjects.JOB, action: Actions.CREATE },
    { subject: Subjects.JOB, action: Actions.READ },
    { subject: Subjects.JOB, action: Actions.UPDATE },
    { subject: Subjects.JOB, action: Actions.DELETE },
    { subject: Subjects.EVAL, action: Actions.READ },
    { subject: Subjects.EVAL, action: Actions.CREATE },
    { subject: Subjects.ISSUE, action: Actions.READ },
    { subject: Subjects.PROVIDER, action: Actions.READ },
    { subject: Subjects.PLUGIN_COLLECTION, action: Actions.READ },
    { subject: Subjects.CONFIG, action: Actions.READ },
  ],
  [PermissionSet.MANAGE_RESULTS]: [
    { subject: Subjects.EVAL, action: Actions.CREATE },
    { subject: Subjects.EVAL, action: Actions.UPDATE },
    { subject: Subjects.EVAL, action: Actions.DELETE },
    { subject: Subjects.ISSUE, action: Actions.READ },
    { subject: Subjects.ISSUE, action: Actions.CREATE },
    { subject: Subjects.ISSUE, action: Actions.UPDATE },
    { subject: Subjects.ISSUE, action: Actions.DELETE },
    { subject: Subjects.PROVIDER, action: Actions.READ },
  ],
  [PermissionSet.VIEW_RESULTS]: [
    { subject: Subjects.ISSUE, action: Actions.READ },
    { subject: Subjects.PROVIDER, action: Actions.READ },
    { subject: Subjects.EVAL, action: Actions.READ },
  ],
};

export const PermissionSchema = z.object({
  subject: z.nativeEnum(Subjects),
  action: z.nativeEnum(Actions),
});

export type PermissionDTO = z.infer<typeof PermissionSchema>;

// Team Schemas
export const CreateTeamSchema = z.object({
  name: z.string().min(1, 'Name is required').trim(),
  description: z.string().optional(),
});

export const UpdateTeamSchema = z.object({
  name: z.string().min(1, 'Name is required').trim().optional(),
  description: z.string().optional(),
});

export const AddTeamMemberSchema = z.object({
  userId: z.string().uuid('Invalid user ID'),
  roleId: z.string().uuid('Invalid role ID'),
});

export const UpdateTeamMemberSchema = z.object({
  roleId: z.string().uuid('Invalid role ID'),
});

// Role Schemas
export const CreateRoleSchema = z.object({
  name: z.string().min(1, 'Name is required').trim(),
  permissionSets: z.array(z.nativeEnum(PermissionSet)),
});

export const UpdateRoleSchema = z.object({
  name: z.string().min(1, 'Name is required').trim(),
  permissionSets: z.array(z.nativeEnum(PermissionSet)),
});

export const RoleSchema = z.object({
  id: z.string(),
  name: z.string(),
  permissionSets: z.array(z.nativeEnum(PermissionSet)),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type RoleDTO = z.infer<typeof RoleSchema>;

// Type exports
export type CreateTeamRequest = z.infer<typeof CreateTeamSchema>;
export type UpdateTeamRequest = z.infer<typeof UpdateTeamSchema>;
export type AddTeamMemberRequest = z.infer<typeof AddTeamMemberSchema>;
export type UpdateTeamMemberRequest = z.infer<typeof UpdateTeamMemberSchema>;
export type CreateRoleRequest = z.infer<typeof CreateRoleSchema>;
export type UpdateRoleRequest = z.infer<typeof UpdateRoleSchema>;
