import React, { createContext, useState, useContext, type ReactNode } from 'react';
import { Snackbar, Alert, type AlertColor } from '@mui/material';

interface ToastContextType {
  showToast: (message: React.ReactNode, severity?: AlertColor, autoHideDuration?: number) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<React.ReactNode>('');
  const [severity, setSeverity] = useState<AlertColor>('info');
  const [autoHideDuration, setAutoHideDuration] = useState<number>(2000);

  const showToast = (
    message: React.ReactNode,
    severity: AlertColor = 'info',
    autoHideDuration?: number,
  ) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
    if (autoHideDuration) {
      setAutoHideDuration(autoHideDuration);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{
            width: '100%',
            maxWidth: '600px',
            boxShadow: (theme) => theme.shadows[3],
            fontFamily: 'inherit',
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
