import { CLOUD_PROVIDER_PREFIX } from '@promptfoo/constants';
import { ProviderOptions, UnifiedConfig } from '@promptfoo/types';

export const EXAMPLE_RAG_PROVIDER_CONFIG = {
  url: 'https://redpanda-internal-rag-example.promptfoo.app/chat',
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'x-session-id': '{{sessionId}}',
  },
  body: {
    input: '{{prompt}}',
    role: 'engineering',
  },
  transformResponse: 'json.response',
  sessionParser: `data.headers['x-session-id']`,
};

export const getFirstDisplayNameFromConfigProviders = (providers: UnifiedConfig['providers']) => {
  if (!providers) {
    return null;
  }
  if (typeof providers === 'string') {
    return providers;
  }
  if (Array.isArray(providers)) {
    if (providers.length === 0) {
      return null;
    }
    const provider = providers[0];
    if (typeof provider === 'string') {
      return provider;
    }
    return (provider as ProviderOptions).label ?? (provider as ProviderOptions).id;
  }
  return null;
};

export const getDatabaseIdFromUnifiedConfigProvider = (
  provider: UnifiedConfig['providers'],
): string | null => {
  if (typeof provider === 'function') {
    return null;
  }
  if (typeof provider === 'string') {
    return getDatabaseIdFromProviderId(provider);
  }
  if (Array.isArray(provider)) {
    const first = provider[0];
    if (typeof first === 'string') {
      return getDatabaseIdFromProviderId(first);
    }
    if (typeof first === 'object') {
      return getDatabaseIdFromProvider(first as ProviderOptions);
    }
  }
  return null;
};

export const getDatabaseIdFromProvider = (provider?: ProviderOptions | null) => {
  if (!provider?.id) {
    return null;
  }
  return getDatabaseIdFromProviderId(provider.id);
};

export const getDatabaseIdFromProviderId = (providerId?: string | null) => {
  if (!providerId) {
    return null;
  }
  if (!isCloudProviderId(providerId)) {
    return null;
  }
  return providerId.split(CLOUD_PROVIDER_PREFIX)[1];
};

export const isCloudProvider = (provider?: ProviderOptions | null) => {
  if (!provider?.id) {
    return false;
  }
  return isCloudProviderId(provider.id);
};

export const isCloudProviderId = (providerId?: string | null) => {
  if (!providerId) {
    return false;
  }
  return providerId.startsWith(CLOUD_PROVIDER_PREFIX);
};
