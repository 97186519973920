import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import {
  AtomicTestCase,
  GradingResult,
  Prompt,
  ProviderOptions,
  ProviderResponse,
  ResultsFile,
} from '@promptfoo/types';
import { z } from 'zod';
import { ProviderDTO } from './dto/providers';

extendZodWithOpenApi(z);

export * from './dto/config';
export * from './dto/dashboard';
export * from './dto/datasets';
export * from './dto/history';
export * from './dto/jobs';
export * from './dto/prompts';
export * from './dto/issues';
export * from './dto/evals';

export const EvalResultDTOSchema = z.object({
  id: z.string().uuid(),
  evalId: z.string(),
  description: z.string().nullable().optional(),
  promptIdx: z.number(),
  testIdx: z.number(),
  testCase: z.custom<AtomicTestCase>(),
  prompt: z.custom<Prompt>(),
  promptId: z.string(),
  error: z.string().nullable().optional(),
  success: z.boolean(),
  score: z.number(),
  response: z.custom<ProviderResponse>().nullable(),
  gradingResult: z.custom<GradingResult>().nullable(),
  namedScores: z.record(z.string(), z.number()),
  provider: z.custom<ProviderOptions>(),
  latencyMs: z.number(),
  cost: z.number(),
  persisted: z.boolean(),
  createdAt: z.date(),
});

export type EvalResultDTO = z.infer<typeof EvalResultDTOSchema>;

export type CloudResultsFile = ResultsFile & {
  isPublic: boolean;
  providers: ProviderDTO[];
  teamId?: string | null;
};
