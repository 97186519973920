import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet, useLocation } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ServerSettingsKeys } from '@shared/dto/serverSettings';

export default function ServerSettingsPage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // If we're at the root settings page (no sub-route), redirect to redteam provider
    if (location.pathname === ROUTES.serverSettings.index) {
      navigate(`${ROUTES.serverSettings.edit}/${ServerSettingsKeys.redteamProvider}`);
    }
  }, [location.pathname, navigate]);

  const settingsMenu = [
    // {
    //   title: 'Authentication',
    //   icon: <SecurityIcon />,
    //   onClick: () => navigate(`${ROUTES.serverSettings.edit}/authConfig`),
    // },
    {
      title: 'Red Team Provider',
      icon: <SmartToyIcon />,
      onClick: () => navigate(`${ROUTES.serverSettings.edit}/redteamProvider`),
    },
    {
      title: 'Unaligned Provider',
      icon: <SmartToyIcon />,
      onClick: () => navigate(`${ROUTES.serverSettings.edit}/unalignedProvider`),
    },
  ];

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {/* Left Navigation */}
      <Paper
        sx={{
          width: 280,
          flexShrink: 0,
          borderRight: 1,
          borderColor: 'divider',
          height: '100vh',
          position: 'sticky',
          top: 0,
          overflowY: 'auto',
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Settings
          </Typography>
        </Box>
        <List>
          {settingsMenu.map((item) => (
            <ListItem key={item.title} disablePadding>
              <ListItemButton onClick={item.onClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Main Content */}
      <Box sx={{ flex: 1, p: 3 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
