import React from 'react';
import HistoryIcon from '@mui/icons-material/History';
import { Box, Typography, Grid } from '@mui/material';
import type { ProviderDTO } from '@shared/dto/providers';
import type { UpdateProviderRequest } from '@shared/dto/providers';
import History from './History';

interface ReviewPanelProps {
  target: Partial<ProviderDTO> | undefined;
  updates: UpdateProviderRequest | undefined;
  canEdit: boolean;
  isNew: boolean;
}

const typeMap = {
  openai: 'OpenAI',
  anthropic: 'Anthropic Claude',
  google: 'Google Gemini',
  azure: 'Azure OpenAI',
  openrouter: 'Foundation Model',
  http: 'HTTP/HTTPS Endpoint',
  websocket: 'WebSocket Endpoint',
  javascript: 'JavaScript Provider',
  python: 'Python Provider',
  custom: 'Custom Provider',
} as const;

type ProviderType = keyof typeof typeMap;

// Helper function to get a user-friendly name for the provider type
const getProviderTypeName = (type: ProviderType): string => {
  return typeMap[type] || type;
};

export default function ReviewPanel({ target, updates, canEdit, isNew }: ReviewPanelProps) {
  const config = React.useMemo(() => {
    return {
      name: updates?.name ?? target?.name ?? '',
      config: updates?.config ?? target?.config ?? {},
      stateful: updates?.stateful ?? target?.stateful ?? false,
      applicationDescription:
        updates?.applicationDescription ?? target?.applicationDescription ?? {},
    };
  }, [target, updates]);

  const providerType = ((config.config as any)?.type ||
    (config.config?.id || '').split(':')[0]) as ProviderType;
  const providerDisplayName = getProviderTypeName(providerType);

  // Helper function to format configuration details
  const getConfigurationDetails = () => {
    const details = [];
    const providerConfig = config.config as any;

    if (providerType === 'openrouter') {
      details.push({
        label: 'Model',
        value: providerConfig.id?.split(':')[1] || 'Not specified',
      });
    } else if (providerType === 'http' || providerType === 'websocket') {
      details.push({
        label: 'Endpoint URL',
        value: providerConfig.config?.url || 'Not specified',
      });
      if (providerConfig.config?.headers) {
        details.push({
          label: 'Headers',
          value: Object.keys(providerConfig.config.headers).length + ' header(s) configured',
        });
      }
    } else if (providerType === 'javascript' || providerType === 'python') {
      details.push({
        label: 'File Path',
        value: providerConfig.id || 'Not specified',
      });
    }

    // Add any custom configuration options
    if (providerConfig.config?.options) {
      details.push({
        label: 'Custom Options',
        value: Object.keys(providerConfig.config.options).length + ' option(s) configured',
      });
    }

    return details;
  };

  const configDetails = getConfigurationDetails();
  const isTargetModel = providerType === 'openrouter';

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box
            sx={{
              p: 3,
              bgcolor: 'background.paper',
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider',
              height: '100%',
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Target Name
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {config.name || '(Not set)'}
            </Typography>

            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Target Type
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {providerDisplayName || '(Not set)'}
            </Typography>

            {configDetails.length > 0 && (
              <>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Configuration Details
                </Typography>
                {configDetails.map((detail, index) => (
                  <Box key={detail.label} sx={{ mb: index === configDetails.length - 1 ? 2 : 1 }}>
                    <Typography variant="body2" color="text.secondary">
                      {detail.label}
                    </Typography>
                    <Typography variant="body1">{detail.value}</Typography>
                  </Box>
                ))}
              </>
            )}

            {!isTargetModel && (
              <>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Conversation History
                </Typography>
                <Typography variant="body1">
                  {config.stateful
                    ? 'System maintains conversation history'
                    : 'The whole conversation gets sent on every request or there is no conversation history'}
                </Typography>
              </>
            )}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box
            sx={{
              p: 3,
              bgcolor: 'background.paper',
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider',
              height: '100%',
            }}
          >
            {!isTargetModel && (
              <>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Purpose
                </Typography>
                <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-wrap' }}>
                  {config.applicationDescription?.purpose || '(Not set)'}
                </Typography>

                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  System Prompt
                </Typography>
                <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-wrap' }}>
                  {config.applicationDescription?.systemPrompt || '(Not set)'}
                </Typography>

                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Access to Data
                </Typography>
                <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                  {config.applicationDescription?.accessToData || '(Not set)'}
                </Typography>
              </>
            )}
            {isTargetModel && (
              <Typography variant="body1" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                No additional context required for foundation models.
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {!isNew && target?.id && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <HistoryIcon sx={{ fontSize: '1.25rem' }} />
            Recent History
          </Typography>
          <History providerId={target.id} />
        </Box>
      )}
    </Box>
  );
}
