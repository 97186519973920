import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ErrorFallback from '@cloud-ui/components/ErrorFallback';
import { ROUTES } from '@cloud-ui/constants';
import { useConfig } from '@cloud-ui/contexts/ConfigContext';
import { useCan } from '@cloud-ui/contexts/RbacContext';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { useStartJob } from '@cloud-ui/hooks/useJobs';
import {
  getRedteamConfig,
  createRedteamConfig,
  updateRedteamConfig,
} from '@cloud-ui/utils/api/redteam';
import { canRunOnServer } from '@cloud-ui/utils/providers';
import AppIcon from '@mui/icons-material/Apps';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PluginIcon from '@mui/icons-material/Extension';
import TargetIcon from '@mui/icons-material/GpsFixed';
import StrategyIcon from '@mui/icons-material/Psychology';
import ReviewIcon from '@mui/icons-material/RateReview';
import SecurityIcon from '@mui/icons-material/Security';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { CLOUD_PROVIDER_PREFIX } from '@promptfoo/constants';
import { Actions, Subjects } from '@shared/dto/rbac';
import type { RedteamConfigDTO } from '@shared/dto/redteamConfigs';
import { getUnifiedConfig } from '@shared/utils/configs';
import { getDatabaseIdFromProvider, isCloudProvider } from '@shared/utils/providers';
import { useGetProviders } from '../../hooks/providers';
import Plugins from './components/Plugins';
import Purpose from './components/Purpose';
import Review from './components/Review';
import Setup from './components/Setup';
import Strategies from './components/Strategies';
import Target from './components/Target';
import { EXAMPLE_CONFIG, useRedTeamConfig } from './hooks/useRedTeamConfig';
import { useSetupState } from './hooks/useSetupState';
import './edit.css';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    left: 0,
    right: 'auto',
  },
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  '& .MuiTab-root': {
    minHeight: '48px',
  },
  '& .MuiTabs-scrollButtons': {
    display: 'none',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  alignItems: 'center',
  textAlign: 'left',
  justifyContent: 'flex-start',
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
  },
  maxWidth: 'none',
  width: '100%',
  minHeight: '48px',
  padding: theme.spacing(1, 2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    fontSize: '18px',
  },
  textTransform: 'none',
  fontSize: '0.875rem',
}));

const TabPanel = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </TabPanel>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Root = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 64,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#fff',
  overflow: 'hidden',
}));

const PageHeader = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  '.headerContent': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3),
    minHeight: 64,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  '.leftSection': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  '.rightSection': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  '.backButton': {
    marginRight: theme.spacing(1),
  },
  '.configStatus': {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const MainContent = styled(Box)({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
});

const OuterSidebarContainer = styled(Box)(({ theme }) => ({
  width: '280px',
  borderRight: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
}));

const InnerSidebarContainer = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

const Content = styled(Box)({
  flex: 1,
  overflow: 'auto',
  padding: '24px',
});

const TabContent = styled(Box)({
  height: '100%',
});

const TabsContainer = styled(Box)({
  flex: 1,
  overflow: 'auto',
});

export default function RedteamConfigsEditPage() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const targetId = searchParams.get('targetId');
  const { enableServerSideJobs } = useConfig();
  const queryClient = useQueryClient();
  const { currentTeam } = useTeamsContext();
  const canEdit = useCan(Actions.UPDATE, Subjects.CONFIG);
  const canStartJobs = useCan(Actions.CREATE, Subjects.JOB);
  const [initialLoad, setInitialLoad] = useState(true);

  const {
    configId,
    setConfigId,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    config,
    setFullConfig,
    resetConfig,
    updateConfig,
  } = useRedTeamConfig();

  const { providers } = useGetProviders();
  const provider = useMemo(() => {
    if (!isCloudProvider(config.target)) {
      return config.target;
    }
    return providers?.find((p) => p.id === getDatabaseIdFromProvider(config.target));
  }, [providers, config.target]);
  const targetCanRunOnServer = useMemo(() => canRunOnServer(provider?.config), [provider]);

  // If the team changes, we need to navigate to the configs list page.
  useEffect(() => {
    if (!initialLoad && currentTeam && id !== 'new') {
      navigate(ROUTES.redteam.configs);
    }
    setInitialLoad(false);
  }, [currentTeam]);

  const {
    data: configData,
    isLoading: configIsLoading,
    error: loadingConfigError,
  } = useQuery({
    queryKey: ['redteamConfig', configId],
    queryFn: () => getRedteamConfig(configId),
    enabled: !!configId,
  });

  // Get initial tab from URL hash or default to first page
  const [value, setValue] = useState(() => {
    const hash = location.hash.replace('#', '');
    return hash ? Number.parseInt(hash, 10) : 0;
  });

  const { showToast } = useToast();
  const { mutate: runRedTeam } = useStartJob();
  const lastSavedConfig = useRef<string>('');
  const { hasSeenSetup, markSetupAsSeen } = useSetupState();
  const [setupModalOpen, setSetupModalOpen] = useState(!hasSeenSetup);

  // We don't show the usage details tab if the target is a cloud provider. Only if it's an old school
  // manually configured target.
  const [showUsageDetails, setShowUsageDetails] = useState(configId && !config?.target?.id);

  useEffect(() => {
    setShowUsageDetails(configId && !isCloudProvider(config?.target));
  }, [configId, config?.target, configIsLoading]);

  // Create a helper function to calculate tab indices based on whether the usage details tab is shown.
  const getTabIndex = useCallback(
    (baseIndex: number) => {
      return showUsageDetails ? baseIndex : baseIndex - 1;
    },
    [showUsageDetails, configIsLoading],
  );

  // Handle browser back/forward
  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      const newValue = Number.parseInt(hash, 10);
      if (!Number.isNaN(newValue) && newValue >= 0 && newValue <= 4) {
        setValue(newValue);
      } else {
        setValue(0);
      }
    } else {
      setValue(0);
    }
  }, [location.hash]);

  const updateHash = (newValue: number) => {
    if (location.hash !== `#${newValue}`) {
      navigate(`#${newValue}`);
    }
  };

  const handleNext = () => {
    setValue((prevValue) => {
      const newValue = prevValue + 1;
      updateHash(newValue);
      return newValue;
    });
  };

  const handleBack = () => {
    setValue((prevValue) => {
      const newValue = prevValue - 1;
      updateHash(newValue);
      return newValue;
    });
  };

  const handleChange = (event: React.SyntheticEvent | undefined, newValue: number) => {
    updateHash(newValue);
    setValue(newValue);
  };

  const closeSetupModal = () => {
    setSetupModalOpen(false);
    markSetupAsSeen();
  };

  const handleUpdateConfig = useMutation({
    mutationFn: async () => {
      if (!config.description) {
        throw new Error('Configuration name is required');
      }
      return await updateRedteamConfig(configId, {
        ...config,
      });
    },
    onSuccess: (data: RedteamConfigDTO) => {
      showToast('Configuration saved successfully', 'success');

      lastSavedConfig.current = JSON.stringify(config);
      setHasUnsavedChanges(false);
      setConfigId(data.id || '');
      setFullConfig({ ...config, id: data.id });
      queryClient.setQueryData(['redteamConfig', data.id], data);
      queryClient.invalidateQueries({ queryKey: ['redteamConfigs', currentTeam?.id] });
    },
    onError: (error: Error) => {
      showToast(error.message || 'Failed to save configuration', 'error');
      console.error('Failed to save configuration:', error);
    },
  });

  const handleSaveConfig = useMutation({
    mutationFn: async () => {
      return await createRedteamConfig({
        ...config,
        description:
          config.description || `New Redteam Configuration: ${new Date().toLocaleTimeString()}`,
        teamId: currentTeam!.id,
      });
    },
    onSuccess: (data: RedteamConfigDTO) => {
      showToast('Configuration saved successfully', 'success');

      lastSavedConfig.current = JSON.stringify(config);
      setHasUnsavedChanges(false);

      setConfigId(data.id || '');
      setFullConfig({ ...config, id: data.id });
      queryClient.setQueryData(['redteamConfig', data.id], data);
      queryClient.invalidateQueries({ queryKey: ['redteamConfigs', currentTeam?.id] });
    },
    onError: (error: Error) => {
      showToast(error.message || 'Failed to save configuration', 'error');
      console.error('Failed to save configuration:', error);
    },
  });

  if (loadingConfigError) {
    console.error('Failed to load configuration', loadingConfigError);
    showToast(
      loadingConfigError instanceof Error
        ? loadingConfigError.message
        : 'Failed to load configuration',
      'error',
    );
  }

  useEffect(() => {
    if (configData) {
      setFullConfig({ ...configData.config, id: configData.id });

      lastSavedConfig.current = JSON.stringify({ ...configData.config, id: configData.id });
      if (isCloudProvider(configData.config.target)) {
        setShowUsageDetails(false);
        handleChange(undefined, 3);
      } else {
        setShowUsageDetails(true);
        handleChange(undefined, 4);
      }
      setHasUnsavedChanges(false);
    }
  }, [configData]);

  useEffect(() => {
    if (id === 'new') {
      // First reset the config
      resetConfig();
      setShowUsageDetails(false);

      // Set the initial tab
      handleChange(undefined, 0);
    } else if (id === 'example') {
      setFullConfig({ ...EXAMPLE_CONFIG, teamId: currentTeam!.id });
      setShowUsageDetails(true);
      handleChange(undefined, 4);
    } else if (id) {
      setConfigId(id);
    } else if (configId !== '') {
      resetConfig();
    }
  }, [id]);

  // Handle target selection after config reset
  useEffect(() => {
    if (id === 'new' && targetId && providers?.length > 0) {
      const provider = providers.find((p) => p.id === targetId);
      if (provider) {
        updateConfig('target', {
          id: `${CLOUD_PROVIDER_PREFIX}${provider.id}`,
        });
        // Only navigate to plugins tab after we've set the target
        handleChange(undefined, getTabIndex(2));
      }
    }
  }, [id, targetId, providers, getTabIndex]);

  // Replace the existing effect with this one
  useEffect(() => {
    const currentConfigString = JSON.stringify(config);
    const hasChanges = lastSavedConfig.current !== currentConfigString;
    setHasUnsavedChanges(hasChanges);
  }, [config]);

  const handleBackToList = () => {
    navigate(ROUTES.redteam.configs);
  };

  if (!config || !config.applicationDefinition || configIsLoading) {
    return null;
  }

  if (!canEdit) {
    return (
      <Box sx={{ p: 3 }}>
        <Review onSave={() => {}} />
      </Box>
    );
  }

  return (
    <Root>
      <PageHeader>
        <div className="headerContent">
          <div className="leftSection">
            <IconButton
              className="backButton"
              onClick={handleBackToList}
              aria-label="back to configurations list"
            >
              <ArrowBackIcon />
            </IconButton>
            <div className="configStatus">
              <Typography variant="h6">{config.description || 'New Scan'}</Typography>
              {hasUnsavedChanges && canEdit && (
                <Typography
                  variant="body2"
                  color="warning.main"
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                >
                  <span>●</span> Unsaved changes
                </Typography>
              )}
            </div>
          </div>
          <div className="rightSection">
            {hasUnsavedChanges && canEdit && (
              <Button
                variant="outlined"
                onClick={async () => {
                  const copy = { ...config, teamId: currentTeam!.id };
                  const result = await createRedteamConfig(copy);
                  showToast('Config copied successfully', 'success');
                  navigate(`${ROUTES.redteam.configs}/${result.id}`);
                }}
              >
                Save as New
              </Button>
            )}
            {hasUnsavedChanges && canEdit ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => (configId ? handleUpdateConfig.mutate() : handleSaveConfig.mutate())}
                disabled={handleSaveConfig.isPending}
              >
                {handleSaveConfig.isPending ? 'Saving...' : 'Save'}
              </Button>
            ) : (
              enableServerSideJobs &&
              canStartJobs && (
                <Tooltip
                  title={
                    targetCanRunOnServer
                      ? undefined
                      : 'This target does not support server-side execution. Please use the CLI.'
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SecurityIcon />}
                      onClick={() =>
                        runRedTeam({
                          config,
                          configId,
                          getUnifiedConfig,
                          teamId: config.teamId ?? currentTeam!.id,
                        })
                      }
                      disabled={!targetCanRunOnServer}
                    >
                      Run Red Team
                    </Button>
                  </span>
                </Tooltip>
              )
            )}
          </div>
        </div>
      </PageHeader>
      <MainContent>
        <OuterSidebarContainer>
          <InnerSidebarContainer>
            <TabsContainer>
              <StyledTabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
              >
                {showUsageDetails && (
                  <StyledTab
                    icon={<AppIcon />}
                    iconPosition="start"
                    label="Usage Details"
                    {...a11yProps(0)}
                  />
                )}
                <StyledTab
                  icon={<TargetIcon />}
                  iconPosition="start"
                  label="Targets"
                  {...a11yProps(getTabIndex(1))}
                />
                <StyledTab
                  icon={<PluginIcon />}
                  iconPosition="start"
                  label={`Plugins${config.plugins?.length ? ` (${config.plugins.length})` : ''}`}
                  {...a11yProps(getTabIndex(2))}
                />
                <StyledTab
                  icon={<StrategyIcon />}
                  iconPosition="start"
                  label={`Strategies${config.strategies?.length ? ` (${config.strategies.length})` : ''}`}
                  {...a11yProps(getTabIndex(3))}
                />
                <StyledTab
                  icon={<ReviewIcon />}
                  iconPosition="start"
                  label="Review"
                  {...a11yProps(getTabIndex(4))}
                />
              </StyledTabs>
            </TabsContainer>
          </InnerSidebarContainer>
        </OuterSidebarContainer>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Content>
            <TabContent>
              {showUsageDetails && (
                <CustomTabPanel value={value} index={0}>
                  <Purpose onNext={handleNext} />
                </CustomTabPanel>
              )}
              <CustomTabPanel value={value} index={getTabIndex(1)}>
                <Target onNext={handleNext} onBack={handleBack} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={getTabIndex(2)}>
                <Plugins onNext={handleNext} onBack={handleBack} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={getTabIndex(3)}>
                <Strategies onNext={handleNext} onBack={handleBack} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={getTabIndex(4)}>
                <Review
                  onSave={() =>
                    configId ? handleUpdateConfig.mutate() : handleSaveConfig.mutate()
                  }
                />
              </CustomTabPanel>
            </TabContent>
          </Content>
        </ErrorBoundary>
      </MainContent>
      <Setup open={setupModalOpen} onClose={closeSetupModal} />
    </Root>
  );
}
