import Markdown from 'react-markdown';
import BuildIcon from '@mui/icons-material/Build';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Card, Typography, Chip, Stack, Box } from '@mui/material';
import type { SuggestionType } from '@shared/dto/remediations';
import { remediationPathLabels, impactColors, complexityColors } from '@shared/dto/remediations';

interface SuggestionProps {
  suggestion: SuggestionType;
}

export default function Suggestion({ suggestion }: SuggestionProps) {
  return (
    <Card
      sx={{
        mb: 3,
        p: 3,
        border: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.paper',
        position: 'relative',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          color: 'primary.main',
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {suggestion.title}
      </Typography>

      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        <Chip
          label={remediationPathLabels[suggestion.remediation_path]}
          color="primary"
          size="small"
        />
        <Chip
          icon={<PriorityHighIcon />}
          label={`Impact: ${suggestion.impact_level}`}
          color={impactColors[suggestion.impact_level] as 'error' | 'warning' | 'info'}
          size="small"
        />
        <Chip
          icon={<BuildIcon />}
          label={`Complexity: ${suggestion.implementation_complexity}`}
          color={complexityColors[suggestion.implementation_complexity] as any}
          size="small"
        />
      </Stack>

      <Box
        sx={{
          '& pre': {
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            backgroundColor: '#f5f5f5',
            padding: '12px',
            borderRadius: '4px',
            overflowX: 'auto',
            maxWidth: '100%',
          },
          '& code': {
            backgroundColor: '#f5f5f5',
            padding: '2px 4px',
            borderRadius: '3px',
            fontSize: '0.875em',
          },
        }}
      >
        <Markdown
          components={{
            p: ({ children }) => (
              <Typography
                component="p"
                sx={{
                  my: 1,
                  lineHeight: 1.6,
                }}
              >
                {children}
              </Typography>
            ),
          }}
        >
          {suggestion.remediation}
        </Markdown>
      </Box>
    </Card>
  );
}
