import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { useOrganization, useUpdateOrganization } from '@cloud-ui/hooks/useOrganization';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Card, TextField, Typography } from '@mui/material';

export default function Organization() {
  const { orgId } = useParams();
  const { showToast } = useToast();
  const { data: organization, refetch: refetchOrganization } = useOrganization(orgId || '');
  const { mutate: updateOrganization } = useUpdateOrganization(orgId || '');
  const [organizationName, setOrganizationName] = useState<string>('');

  useEffect(() => {
    if (organization?.name) {
      setOrganizationName(organization.name);
    }
  }, [organization]);

  const handleUpdateOrganization = () => {
    if (!organizationName?.trim()) {
      return;
    }
    updateOrganization({ name: organizationName });
    showToast('Organization updated', 'success');
    refetchOrganization();
  };

  return (
    <Box maxWidth="100%" mx="auto" p={2}>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h4">Organization</Typography>
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleUpdateOrganization}
          disabled={!organizationName?.trim() || organizationName === organization?.name}
        >
          Save Changes
        </Button>
      </Box>

      <Card sx={{ p: 3, mb: 4 }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <TextField
            label="Name"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            sx={{ minWidth: 300 }}
          />
        </Box>
      </Card>
    </Box>
  );
}
