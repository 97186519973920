import React from 'react';
import { useCopyToClipboard } from '@cloud-ui/hooks/useCopyToClipboard';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';

export interface CommandBoxProps {
  /** The command to display */
  command: string;
  /** Optional additional styling for the container */
  sx?: Record<string, any>;
}

/**
 * A component that displays a command with a copy button
 */
export const CommandBox: React.FC<CommandBoxProps> = ({ command, sx = {} }) => {
  const theme = useTheme();
  const [copied, copyToClipboard] = useCopyToClipboard();
  const hasClipboard = typeof navigator !== 'undefined' && !!navigator.clipboard;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: 1,
        p: 2,
        bgcolor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
        borderRadius: 1,
        ...sx,
      }}
    >
      <Box
        component="code"
        sx={{
          flexGrow: 1,
          display: 'block',
          color: 'text.primary',
          overflow: 'auto',
          wordBreak: 'keep-all',
          whiteSpace: 'nowrap',
          textAlign: 'left',
          padding: 2,
        }}
        aria-label="Command line instruction"
      >
        {command}
      </Box>
      {hasClipboard && (
        <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
          <IconButton
            size="small"
            onClick={() => copyToClipboard(command)}
            sx={{
              ml: 1,
              color: theme.palette.text.secondary,
            }}
            aria-label="Copy command to clipboard"
          >
            {copied ? <CheckIcon fontSize="small" /> : <ContentCopyIcon fontSize="small" />}
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default CommandBox;
