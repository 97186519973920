import type {
  IssuesOverTimeDTO,
  DashboardEvalDTO,
  DashboardIssuesDTO,
  DashboardTargetsDTO,
} from '@shared/dto';
import { callApi } from '../api';

export const getIssueCountsBySeverity = async (
  targetId: string | null,
  teamId?: string,
): Promise<DashboardIssuesDTO> => {
  const params = new URLSearchParams();
  if (targetId) {
    params.append('targetId', targetId);
  }
  if (teamId) {
    params.append('teamId', teamId);
  }

  const response = await callApi(
    `/dashboard/issues${params.toString() ? `?${params.toString()}` : ''}`,
  );
  return await response.json();
};

export const getTargets = async (teamId?: string): Promise<DashboardTargetsDTO> => {
  const params = new URLSearchParams();
  if (teamId) {
    params.append('teamId', teamId);
  }

  const response = await callApi(
    `/dashboard/targets${params.toString() ? `?${params.toString()}` : ''}`,
  );
  return await response.json();
};

export const getOpenIssuesPerDay = async (
  targetId: string | null,
  teamId?: string,
): Promise<IssuesOverTimeDTO> => {
  if (!targetId) {
    return [];
  }

  const params = new URLSearchParams();
  params.append('targetId', targetId);
  if (teamId) {
    params.append('teamId', teamId);
  }

  const response = await callApi(`/dashboard/issuesPerDay?${params.toString()}`);
  return await response.json();
};

export const getEvals = async (
  targetId: string | null,
  teamId?: string,
): Promise<DashboardEvalDTO[]> => {
  const params = new URLSearchParams();
  params.append('limit', '20');
  if (targetId) {
    params.append('targetId', targetId);
  }
  if (teamId) {
    params.append('teamId', teamId);
  }

  const response = await callApi(`/dashboard/evals?${params.toString()}`);
  return await response.json();
};
