import { useParams } from 'react-router-dom';
import { ServerSettingsKeys } from '@shared/dto/serverSettings';
import ProviderSettings from './components/ProviderSettings';

export default function EditServerSettings() {
  const { key } = useParams<{ key: ServerSettingsKeys }>();

  if (
    !key ||
    ![ServerSettingsKeys.redteamProvider, ServerSettingsKeys.unalignedProvider].includes(key)
  ) {
    return null;
  }

  return <ProviderSettings settingKey={key} />;
}
