import { useEffect, useState, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type { SeverityLevelType } from '@shared/constants';
import type { IssuesOverTimeDTO, DashboardEvalDTO, IssueDTO } from '@shared/dto';
import { useDashboardStore } from '../store';
import DashboardCharts from './DashboardCharts';
import EmergingRisks from './EmergingRisks';
import IssueHeatMap from './IssueHeatMap';
import IssuesBySeverity from './IssuesBySeverity';
import IssuesBySeverityList from './IssuesBySeverityList';
import LatestScanSection from './LatestScanSection';
import RecentEvals from './RecentEvals';
import SeverityCards from './SeverityCards';
import WeakestAreasList from './WeakestAreas';

export default function Dashboard({
  issues,
  issuesPerDay,
  evals,
  targets,
  isLoading,
}: {
  issues: IssueDTO[];
  issuesPerDay: IssuesOverTimeDTO;
  evals: DashboardEvalDTO[];
  targets: { id: string; label: string }[]; // This is what the autocomplete needs :/
  isLoading: boolean;
}) {
  const { selectedTargetId, setSelectedTargetId } = useDashboardStore();
  const navigate = useNavigate();
  const [issueCountsBySeverity, setIssueCountsBySeverity] = useState<
    Record<SeverityLevelType, number> | undefined
  >();

  const [filteredIssues, setFilteredIssues] = useState<IssueDTO[]>([]);

  useEffect(() => {
    setFilteredIssues(
      issues.filter(
        (issue) => issue.providerId === selectedTargetId || issue.targetId === selectedTargetId,
      ),
    );
  }, [selectedTargetId, issues]);

  useEffect(() => {
    setIssueCountsBySeverity(
      issuesPerDay && issuesPerDay.length > 0
        ? issuesPerDay[issuesPerDay.length - 1]?.data
        : undefined,
    );
  }, [issuesPerDay]);

  const navigateToIssues = useCallback(
    (params: Record<string, string>) => {
      const queryParams = new URLSearchParams(params);
      if (selectedTargetId) {
        queryParams.set('targetId', selectedTargetId);
      }
      navigate(`${ROUTES.redteam.vulnerabilities}?${queryParams.toString()}`);
    },
    [navigate, selectedTargetId],
  );

  const handleTargetChange = (event: any, newValue: { id: string; label: string } | null) => {
    setSelectedTargetId(newValue?.id ?? null);
    if (newValue) {
      navigate(`${ROUTES.redteam.dashboard}/${encodeURIComponent(newValue.id)}`);
    } else {
      navigate(ROUTES.redteam.dashboard);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
        maxWidth: '100%',
        overflowX: 'hidden',
      }}
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          transition: 'margin-left 0.3s ease-in-out',
        }}
      >
        <Container maxWidth={false} disableGutters sx={{ mt: 2, mb: 4, px: 4 }}>
          <Paper
            sx={{
              p: 3,
              mb: 3,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
              <Autocomplete
                options={targets}
                renderInput={(params) => (
                  <TextField {...params} label="Select target" required error={!selectedTargetId} />
                )}
                value={targets.find((target) => target.id === selectedTargetId) ?? null}
                onChange={handleTargetChange}
                sx={{ width: 300 }}
                size="small"
                clearOnBlur
                handleHomeEndKeys
                disablePortal
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: 'green',
                }}
              />

              <Typography
                variant="body2"
                onClick={() => evals[0] && navigate(`${ROUTES.redteam.report}/${evals[0].evalId}`)}
                sx={{
                  cursor: evals[0] ? 'pointer' : 'default',
                }}
              >
                Last scan: {evals[0] ? new Date(evals[0].createdAt).toLocaleString() : 'TBD'}
              </Typography>
            </Box>
          </Paper>

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress size={60} thickness={4} />
            </Box>
          ) : (
            <Grid container spacing={3}>
              {/* Severity Cards */}
              <ErrorBoundary fallback={<>Error loading severity cards</>} onError={console.error}>
                <SeverityCards
                  issueCountsBySeverity={issueCountsBySeverity}
                  navigateToIssues={navigateToIssues}
                />
              </ErrorBoundary>
              {/* Charts */}
              <Grid container size={{ xs: 12 }} spacing={3}>
                <Grid size={{ xs: 12, md: 8 }}>
                  <ErrorBoundary fallback={<>Error loading charts</>} onError={console.error}>
                    <DashboardCharts evals={evals} issuesOverTimeData={issuesPerDay} />
                  </ErrorBoundary>
                </Grid>

                <Grid size={{ xs: 12, md: 4 }}>
                  <IssuesBySeverity
                    severityCounts={issueCountsBySeverity}
                    evals={evals}
                    navigateToIssues={navigateToIssues}
                  />
                </Grid>
              </Grid>

              <Grid container size={{ xs: 12 }} spacing={3}>
                <ErrorBoundary
                  fallback={<>Error loading latest scan section</>}
                  onError={console.error}
                >
                  <LatestScanSection evals={evals} navigateToIssues={navigateToIssues} />
                </ErrorBoundary>
              </Grid>
              <Grid container size={{ xs: 12 }} spacing={3}>
                <ErrorBoundary fallback={<>Error loading emerging risks</>}>
                  <EmergingRisks navigateToIssues={navigateToIssues} evals={evals} />
                </ErrorBoundary>
              </Grid>
              <Grid size={{ xs: 12, lg: 12 }}>
                <Paper sx={{ p: 3 }}>
                  <ErrorBoundary fallback={<>Error loading heatmap</>} onError={console.error}>
                    {isLoading ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <IssueHeatMap issues={filteredIssues} navigateToIssues={navigateToIssues} />
                    )}
                  </ErrorBoundary>
                </Paper>
              </Grid>

              <Grid container size={{ xs: 12 }} spacing={3}>
                <Grid size={{ xs: 6, lg: 6 }}>
                  <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" gutterBottom></Typography>
                    <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                      <ErrorBoundary
                        fallback={<>Error loading severity list</>}
                        onError={console.error}
                      >
                        <IssuesBySeverityList evals={evals} navigateToIssues={navigateToIssues} />
                      </ErrorBoundary>
                    </Box>
                  </Paper>
                </Grid>
                <Grid size={{ xs: 6, lg: 6 }}>
                  <Paper sx={{ p: 3, height: '100%' }}>
                    <ErrorBoundary
                      fallback={<>Error loading weakest areas</>}
                      onError={console.error}
                    >
                      <WeakestAreasList evals={evals} navigateToIssues={navigateToIssues} />
                    </ErrorBoundary>
                  </Paper>
                </Grid>
              </Grid>

              <Grid size={{ xs: 12, md: 12, lg: 12 }}>
                <Paper sx={{ p: 3 }}>
                  <ErrorBoundary fallback={<>Error loading recent evals</>} onError={console.error}>
                    <RecentEvals evals={evals} selectedTargetId={selectedTargetId} />
                  </ErrorBoundary>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </Box>
  );
}
