import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Typography, Container, Paper, Button, Divider } from '@mui/material';
import pandaImage from '../../../assets/journey-panda.jpeg';

interface EmptyStateViewProps {
  type: 'redteam' | 'eval';
  targetRoute?: string;
}

/**
 * A reusable component for displaying empty state screens
 * with consistent styling and the red panda image
 */
const EmptyStateView: React.FC<EmptyStateViewProps> = ({ type, targetRoute }) => {
  const navigate = useNavigate();

  const getContent = () => {
    switch (type) {
      case 'redteam':
        return {
          title: 'No Redteams have been run yet.',
          description: 'Start your security testing journey by creating a target for your Redteam.',
          buttonText: 'Create Target',
          showButton: true,
        };
      case 'eval':
        return {
          title: 'No Evals Yet',
          description:
            "You haven't created any evaluations yet. Start by running your first evaluation.",
          buttonText: '',
          showButton: false,
        };
      default:
        return {
          title: 'No Data Available',
          description: 'There is no data to display at this time.',
          buttonText: '',
          showButton: false,
        };
    }
  };

  const content = getContent();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            maxWidth: '100%',
            borderRadius: 2,
          }}
        >
          <Box
            component="img"
            src={pandaImage}
            alt="Red Panda"
            sx={{
              width: '100%',
              maxWidth: 300,
              height: 'auto',
              borderRadius: 2,
              mb: 3,
            }}
          />
          <Typography variant="h4" component="h1" gutterBottom>
            {content.title}
          </Typography>
          <Typography variant="body1" paragraph sx={{ mb: content.showButton ? 3 : 0 }}>
            {content.description}
          </Typography>
          {content.showButton && targetRoute && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => navigate(targetRoute)}
              sx={{ mb: 3 }}
            >
              {content.buttonText}
            </Button>
          )}

          <Divider sx={{ width: '100%', my: 3, display: content.showButton ? 'block' : 'none' }} />

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mt: content.showButton ? 0 : 3, mb: 2 }}
          >
            Or click here to learn how to share an Eval from the CLI
          </Typography>

          <Button
            variant="outlined"
            color="primary"
            startIcon={<UploadFileIcon />}
            onClick={() => navigate(ROUTES.welcome)}
            size="medium"
          >
            Share from CLI
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default EmptyStateView;
