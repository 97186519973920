import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Paper, FormControlLabel, Checkbox, Typography, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import { alpha, Box } from '@mui/system';
import {
  displayNameOverrides,
  categoryAliases,
  subCategoryDescriptions,
} from '@promptfoo/redteam/constants';
import type { Plugin } from '@promptfoo/redteam/constants';

export interface PluginCardProps {
  plugin: Plugin;
  selectedPlugins: Set<Plugin>;
  handlePluginToggle: (plugin: Plugin) => void;
  handleConfigClick: (plugin: Plugin) => void;
  isPluginConfigured: (plugin: Plugin) => boolean;
  isReadOnly?: boolean;
}

export const PLUGINS_REQUIRING_CONFIG = ['indirect-prompt-injection', 'prompt-extraction'];

const PLUGINS_SUPPORTING_CONFIG = ['bfla', 'bola', 'ssrf', ...PLUGINS_REQUIRING_CONFIG];

export default function PluginCard({
  plugin,
  selectedPlugins,
  handlePluginToggle,
  handleConfigClick,
  isPluginConfigured,
  isReadOnly = false,
}: PluginCardProps) {
  return (
    <Grid item xs={12} sm={6} md={4} key={plugin}>
      <Paper
        elevation={2}
        sx={{
          p: 2,
          height: '100%',
          border: (theme) => {
            if (selectedPlugins.has(plugin) && !isReadOnly) {
              if (PLUGINS_REQUIRING_CONFIG.includes(plugin) && !isPluginConfigured(plugin)) {
                return `1px solid ${theme.palette.error.main}`; // Error state border
              }
              return `1px solid ${theme.palette.primary.main}`; // Selected state border
            }
            return undefined;
          },
          backgroundColor: (theme) =>
            selectedPlugins.has(plugin) && !isReadOnly
              ? alpha(theme.palette.primary.main, 0.04)
              : 'background.paper',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            backgroundColor: (theme) =>
              selectedPlugins.has(plugin) && !isReadOnly
                ? alpha(theme.palette.primary.main, 0.08)
                : alpha(theme.palette.action.hover, 0.04),
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative',
          }}
        >
          <FormControlLabel
            sx={{ flex: 1 }}
            control={
              isReadOnly ? (
                <Box sx={{ width: 20, height: 20 }} />
              ) : (
                <Checkbox
                  checked={selectedPlugins.has(plugin)}
                  onChange={() => handlePluginToggle(plugin)}
                  color="primary"
                />
              )
            }
            label={
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  {displayNameOverrides[plugin as Plugin] ||
                    categoryAliases[plugin as Plugin] ||
                    plugin}
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {subCategoryDescriptions[plugin]}
                </Typography>
              </Box>
            }
          />
          {selectedPlugins.has(plugin) && PLUGINS_SUPPORTING_CONFIG.includes(plugin) && (
            <IconButton
              size="small"
              title={isPluginConfigured(plugin) ? 'Edit Configuration' : 'Configuration Required'}
              onClick={(e) => {
                e.stopPropagation();
                handleConfigClick(plugin);
              }}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                opacity: 0.6,
                '&:hover': {
                  opacity: 1,
                  backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                },
                ...(PLUGINS_REQUIRING_CONFIG.includes(plugin) &&
                  !isPluginConfigured(plugin) && {
                    color: 'error.main',
                    opacity: 1,
                  }),
              }}
            >
              <SettingsOutlinedIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Paper>
    </Grid>
  );
}
