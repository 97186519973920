import type { ResultsFile } from '@promptfoo/types';
import type { IssueDTO, EvalWithAuthor } from '@shared/dto';
import { ShareEvalRequestSchema, type ShareEvalResponse } from '@shared/dto/results';
import { callApi } from '../api';

export interface UseEvalsOptions {
  teamId?: string;
}

export const getIssueFromEvalResult = async (evalResultId: string): Promise<IssueDTO | null> => {
  const response = await callApi(`/results/evalResults/${evalResultId}/issue`);
  return await response.json();
};

export const fetchEvalById = async (id: string): Promise<ResultsFile & { isPublic: boolean }> => {
  const response = await callApi(`/results/${id}`, { cache: 'no-store' });
  return await response.json();
};

export const fetchEvals = async (options?: UseEvalsOptions): Promise<EvalWithAuthor[]> => {
  const params = new URLSearchParams();

  if (options?.teamId) {
    params.append('teamId', options.teamId);
  }

  const response = await callApi(`/results${params.toString() ? `?${params.toString()}` : ''}`);
  return await response.json();
};

export const deleteEval = async (evalId: string): Promise<void> => {
  await callApi(`/results/${evalId}`, {
    method: 'DELETE',
  });
};

export const shareEval = async ({
  evalId,
  roleId,
}: {
  evalId: string;
  roleId?: string | null;
}): Promise<ShareEvalResponse> => {
  const body = ShareEvalRequestSchema.parse({ roleId });
  const response = await callApi(`/results/${evalId}/share`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  const json = await response.json();
  return json as ShareEvalResponse;
};
