import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import { postIssueComment } from '@cloud-ui/utils/api/issues';
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import type { IssueHistoryDTO, EvalResultDTO, IssueDTO } from '@shared/dto';
import { IssueEventType, IssueEventTypeDisplayNames } from '@shared/dto/issues';
import HistoryItem from './HistoryItem';

interface HistoryTabProps {
  issue: IssueDTO;
}

export default function HistoryTab({ issue }: HistoryTabProps) {
  const [comment, setComment] = useState('');
  const [selectedEventType, setSelectedEventType] = useState<string>('all');
  const queryClient = useQueryClient();
  const { currentTeam } = useTeamsContext();

  const commentMutation = useMutation({
    mutationFn: (newComment: string) => postIssueComment(issue.id, newComment),
    onSuccess: (newCommentData: IssueHistoryDTO) => {
      // Update the specific issue being viewed
      const updatedIssue = { ...issue, history: [...issue.history, newCommentData] };

      // Update the issue in the local state via React Query cache
      queryClient.setQueryData(
        ['issue_evalresults', issue.id],
        (oldData: EvalResultDTO[] | undefined) => oldData,
      );

      // Update the issue in the issues list cache for the team
      queryClient.setQueryData(['issues', currentTeam?.id], (oldIssues: IssueDTO[] | undefined) => {
        if (!oldIssues) {
          return oldIssues;
        }
        return oldIssues.map((i) => (i.id === issue.id ? updatedIssue : i));
      });

      // Update for any other cache that might be using the general issues list
      queryClient.setQueryData(['issues'], (oldIssues: IssueDTO[] | undefined) => {
        if (!oldIssues) {
          return oldIssues;
        }
        return oldIssues.map((i) => (i.id === issue.id ? updatedIssue : i));
      });

      // Update the local state of the component
      queryClient.setQueryData(['selectedIssue', issue.id], updatedIssue);

      setComment('');
    },
    onError: (error) => {
      console.error('Error adding comment:', error);
    },
  });

  const handleCommentSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (comment.trim()) {
      commentMutation.mutate(comment);
    }
  };

  const filteredHistory = issue.history
    .slice()
    .filter((item) => selectedEventType === 'all' || item.type === selectedEventType);

  const eventTypeOptions = [
    { value: 'all', label: 'All Events' },
    ...Object.entries(IssueEventType).map(([key, value]) => ({
      value,
      label: IssueEventTypeDisplayNames[value],
    })),
  ];

  return (
    <Box sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <form onSubmit={handleCommentSubmit}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Add a comment..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          sx={{ mb: 2 }}
          autoFocus
          multiline
          rows={3}
          maxRows={6}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Button type="submit" variant="contained" disabled={commentMutation.isPending}>
            {commentMutation.isPending ? 'Submitting...' : 'Add Comment'}
          </Button>

          <FormControl sx={{ width: '200px' }} size="small">
            <InputLabel id="event-type-filter-label">Filter by Event Type</InputLabel>
            <Select
              labelId="event-type-filter-label"
              value={selectedEventType}
              onChange={(e) => setSelectedEventType(e.target.value)}
              label="Filter by Event Type"
            >
              {eventTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {commentMutation.isError && (
          <Typography color="error" sx={{ mb: 2 }}>
            Error adding comment: {commentMutation.error.message}
          </Typography>
        )}
      </form>
      <Box sx={{ mt: 2 }}>
        {filteredHistory.reverse().map((historyItem: IssueHistoryDTO, index: number) => (
          <HistoryItem key={index} item={historyItem} />
        ))}
      </Box>
    </Box>
  );
}
