import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ProviderEditor from '@cloud-ui/components/providers/ProviderEditor';
import { ROUTES } from '@cloud-ui/constants';
import { useTeamsContext } from '@cloud-ui/contexts/TeamsContext';
import DisplayTargetConfig from '@cloud-ui/pages/redteam/components/plugins/DisplayTargetConfig';
import { useGetProviders } from '@cloud-ui/pages/redteam/hooks/providers';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Autocomplete, Paper, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { CLOUD_PROVIDER_PREFIX } from '@promptfoo/constants';
import type { ProviderDTO } from '@shared/dto/providers';
import { getDatabaseIdFromProvider, isCloudProvider } from '@shared/utils/providers';
import { useRedTeamConfig } from '../hooks/useRedTeamConfig';

interface TargetProps {
  onNext?: () => void;
  onBack?: () => void;
}

function TargetEmptyState() {
  return (
    <>
      <Typography variant="body1" sx={{ mb: 3 }}>
        No target configurations found.
      </Typography>

      <Paper
        variant="outlined"
        sx={{
          p: 4,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Please create a target first
        </Typography>
        <RouterLink to={`${ROUTES.redteam.targets}/new`} style={{ textDecoration: 'none' }}>
          <button
            style={{
              padding: '10px 20px',
              backgroundColor: '#1976d2',
              color: 'white',
              border: 'none',
              borderRadius: 4,
              cursor: 'pointer',
              marginTop: '16px',
              fontSize: '16px',
            }}
          >
            Create New Target
          </button>
        </RouterLink>
      </Paper>
    </>
  );
}

export default function Target({ onNext, onBack }: TargetProps) {
  const { currentTeam } = useTeamsContext();
  const { config, updateConfig } = useRedTeamConfig();
  const { providers, isLoading } = useGetProviders(currentTeam?.id);
  const [selectedTarget, setSelectedTarget] = useState<ProviderDTO | undefined>(() => {
    const databaseId = getDatabaseIdFromProvider(config.target);
    if (databaseId && providers) {
      return providers.find((p) => p.id === databaseId);
    }
    return undefined;
  });

  // Update selectedTarget when config.target changes
  useEffect(() => {
    const databaseId = getDatabaseIdFromProvider(config.target);
    if (databaseId && providers) {
      const provider = providers.find((p) => p.id === databaseId);
      if (provider && (!selectedTarget || selectedTarget.id !== provider.id)) {
        setSelectedTarget(provider);
      }
    }
  }, [config.target, providers]);

  const theme = useTheme();
  if (isLoading) {
    return <Typography variant="body1">Loading target configurations...</Typography>;
  }

  if (config.target && !isCloudProvider(config.target)) {
    return (
      <ProviderEditor
        onActionButtonClick={onNext}
        onBack={onBack}
        provider={config.target}
        setProvider={(provider) => updateConfig('target', provider)}
        extensions={config.extensions}
        onExtensionsChange={(extensions) => updateConfig('extensions', extensions)}
        opts={{
          actionButtonText: 'Next',
        }}
      />
    );
  }

  return (
    <Paper sx={{ p: 3, borderRadius: 2 }} component="form" onSubmit={(e) => e.preventDefault()}>
      <Typography variant="h5" gutterBottom>
        Target Setup
      </Typography>

      {providers.length === 0 ? (
        <TargetEmptyState />
      ) : (
        <>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Select a target configuration or create a new one.
          </Typography>

          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Select Target
          </Typography>

          {/* Target Selector */}
          <div style={{ display: 'flex', alignItems: 'center', gap: 16, marginBottom: 24 }}>
            <Autocomplete
              fullWidth
              options={providers}
              getOptionLabel={(option) => option.name}
              value={selectedTarget}
              onChange={(event, newValue) => {
                if (newValue) {
                  updateConfig('target', {
                    id: `${CLOUD_PROVIDER_PREFIX}${newValue.id}`,
                  });
                  setSelectedTarget(newValue);
                }
                // No else clause - we don't allow clearing the value anymore
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select a target configuration" variant="outlined" />
              )}
              sx={{ maxWidth: 400 }}
              disableClearable={true}
              onBlur={(e) => e.preventDefault()}
              onClick={(e) => e.stopPropagation()}
              disablePortal={true}
            />

            {/* Create New or Edit Target Links */}

            <RouterLink to={`${ROUTES.redteam.targets}/new`} style={{ textDecoration: 'none' }}>
              <Typography color="primary">Create New</Typography>
            </RouterLink>

            {config.target?.id && (
              <RouterLink
                to={`${ROUTES.redteam.targets}/${getDatabaseIdFromProvider(config.target)}`}
                style={{ textDecoration: 'none' }}
              >
                <Typography color="primary">Edit Configuration</Typography>
              </RouterLink>
            )}
          </div>

          {/* Display the target configuration details */}
          {selectedTarget && <DisplayTargetConfig config={selectedTarget.config} />}
        </>
      )}

      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 16, marginTop: 24 }}>
        <Button
          variant="contained"
          onClick={onNext}
          endIcon={<KeyboardArrowRightIcon />}
          disabled={!selectedTarget}
          sx={{
            backgroundColor: theme.palette.primary.main,
            '&:hover': { backgroundColor: theme.palette.primary.dark },
            px: 4,
            py: 1,
          }}
        >
          Next
        </Button>
      </div>
    </Paper>
  );
}
